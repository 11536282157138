import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M3.846 10H2.523a.683.683 0 0 0-.677.677v4c0 .37.308.677.677.677h1.323c1.108 0 2-.893 2-2V12c0-1.108-.892-2-2-2m.677 3.323c0 .37-.308.677-.677.677H3.17v-2.677h.677c.37 0 .677.308.677.677zM13.815 4.862l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523V8c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8v2.768c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769v2.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323c-.031-.154-.093-.338-.216-.461M10.4 4.677h-.37V2.954l1.724 1.723zM9.23 10H7.693c-.676 0-1.23.554-1.23 1.23v2.924c0 .677.554 1.23 1.23 1.23h1.539c.677 0 1.23-.553 1.23-1.23V11.23c0-.677-.553-1.231-1.23-1.231m0 4.154H7.693V11.23h1.539zM13.385 11.23a.617.617 0 0 0 .615-.615.617.617 0 0 0-.615-.615h-1.077c-.677 0-1.231.554-1.231 1.23v2.924c0 .677.554 1.23 1.23 1.23h1.078A.617.617 0 0 0 14 14.77a.617.617 0 0 0-.615-.615h-1.077V11.23z" }, null, -1)
  ])))
}
export default { render: render }