import Constants from '@/configs/tables/constants';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import StatusFlag from '@/components/table/flags/StatusFlag.vue';
import PlayIcon from '@/assets/icons/play.svg';
import FlagIcon from '@/assets/icons/flag.svg';
import ErrorIcon from '@/assets/icons/error.svg';

const RPS = {};
RPS[String(ConstantsRPS.rp_status_type_performed_id)] = {
  title: 'Выполняется',
  icon: PlayIcon,
};
RPS[String(ConstantsRPS.rp_status_type_completed_id)] = {
  title: 'Завершено',
  icon: FlagIcon,
};
RPS[String(ConstantsRPS.rp_status_type_expired_id)] = {
  title: 'Просрочено',
  icon: ErrorIcon,
};
RPS[String(ConstantsRPS.rp_status_type_pending_id)] = {
  title: 'Ожидает',
};
RPS[String(ConstantsRPS.rp_status_type_approved_id)] = {
  title: 'Согласовано',
};
RPS[String(ConstantsRPS.rp_status_type_rejected_id)] = {
  title: 'Отклонено',
};
RPS[String(ConstantsRPS.rp_status_type_completion_confirmed_id)] = {
  title: 'Завершение подтверждено',
};
RPS[String(ConstantsRPS.rp_status_type_stopped_id)] = {
  title: 'Остановлено',
};
RPS[String(ConstantsRPS.rp_status_type_accepted_id)] = {
  title: 'Принято',
};

const data = {
  visible: true,
  system: false,
  title: 'Статус',
  classes: { 'text-center': true },
  type: Constants.identifier,
  values: RPS,
  description: 'ID статуса поручения',
  component: StatusFlag,
  order: 4,
  conditions: 1,
};

export default function (mix = {}, type = '') {
  if (type == 'commissions') {
    let values = {};
    values[String(ConstantsRPS.rp_status_type_performed_id)] = RPS[String(ConstantsRPS.rp_status_type_performed_id)];
    values[String(ConstantsRPS.rp_status_type_completed_id)] = RPS[String(ConstantsRPS.rp_status_type_completed_id)];
    values[String(ConstantsRPS.rp_status_type_expired_id)] = RPS[String(ConstantsRPS.rp_status_type_expired_id)];

    mix['values'] = values;
  }
  return Object.assign(Object.create(data), mix);
}
