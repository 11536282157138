<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.66667C4 6.29848 4.29848 6 4.66667 6H11.3333C11.7015 6 12 6.29848 12 6.66667C12 7.03486 11.7015 7.33333 11.3333 7.33333H4.66667C4.29848 7.33333 4 7.03486 4 6.66667Z"
      fill="#5F6A7D"
    />
    <path
      d="M4 9.33341C4 8.96522 4.29848 8.66675 4.66667 8.66675H11.3333C11.7015 8.66675 12 8.96522 12 9.33341C12 9.7016 11.7015 10.0001 11.3333 10.0001H4.66667C4.29848 10.0001 4 9.7016 4 9.33341Z"
      fill="#5F6A7D"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'MenuTableSettingBarsIcon',
};
</script>
