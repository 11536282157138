import { ref } from 'vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import { State } from '@/common/enums/state.ts';
import FileApi from '@/services/api/file-api.js';
import { DOC_FILES_GET } from '@/configs/end-points.js';
import { fileSize } from '@/common/utils/utils.js';
import { usePreviewStore } from '@/stores/preview.js';
import { notify } from '@kyvg/vue3-notification';
import type ExternalFile from '@/common/models/file/external-file.ts';
import type { TableId } from '@/common/enums/table-id.ts';

export default function useUpload() {
  const previewStore = usePreviewStore();

  const files = ref<ExternalFile[]>([]);

  async function upload(tableId: TableId, parentId: number, comment?: string) {
    const notUploaded = files.value.filter((file) => file.state !== State.Defined);

    if (!notUploaded.length) {
      return { hasError: false, hasSuccess: false };
    }

    notUploaded.forEach((file) => (file.state = State.Loading));

    const responses = await FileApi.uploadList({
      tableId,
      parentId,
      files: notUploaded,
      comment,
    });

    let hasSuccess = false;
    let hasError = false;

    responses.forEach((response, index) => {
      const file = notUploaded[index];

      if (response.status === 'fulfilled') {
        file.state = State.Defined;
        hasSuccess = true;
      } else {
        file.state = State.Error;
        file.error = response.reason;
        notify({
          type: NotifyTypes.Error,
          text: `При загрузке файла "${file.name}" (${fileSize(file.size)}) возникла ошибка.`,
          data: response.reason,
        });
        hasError = true;
      }
    });

    if (hasSuccess) {
      void previewStore.refreshPartlyPreviewAction(DOC_FILES_GET);
    }

    if (!hasError) {
      notify({
        type: NotifyTypes.Success,
        text: 'Файлы успешно загружены',
      });
    }

    return { hasError, hasSuccess };
  }

  return { files, upload };
}
