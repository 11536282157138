<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  arrow,
  autoUpdate,
  flip,
  limitShift,
  offset,
  type Placement,
  shift,
  size,
  useFloating,
} from '@floating-ui/vue';
import TooltipArrowIcon from '@/assets/icons/tooltip-arrow.svg';

const {
  tag = 'div',
  text,
  placement = 'bottom',
  maxWidth,
  disabled,
} = defineProps<{
  tag?: 'div' | 'span' | 'a';
  text?: string;
  placement?: Placement;
  maxWidth?: number;
  disabled?: boolean;
}>();

const anchorRef = ref<HTMLElement>();
const dropdownRef = ref<HTMLElement>();
const arrowRef = ref<HTMLSpanElement>();

const isOpen = ref(false);

const {
  floatingStyles,
  middlewareData,
  placement: finalPlacement,
} = useFloating(anchorRef, dropdownRef, {
  placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(10),
    flip({ padding: 8 }),
    shift({ padding: 8, limiter: limitShift({ offset: 36 }) }),
    arrow({ element: arrowRef, padding: 12 }),
    size({
      apply({ availableWidth, elements }) {
        const availableTooltipWidth = availableWidth - 16;
        const tooltipWidth = maxWidth ? Math.min(availableTooltipWidth, maxWidth) : availableTooltipWidth;

        Object.assign(elements.floating.style, {
          maxWidth: `${tooltipWidth}px`,
        });
      },
    }),
  ],
});

const arrowStyles = computed(() => {
  const arrow = middlewareData.value.arrow;

  const dynamicSide = finalPlacement.value.split('-')[0];
  const isVertical = dynamicSide === 'top' || dynamicSide === 'bottom';

  const staticSide =
    {
      bottom: 'top',
      left: 'right',
      top: 'bottom',
      right: 'left',
    }[dynamicSide] || 'bottom';

  const staticSideOffset = isVertical ? 9 : 15;

  const rotation =
    {
      bottom: 0,
      left: 90,
      top: 180,
      right: 270,
    }[dynamicSide] || 0;

  return {
    left: arrow?.x != null ? `${arrow.x}px` : '',
    top: arrow?.y != null ? `${arrow.y}px` : '',
    [staticSide]: `-${staticSideOffset}px`,
    transform: `rotate(${rotation}deg)`,
  };
});

const show = computed(() => !disabled && isOpen.value);
</script>

<template>
  <component
    :is="tag"
    ref="anchorRef"
    class="tooltip-ui"
    @mouseenter="isOpen = true"
    @mouseleave="isOpen = false"
  >
    <slot />

    <Transition name="opacity-fast">
      <component
        :is="tag === 'div' ? 'div' : 'span'"
        v-if="show"
        ref="dropdownRef"
        class="dropdown"
        :style="floatingStyles"
      >
        <slot name="tooltip">
          <component
            :is="tag === 'div' ? 'p' : 'span'"
            class="text"
          >
            {{ text }}
          </component>
        </slot>

        <TooltipArrowIcon
          ref="arrowRef"
          class="arrow"
          :style="arrowStyles"
        />
      </component>
    </Transition>
  </component>
</template>

<style scoped lang="scss">
.tooltip-ui {
  cursor: pointer;
}

.dropdown,
.arrow {
  position: absolute;
  z-index: var(--z-index-tooltip);
  pointer-events: none;
}

.dropdown {
  width: max-content;
  padding: $unit * 3;

  border: var(--border-yellow-500);
  border-radius: $unit * 2;
  background-color: var(--color-yellow-100);
}

.text {
  text-align: center;
  word-break: break-word;

  color: var(--color-gray-1000);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
}
</style>
