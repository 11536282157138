<template>
  <div
    ref="layout"
    class="layout-wrapper"
    :class="classLayoutWrapper"
  >
    <SidebarGeneral />
    <MobileMenu />

    <div class="layout-content">
      <ToolbarGeneral
        ref="toolbar"
        :show-add-document="addDocumentEnabled"
        @add-document="addDocument"
      />

      <CreateDocModal />

      <div
        ref="frames"
        class="layout-content-frame"
        :style="styleFrame"
      >
        <DropZoneUi
          ref="frameLeft"
          class="layout-content-frame-left"
          :style="styleFrameL"
          subtitle="для создания документа"
          :size="dropzoneLeftSize"
          :disabled="!addDocumentEnabled"
          :show="showDropZones"
          @drop="addDocument"
        >
          <template #icon>
            <AddFileIcon />
          </template>

          <RouterView
            class="inner"
            :component-width="frameLeftWidth"
          />
        </DropZoneUi>

        <DropZoneUi
          ref="frameRight"
          class="layout-content-frame-right"
          :style="styleFrameR"
          subtitle="для добавления в документ"
          :size="dropzoneRightSize"
          :disabled="!accessToEdit"
          :show="showDropZones"
          @drop="addFiles"
        >
          <div
            v-if="showEmptyPreview"
            class="document-not-selected"
          >
            Выберите документ для просмотра
          </div>

          <component
            :is="preview"
            v-if="showPreview"
            class="inner"
            :component-width="frameRightWidth"
            preview
          />
        </DropZoneUi>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useDropZone, useElementBounding } from '@vueuse/core';
import Constants from '@/configs/constants';
import SidebarGeneral from '@/components/general/SidebarGeneral';
import MobileMenu from '@/components/general/MobileMenu';
import ToolbarGeneral from '@/components/general/ToolbarGeneral';
import DocumentPreview from '@/components/doc/preview/DocumentPreview';
import ClientPreview from '@/components/clients/ClientPreview';
import { mapActions, mapState } from 'pinia';
import { DOCUMENTS__EDIT } from '@/configs/events';
import Split from 'split.js';
import DropZoneUi from '@/components/ui/DropZoneUi.vue';
import CreateDocModal from '@/components/modals/Doc/CreateDocModal.vue';
import AddFileIcon from '@/assets/icons/add-file.svg';
import Emitter from '@/services/emitter.js';
import { useMasterStore } from '@/stores/master.ts';
import { useSplitStore } from '@/stores/split.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useControlsStore } from '@/stores/controls.js';
import { useRolesStore } from '@/stores/roles.js';
import { Route } from '@/common/enums/route.ts';

export default defineComponent({
  name: 'ExtendedViewLayout',
  components: {
    AddFileIcon,
    CreateDocModal,
    DropZoneUi,
    SidebarGeneral,
    MobileMenu,
    ToolbarGeneral,
    DocumentPreview,
    ClientPreview,
  },
  inject: ['app'],
  provide() {
    const provider = {};
    const self = this;
    Object.defineProperty(provider, 'layout', {
      enumerable: true,
      get: () => self,
    });
    return provider;
  },
  setup() {
    const layout = ref(null);
    const { width: layoutWidth, height: layoutHeight } = useElementBounding(layout);

    const toolbar = ref(null);
    const { height: toolbarHeight } = useElementBounding(toolbar);

    const frameLeft = ref(null);
    const { width: frameLeftWidth, right: frameLeftRight, left: frameLeftLeft } = useElementBounding(frameLeft);

    const frameRight = ref(null);
    const { width: frameRightWidth } = useElementBounding(frameRight);

    const frames = ref(null);
    const { isOverDropZone: showDropZones } = useDropZone(frames);

    return {
      layout,
      layoutWidth,
      layoutHeight,

      toolbar,
      toolbarHeight,

      frameLeft,
      frameLeftWidth,
      frameLeftRight,
      frameLeftLeft,

      frameRight,
      frameRightWidth,

      frames,
      showDropZones,
    };
  },
  data: () => ({
    splitObject: null,
    isSlideMode: false,
    isPreviewActive: false,
    timerCounterId: null,
    documentLastIdPreview: null,
    timerCounterLimit: 1000 * 60,
    isScrollDisabled: false,
  }),
  mounted() {
    Emitter.on('search-active-change', this.onSearchActiveChange);

    /* Counters */
    this.getCommissionsCountAction();
    this.getControlsCountAction();
    this.timerCounterId = setInterval(() => {
      this.getCommissionsCountAction();
      this.getControlsCountAction();
    }, this.timerCounterLimit);

    /* Frames */
    this.initFrames();
  },
  unmounted() {
    Emitter.off('search-active-change', this.onSearchActiveChange);

    /* Counters */
    clearInterval(this.timerCounterId);

    /* Split mode */
    this.destroySplit();
  },
  computed: {
    ...mapState(useSplitStore, { splitRatio: 'ratio' }),
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(usePreviewStore, ['hasEntityIdPreviewGetter', 'isActivePreview']),
    showEmptyPreview() {
      return this.frameRightWidth > 4 && !this.hasEntityIdPreviewGetter;
    },
    showPreview() {
      return this.frameRightWidth > 4 && this.hasEntityIdPreviewGetter;
    },
    preview() {
      return (this.$route.meta.preview || 'document') + '-preview';
    },
    accessToEdit() {
      return this.accessToEvent(DOCUMENTS__EDIT);
    },
    classLayoutWrapper() {
      return {
        '_overflow-hidden': this.isScrollDisabled,
        'layout-content-frame-left-active': this.isSlideMode && !this.isActivePreview,
        'layout-content-frame-right-active': this.isSlideMode && this.isActivePreview,
      };
    },
    styleFrame() {
      return {
        'height': this.layoutHeight + 'px',
        'margin-top': -this.toolbarHeight + 'px',
      };
    },
    styleFrameL() {
      return {
        'height': this.layoutHeight + 'px',
        'padding-top': this.toolbarHeight + 'px',
      };
    },
    styleFrameR() {
      return {
        'height': this.layoutHeight + 'px',
        'padding-top': this.toolbarHeight + 'px',
      };
    },
    dropzoneLeftSize() {
      return this.frameLeftWidth > 600 ? 'm' : 's';
    },
    dropzoneRightSize() {
      return this.frameRightWidth > 600 ? 'm' : 's';
    },
    addDocumentEnabled() {
      return this.$route.name === Route.ControlsPage && this.accessToEvent(DOCUMENTS__EDIT);
    },
  },
  methods: {
    ...mapActions(useSplitStore, { setSplitRatio: 'set' }),
    ...mapActions(useCommissionsStore, ['getCommissionsCountAction']),
    ...mapActions(useControlsStore, ['getControlsCountAction']),
    ...mapActions(useMasterStore, { openDocumentMaster: 'open', addDocumentMasterFiles: 'addFiles' }),
    initFrames: function () {
      /* Инициализация слайдерного режима */
      if (this.app.appWidth <= Constants.resolutionPoints.small) {
        this.destroySplit();
        this.isSlideMode = true;
        /* Инициализация сплит режима */
      } else {
        this.initSplit();
        this.isSlideMode = false;
      }
    },
    initSplit: function () {
      if (this.splitObject) {
        return;
      }

      const _this = this;
      _this.splitObject = Split(['.layout-content-frame-left', '.layout-content-frame-right'], {
        sizes: this.splitRatio,
        minSize: [350, 4],
        gutterSize: 20,
        gutterAlign: 'center',
        snapOffset: 20,
        direction: 'horizontal',
        cursor: 'col-resize',
        elementStyle: function (dimension, size, gutterSize) {
          return {
            'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)',
          };
        },
        gutterStyle: function (dimension, gutterSize) {
          return {
            'flex-basis': gutterSize + 'px',
          };
        },
        onDrag: () => {
          Emitter.emit('layout-resize');
        },
        onDragEnd: (sizes) => {
          Emitter.emit('layout-resize-end');
          this.setSplitRatio(sizes);
        },
      });
    },
    destroySplit: function () {
      if (this.splitObject) {
        this.splitObject.destroy();
      }
      this.splitObject = null;
    },
    onSearchActiveChange(isOpen) {
      this.isScrollDisabled = isOpen;
    },
    async addDocument(files) {
      if (files) {
        await this.addDocumentMasterFiles(files);
      }
      this.openDocumentMaster();
    },
    addFiles(files) {
      Emitter.emit('document-preview-drop-file', files);
    },
  },
  watch: {
    'app.appWidth'() {
      this.initFrames();
    },
  },
});
</script>
