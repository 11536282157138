import BaseFile from '@/common/models/file/base-file.ts';
import Revision from '@/common/models/revision.ts';
import { DateTime } from 'luxon';
import { useSystemStore } from '@/stores/system.js';
import type ExternalFile from '@/common/models/file/external-file.ts';

export default class ApiFile extends BaseFile {
  fileId: number;
  hash: string;
  url: string;
  pdfUrl: string;
  comment: string;
  revision: Revision;
  createdDate: string;

  constructor(response: any, file?: ExternalFile) {
    super(file?.id || response['F_ID'], file?.name || response['Название'], file?.size || response['Размер']);

    const systemStore = useSystemStore();

    this.fileId = response['F_ID'];
    this.hash = response['Файл'];
    this.url = `${systemStore.storageUrl}${this.hash}.${this.extension}`;
    this.pdfUrl = `${systemStore.storageUrl}${this.hash}.pdf`;
    this.comment = response['Примечание'];
    this.revision = new Revision(response['Редакция']);
    this.createdDate = this.getCreatedDate(response['Создан']);
  }

  private getCreatedDate(date: string) {
    return DateTime.fromSQL(date).toFormat('dd.MM.yyyy, HH:mm');
  }
}
