export default class Address {
  id: number;
  type: string;
  address: string;
  comment: string;

  constructor(response: any) {
    this.id = response['ADDRESS_ID'];
    this.type = response['Тип'];
    this.address = response['Адрес'];
    this.comment = response['Примечание'];
  }
}
