<script setup lang="ts">
import { computed, ref } from 'vue';
import DocumentMasterWrapper from '@/components/document-master/wrapper/DocumentMasterWrapper.vue';
import { storeToRefs } from 'pinia';
import FORM_DATA from '@/common/consts/document-master/form-value.js';
import { useMasterStore } from '@/stores/master.js';
import { DOCUMENT_MASTER_COMPONENT } from '@/common/consts/document-master/component.js';
import ModalForm from '@/components/common/ModalForm.vue';
import { useMasterControllerStore } from '@/stores/master-controller';

const masterStore = useMasterStore();
const { isDirty, step } = storeToRefs(masterStore);
const masterControllerStore = useMasterControllerStore();

const formData = ref(JSON.parse(JSON.stringify(FORM_DATA))); // TODO: Перенести в store

const isOpen = computed({
  get: () => masterStore.isOpen,
  set: (value) => {
    if (value) {
      masterStore.open();
    } else {
      masterStore.cancel();
    }
  },
});

const component = computed(() => DOCUMENT_MASTER_COMPONENT[step.value.code]);

function onShow() {
  masterControllerStore.setUser();
}

function onHide() {
  masterStore.clear();
  formData.value = JSON.parse(JSON.stringify(FORM_DATA));
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    :confirm="isDirty"
    :confirm-text="[
      'Вы собираетесь остановить процесс создания документа.',
      'Данная операция необратима!',
      'Продолжить?',
    ]"
    :width="step.width || 'm'"
    :height="step.height || '510px'"
    min-height="510px"
    @show="onShow"
    @hide="onHide"
  >
    <template #body>
      <Transition
        name="opacity-delayed"
        mode="out-in"
      >
        <component
          :is="component"
          v-if="step.noWrapper"
          v-model="formData"
        />

        <DocumentMasterWrapper v-else>
          <component
            :is="component"
            :key="step.title"
            v-model="formData"
            :step="step"
          />
        </DocumentMasterWrapper>
      </Transition>
    </template>
  </ModalForm>
</template>
