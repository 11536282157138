<script setup lang="ts">
import { computed } from 'vue';
import TooltipUi from '@/components/ui/TooltipUi.vue';

const props = defineProps<{
  value: number;
  completedTooltip?: string;
}>();

const SIZE = 24;
const STROKE = 3;

const radius = (SIZE - STROKE) / 2 + 1; // +1 исправляет отображение в Safari
const full = 2 * Math.PI * radius;

const progress = computed(() => full - (props.value / 100) * full);
const isCompleted = computed(() => props.value === 100);
const color = computed(() => (isCompleted.value ? 'var(--color-green-600)' : 'var(--color-blue-400)'));
</script>

<template>
  <TooltipUi
    class="progress-circle-ui"
    :text="(isCompleted && completedTooltip) || `${value}%`"
    :max-width="220"
  >
    <div
      class="wrapper"
      :class="{ _pulse: isCompleted }"
    >
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        :viewBox="`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`"
      >
        <circle
          :cx="SIZE"
          :cy="SIZE"
          :r="radius"
          stroke="var(--color-gray-100)"
          :stroke-width="STROKE"
          fill="none"
        />
        <circle
          class="progress"
          :cx="SIZE"
          :cy="SIZE"
          :r="radius"
          fill="none"
          :stroke-width="STROKE"
          :stroke-dasharray="full"
          :stroke-dashoffset="progress"
          stroke-linecap="round"
          :stroke="color"
        />
      </svg>
    </div>
  </TooltipUi>
</template>

<style scoped lang="scss">
.wrapper {
  border-radius: 50%;
  transform: rotate(-90deg);

  &._pulse {
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        transform: rotate(-90deg) scale(0.9);
        box-shadow: 0 0 0 0 rgba(46, 219, 159, 0.9);
      }

      70% {
        transform: rotate(-90deg) scale(1);
        box-shadow: 0 0 0 12px rgba(46, 219, 159, 0);
      }

      100% {
        transform: rotate(-90deg) scale(0.9);
        box-shadow: 0 0 0 0 rgba(46, 219, 159, 0);
      }
    }
  }
}

.icon {
  overflow: visible;
}

.progress {
  transition:
    color var(--transition-slow),
    stroke-dashoffset var(--transition-slow);
  animation: initial var(--transition-slow) forwards;
}

@keyframes initial {
  from {
    stroke-dashoffset: v-bind(full);
  }
  to {
    stroke-dashoffset: v-bind(progress);
  }
}
</style>
