<template>
  <div class="file-footer">
    <span>{{ formattedSize }}</span>

    <div
      v-if="state === State.Defined"
      class="state"
    >
      <CheckIcon class="icon _green" />
    </div>
    <TooltipUi
      v-else-if="state === State.Error"
      class="state"
      :text="formattedError"
    >
      <ErrorIcon class="icon _red" />
    </TooltipUi>
    <SpinnerUi
      v-else-if="state === State.Loading || !size"
      class="state"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { fileSize } from '@/common/utils/utils.js';
import ErrorIcon from '@/assets/icons/circle/error.svg';
import CheckIcon from '@/assets/icons/circle/check.svg';
import { only } from '@/common/utils/props-validators';
import { State } from '@/common/enums/state.ts';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import TooltipUi from '@/components/ui/TooltipUi.vue';

export default defineComponent({
  name: 'FileFooter',
  components: {
    TooltipUi,
    SpinnerUi,
    ErrorIcon,
    CheckIcon,
  },
  props: {
    size: Number,
    state: {
      type: String,
      default: State.Undefined,
      validator: only(...Object.values(State)),
    },
    error: null,
  },
  data() {
    return {
      State,
    };
  },
  computed: {
    formattedSize() {
      return fileSize(this.size);
    },
    formattedError() {
      return this.error?.error_message || 'Неизвестная ошибка';
    },
  },
});
</script>

<style scoped lang="scss">
.file-footer {
  min-height: 24px;
  padding: 4px;

  display: flex;
  justify-content: space-between;
}

.state {
  margin-top: -4px;
}

.icon {
  &._green {
    fill: var(--color-green-800);
  }

  &._red {
    fill: var(--color-red-900);
  }
}
</style>
