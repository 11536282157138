import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.677 4.277v.492c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769v2.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523v3.908c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8zm1.354.37C10 4.553 10 4.461 10 4.246V2.953l1.723 1.723H10.4c-.185 0-.308 0-.37-.03M10.154 10.154H7.077a.617.617 0 0 0-.615.615c0 .339.277.616.615.616H8v3.538c0 .338.277.615.616.615a.617.617 0 0 0 .615-.615v-3.538h.923a.617.617 0 0 0 .615-.616.617.617 0 0 0-.615-.615M14.77 11.385a.617.617 0 0 0 .615-.616.617.617 0 0 0-.616-.615h-2.153c-.524 0-.924.4-.924.923v3.846c0 .338.277.615.616.615a.617.617 0 0 0 .615-.615v-1.477h1.385a.617.617 0 0 0 .615-.615.617.617 0 0 0-.615-.616h-1.385v-.83zM5.539 14.4l-1.2-.708c.584-.092 1.046-.615 1.046-1.2v-1.107c0-.677-.554-1.231-1.231-1.231H2.462c-.523 0-.923.4-.923.923v3.877c0 .338.276.615.615.615a.617.617 0 0 0 .615-.615v-.8l2.154 1.292a.6.6 0 0 0 .308.092c.215 0 .4-.092.523-.307.185-.277.092-.646-.215-.831m-1.385-1.939H2.769v-1.076h1.385z" }, null, -1)
  ])))
}
export default { render: render }