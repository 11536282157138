<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import FileError from '@/components/doc/files/FileError.vue';
import FileApi from '@/services/api/file-api.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { State } from '@/common/enums/state.ts';
import ApiFile from '@/common/models/file/api-file.js';
import StorageApi from '@/services/api/storage-api.js';
import BaseFile from '@/common/models/file/base-file.js';
import useAbort from '@/composables/use-abort.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import EditIcon from '@/assets/icons/edit.svg';
import FileNoPreview from '@/components/doc/files/FileNoPreview.vue';

const props = defineProps({
  file: {
    type: [BaseFile],
    required: true,
  },
  tableId: {
    type: Number,
    required: true,
  },
  parentId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['upload', 'edit']);

const { signal } = useAbort();

const state = ref(State.Undefined);
const iframeDisabled = ref(false);

const src = computed(() => `data:image/${props.file.extension};base64,${props.file.base64}`);
const url = computed(() => {
  if (!(props.file instanceof ApiFile)) {
    throw new Error('Файл не был загружен. URL для получения PDF не определён');
  }

  return `${props.file.pdfUrl}#toolbar=0&scrollbar=1`;
});
const showEdit = computed(
  () => !!props.file.canEdit && (state.value === State.Defined || state.value === State.Undefined),
);

watch(() => props.file, load, { immediate: true });

onMounted(() => window.addEventListener('blur', disableIframe));
onUnmounted(() => window.removeEventListener('blur', disableIframe));

function enableIframe() {
  iframeDisabled.value = false;
}

function disableIframe() {
  iframeDisabled.value = true;
}

function load() {
  if (!(props.file instanceof ApiFile)) {
    if (props.file.canEdit || props.file.canPreviewDocument) {
      upload();
    } else {
      state.value = props.file.canPreview ? State.Defined : State.Undefined;
    }
  } else {
    loadView();
  }
}

async function loadView() {
  state.value = State.Loading;

  void FileApi.addView(props.file.fileId, signal.value);

  try {
    await StorageApi.load(
      {
        url: props.file.canPreviewDocument ? props.file.pdfUrl : props.file.url,
        onlyHeaders: !props.file.canPreviewDocument,
      },
      signal.value,
    );
    state.value = props.file.canPreviewDocument ? State.Defined : State.Undefined;
  } catch {
    state.value = State.Error;
  }
}

async function upload() {
  state.value = State.Loading;

  try {
    const id = await FileApi.upload(
      {
        tableId: props.tableId,
        parentId: props.parentId,
        file: props.file,
      },
      signal.value,
    );

    const uploadedFile = await FileApi.getUserFile(id, props.file, signal.value);

    emit('upload', uploadedFile);
  } catch {
    state.value = State.Error;
  }
}
</script>

<template>
  <div class="upload-preview">
    <FileNoPreview
      v-if="state === State.Undefined"
      :icon="file.icon"
      :editable="showEdit"
      @edit="$emit('edit', file)"
    />

    <LoaderUi
      v-else-if="state === State.Loading"
      position="static"
      color="white"
      text="Загрузка данных"
    />

    <FileError
      v-else-if="state === State.Error"
      @retry="load"
    />

    <template v-else>
      <div
        v-if="file.canPreviewDocument"
        class="iframe-wrapper"
        @mouseenter="enableIframe"
        @mouseleave="disableIframe"
      >
        <iframe
          class="iframe"
          :class="{ _disabled: iframeDisabled }"
          :src="url"
          width="100%"
          height="100%"
        />
      </div>

      <img
        v-else
        class="image"
        :src="src"
        :alt="file.name"
      />
    </template>

    <ButtonUi
      v-if="showEdit && state === State.Defined"
      class="edit"
      color="blue"
      shape="square"
      @click="$emit('edit', file)"
    >
      <EditIcon />
    </ButtonUi>
  </div>
</template>

<style scoped lang="scss">
.upload-preview {
  position: relative;
}

.iframe-wrapper {
  height: 100%;
}

.iframe {
  border: none;

  &._disabled {
    pointer-events: none;
  }
}

.image {
  width: 100%;
  height: 100%;

  object-fit: scale-down;
  background-color: var(--color-gray-075);
  border-radius: 8px;
}

.edit {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
</style>
