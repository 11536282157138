<template>
  <DropdownUi
    no-overflow
    close-if-outside-click
    :close-if-inner-click="isConfig"
  >
    <template #anchor="{ toggle }">
      <div
        class="toggle"
        :class="{ _gear: isConfig }"
        @click="toggle"
      >
        <GearIcon v-if="isConfig" />

        <template v-else>
          <span>{{ data.info[col.keyRow].title }}</span>
          <DotsIcon />
        </template>
      </div>

      <PreferencesTableModal
        v-if="isConfig"
        v-model="isPreferencesOpen"
        :configs="data"
      />
    </template>

    <component
      :is="componentChild"
      :class="{ filter: !isConfig }"
      :data="data"
      :col="col.value"
      :key_column="col.keyRow"
      :col_filters="colFilters"
      @show-preferences="isPreferencesOpen = true"
    />
  </DropdownUi>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/tables/constants';
import GearIcon from '@/assets/icons/gear.svg';
import DotsIcon from '@/assets/icons/dots-vertical.svg';
import VuexAdapter from '@/services/vuex-adapter.js';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import PreferencesTableModal from '@/components/modals/Table/PreferencesTableModal.vue';
import DateFilter from '@/components/table/filters/DateFilter.vue';
import FlagFilter from '@/components/table/filters/FlagFilter.vue';
import IntegerFilter from '@/components/table/filters/IntegerFilter.vue';
import IdentifierFilter from '@/components/table/filters/IdentifierFilter.vue';
import StringFilter from '@/components/table/filters/StringFilter.vue';
import EyeFilter from '@/components/table/filters/EyeFilter.vue';
import AsyncIdentifier from '@/components/table/filters/AsyncIdentifier.vue';
import { useTableStore } from '@/stores/table.js';

export default defineComponent({
  name: 'TableHeadDropdown',
  components: {
    Date: DateFilter,
    Flag: FlagFilter,
    Integer: IntegerFilter,
    Identifier: IdentifierFilter,
    String: StringFilter,
    Eye: EyeFilter,
    AsyncIdentifier: AsyncIdentifier,
    GearIcon,
    DotsIcon,
    DropdownUi,
    PreferencesTableModal,
  },
  props: ['data', 'col'],
  data: () => ({
    isPreferencesOpen: false,
    identifier: Constants.identifier,
    asyncIdentifier: Constants.asyncIdentifier,
    tableStore: useTableStore(),
  }),
  computed: {
    isConfig() {
      return !!this.data.info[this.col.keyRow].configTitle;
    },
    tableFilterData() {
      return this.tableStore[VuexAdapter.filterTableNameGetter(this.data.name)];
    },
    componentChild() {
      return this.data.info[this.col.keyRow].configTitle !== undefined && this.data.info[this.col.keyRow].configTitle
        ? 'Eye'
        : this.data.info[this.col.keyRow].type;
    },
    colFilters() {
      return this.tableFilterData[this.col.keyRow];
    },
  },
});
</script>

<style scoped lang="scss">
.toggle {
  width: 100%;
  height: 32px;
  margin-top: -4px;
  padding: 4px 8px 4px 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color-gray-600);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight-bold);

  cursor: pointer;

  &._gear {
    transform: translateY(4px);
  }
}

.filter {
  padding: 8px;
}
</style>
