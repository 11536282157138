import Api from '@/services/api/api.js';
import { UserEndPoint } from '@/common/enums/end-point.ts';

export default class UserApi {
  static async getId() {
    const { login_id } = await Api.post(UserEndPoint.GetId);
    return login_id;
  }

  static async getName(id) {
    const { user_fio } = await Api.post(UserEndPoint.GetName, {
      login_id: id,
    });
    return user_fio;
  }
}
