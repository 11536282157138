/** @deprecated Используйте @/common/enums/table-id */
/** Пользователи */
export const USERS_TABLE = 1;
/** Группы пользователей */
export const USERS_GROUPS_TABLE = 2;
/** Доступ */
export const ACCESS_TABLE = 3;
/** Представители */
export const PERSONS_TABLE = 4;
/** Юридические лица */
export const USERS_J_TABLE = 5;
/** Контрагенты */
export const CLIENTS_TABLE = 6;
/** События аудита */
export const EVENT_TYPES_TABLE = 7;
/** Элементы интерфейса */
export const GUI_ELEMENTS_TYPES_TABLE = 8;
/** Роли */
export const USERS_ROLES_TABLE = 9;
/** Комментарии */
export const COMMENTS_TABLE = 11;
/** Регионы */
export const REGIONS_TABLE = 15;
/** Сегменты */
export const SEGMENTS_TABLE = 16;
/** Адреса */
export const ADDRESS_TABLE = 18;
/** Контакты */
export const CONTACTS_TABLE = 19;
/** Системные параметры */
export const SYSPARAMS_TABLE = 26;
/** Файлы */
export const FILES_TABLE = 28;
/** Расчетные счета */
export const ACCOUNTS_TABLE = 30;
/** Банки */
export const BANKS_TABLE = 31;
/** Представители */
export const REPRESENTATIVES_TABLE = 37;
/** Организационно-правовые формы */
export const LEGAL_FORMS_TABLE = 38;
/** Действия скриптов */
export const ACTIONS_TABLE = 39;
/** Условия скриптов */
export const CONDITIONS_TABLE = 40;
/** Модели скриптов */
export const SCRIPT_MODELS_TABLE = 41;
/** Скрипты */
export const SCRIPTS_TABLE = 42;
/** Описание ошибок */
export const ERRORS_DESCRIPTIONS_TABLE = 43;
/** Типы документов */
export const DOC_TYPES_TABLE = 44;
/** Распечатки */
export const PRINTS_TABLE = 45;
/** Родители документов */
export const DOCS_PARENTS_TABLE = 46;
/** Сервера */
export const SERVERS_TABLE = 47;
/** Должности */
export const POSITIONS_TABLE = 48;
/** Проекты */
export const PROJECTS_TABLE = 49;
/** Календарь */
export const CALENDAR_TABLE = 50;
/** Штрих-Коды */
export const CODES_TABLE = 53;
/** Ассоциация Штрих-кодов */
export const CODES_PARENTS_TABLE = 54;
/** Документы */
export const DOCS_TABLE = 55;
/** Точки маршрутов */
export const ROUTES_POINTS_TABLE = 56;
/** Связи документов */
export const DOCS_LINKS_TABLE = 57;
/** Хронология */
export const DOCS_CHRONO_TABLE = 58;
/** Заглушки исполнителей */
export const RESPONSIBLES_DUMMIES_TABLE = 59;
/** Приборки */
export const DASH_BOARDS_TABLE = 60;
/** Индексы */
export const INDEX_DATA_TABLE = 67;
/** Избранное */
export const FAVORITES_TABLE = 68;
/** Типы ссылок */
export const LINK_TYPES_TABLE = 69;
/** Просмотры */
export const OBJECTS_VIEWS_TABLE = 70;
