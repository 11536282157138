import BaseFile from '@/common/models/file/base-file.ts';
import { uniqueId } from 'lodash-es';
import IdPrefix from '@/common/enums/id-prefix.js';
import { useSystemStore } from '@/stores/system.js';
import type DocumentFile from '@/common/models/file/document-file.ts';

export default class TemplateFile extends BaseFile {
  fileId: number;
  documentId: number;
  hash: string;
  url: string;
  previewUrl: string;

  constructor(response: any, file: DocumentFile) {
    super(uniqueId(IdPrefix.TemplateFile), response['Название шаблона'], response['Размер']);

    const systemStore = useSystemStore();

    this.fileId = response['F_ID'];
    this.documentId = response['DOC_ID'];
    this.hash = file.hash;
    this.url = `${systemStore.storageUrl}${this.hash}.${this.extension}`;
    this.previewUrl = `${systemStore.storageUrl}${this.hash}_page0001.jpg`;
  }
}
