<template>
  <svg
    width="109"
    height="87"
    viewBox="0 0 109 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.3497 24.4696L107.711 2.70345C108.334 2.06909 107.883 1 106.993 1H53.0066C52.1167 1 51.6662 2.06909 52.2888 2.70345L73.9371 24.7618C74.1209 24.9491 74.2239 25.2008 74.2239 25.463V53.9958C74.2239 54.8436 75.2131 55.3084 75.8682 54.7685L85.4114 46.9031C85.6424 46.7127 85.7761 46.4293 85.7761 46.1304V25.872C85.7761 25.3477 85.982 24.8442 86.3497 24.4696Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.5775 3.40651C50.3326 2.13793 51.2333 0 53.0127 0H106.987C108.767 0 109.667 2.13792 108.423 3.40651L87.0659 25.1701C86.8821 25.3574 86.7792 25.6091 86.7792 25.8713V46.1273C86.7792 46.7252 86.5117 47.2918 86.0498 47.6725L85.4102 46.8999L86.0498 47.6725L76.5087 55.5371C75.1989 56.6168 73.2208 55.6872 73.2208 53.9919V25.4623L51.5775 3.40651ZM106.987 2.00427L53.0127 2.00427L74.656 24.0601C75.0236 24.4347 75.2295 24.9381 75.2295 25.4623V53.9919L84.7705 46.1273V25.8713C84.7705 25.0849 85.0793 24.3298 85.6307 23.7679L106.987 2.00427Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M94.7131 7.31035C95.1092 7.71028 95.0932 8.34325 94.6772 8.72414L83.7572 18.7241C83.3413 19.105 82.683 19.0896 82.2869 18.6897C81.8908 18.2897 81.9068 17.6567 82.3228 17.2759L93.2428 7.27586C93.6587 6.89498 94.317 6.91042 94.7131 7.31035Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6218 1.33594H43.3346C43.5998 1.33594 43.8542 1.44129 44.0417 1.62883L69.0417 26.6288C69.2293 26.8164 69.3346 27.0707 69.3346 27.3359V46.5026C69.3346 47.0549 68.8869 47.5026 68.3346 47.5026C67.7823 47.5026 67.3346 47.0549 67.3346 46.5026V27.7502L42.9204 3.33594H21.668C18.1511 3.33594 15.5737 3.33672 13.5394 3.50292C11.5169 3.66817 10.1196 3.99195 8.94708 4.58936C6.78321 5.69191 5.02394 7.45118 3.92139 9.61505C3.32399 10.7875 3.0002 12.1848 2.83495 14.2074C2.66875 16.2416 2.66797 18.8191 2.66797 22.3359V44.0026C2.66797 44.5549 2.22025 45.0026 1.66797 45.0026C1.11568 45.0026 0.667969 44.5549 0.667969 44.0026V22.2897C0.667965 18.8285 0.667963 16.1697 0.841592 14.0445C1.01695 11.8983 1.37437 10.2085 2.13938 8.70707C3.43367 6.16688 5.49891 4.10164 8.0391 2.80735C9.54051 2.04234 11.2303 1.68492 13.3766 1.50956C15.5017 1.33593 18.1605 1.33593 21.6218 1.33594Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.332 2.4375C43.8843 2.4375 44.332 2.88522 44.332 3.4375V20.6481C44.332 21.8314 44.3328 22.6587 44.3855 23.3033C44.4372 23.9363 44.5339 24.3045 44.6772 24.5857C44.9808 25.1816 45.4652 25.666 46.0611 25.9696C46.3422 26.1129 46.7105 26.2096 47.3435 26.2613C47.9881 26.314 48.8154 26.3147 49.9987 26.3147H67.2093C67.7616 26.3147 68.2093 26.7625 68.2093 27.3147C68.2093 27.867 67.7616 28.3147 67.2093 28.3147H49.9558C48.8254 28.3148 47.9163 28.3148 47.1806 28.2547C46.424 28.1928 45.7632 28.0625 45.1531 27.7516C44.1809 27.2563 43.3905 26.4659 42.8952 25.4937C42.5843 24.8836 42.4539 24.2228 42.3921 23.4662C42.332 22.7305 42.332 21.8214 42.332 20.6911L42.332 3.4375C42.332 2.88522 42.7797 2.4375 43.332 2.4375Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66797 43C2.22025 43 2.66797 43.4477 2.66797 44V65.6667C2.66797 69.1835 2.66875 71.761 2.83495 73.7952C3.0002 75.8178 3.32399 77.2151 3.92139 78.3876C5.02394 80.5514 6.78321 82.3107 8.94708 83.4132C10.1196 84.0106 11.5169 84.3344 13.5394 84.4997C15.5737 84.6659 18.1511 84.6667 21.668 84.6667H48.3346C51.8515 84.6667 54.4289 84.6659 56.4632 84.4997C58.4857 84.3344 59.883 84.0106 61.0555 83.4132C63.2194 82.3107 64.9787 80.5514 66.0812 78.3876C66.6786 77.2151 67.0024 75.8178 67.1676 73.7952C67.3339 71.761 67.3346 69.1835 67.3346 65.6667V46.5C67.3346 45.9477 67.7823 45.5 68.3346 45.5C68.8869 45.5 69.3346 45.9477 69.3346 46.5V65.7129C69.3346 69.1742 69.3346 71.833 69.161 73.9581C68.9857 76.1043 68.6282 77.7941 67.8632 79.2955C66.5689 81.8357 64.5037 83.901 61.9635 85.1953C60.4621 85.9603 58.7723 86.3177 56.626 86.493C54.5009 86.6667 51.8421 86.6667 48.3809 86.6667H21.6217C18.1605 86.6667 15.5017 86.6667 13.3766 86.493C11.2303 86.3177 9.54051 85.9603 8.0391 85.1953C5.49891 83.901 3.43367 81.8357 2.13938 79.2955C1.37437 77.7941 1.01695 76.1043 0.841592 73.9581C0.667963 71.833 0.667965 69.1741 0.667969 65.7129V44C0.667969 43.4477 1.11568 43 1.66797 43Z"
      fill="black"
    />
    <path
      d="M9 58.0703C9 56.9657 9.89543 56.0703 11 56.0703H59C60.1046 56.0703 61 56.9657 61 58.0703V70.0703C61 74.4886 57.4183 78.0703 53 78.0703H17C12.5817 78.0703 9 74.4886 9 70.0703V58.0703Z"
      fill="#C3CADB"
    />
    <path
      d="M58 46.0703H12C10.3431 46.0703 9 47.4135 9 49.0703C9 50.7272 10.3431 52.0703 12 52.0703H58C59.6569 52.0703 61 50.7272 61 49.0703C61 47.4135 59.6569 46.0703 58 46.0703Z"
      fill="#C3CADB"
    />
    <path
      d="M9 11.8481C9 11.4185 9.34822 11.0703 9.77778 11.0703H16V17.2925C16 17.7221 15.6518 18.0703 15.2222 18.0703H9.77778C9.34822 18.0703 9 17.7221 9 17.2925V11.8481Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.569 10.7524C12.409 10.7524 12.2721 10.8712 12.2443 11.0341L12.2379 11.0874H11.5557L11.5951 10.9156C11.6785 10.4267 12.0891 10.0703 12.569 10.0703H14.056C14.5774 10.0703 15 10.5075 15 11.0469C15 11.0945 14.9966 11.142 14.99 11.189L14.307 15.9925L14.2428 16.3179C14.1011 17.0365 13.1749 17.191 12.822 16.5549L12.5398 16.0461L12.1849 16.6755C12.047 16.92 11.794 17.0703 11.5205 17.0703H10.5411L10 15.9973L10.2523 14.4314L11.1576 13.1826C11.3029 12.9823 11.5308 12.8643 11.7729 12.8643H12.5143L13.0915 13.7866V14.7011H12.4322V13.9882L12.1557 13.5464H11.7729C11.7383 13.5464 11.7058 13.5632 11.685 13.5918L10.8762 14.7075L10.6889 15.8698L10.9343 16.3883H11.5205C11.5596 16.3883 11.5957 16.3668 11.6154 16.3319L12.2574 15.1934L12.828 15.1954L13.3938 16.2154C13.4443 16.3063 13.5686 16.2755 13.5889 16.1729L13.6872 15.7661L14.3377 11.0898C14.3377 11.1041 14.3397 11.0756 14.3377 11.0898C14.3377 10.9271 14.2132 10.7524 14.056 10.7524H12.569Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'DocumentEmptyByFilterTableIcon',
};
</script>
