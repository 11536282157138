import Api from '@/services/api/api.js';
import { DocumentEndPoint, SystemEndPoint } from '@/common/enums/end-point.ts';
import { ROUTES_POINTS_TABLE } from '@/configs/db-tables.js';
import type { TableId } from '@/common/enums/table-id.ts';
import { type MaybeRef, unref } from 'vue';

export default class SystemApi {
  static async getServerId() {
    const response = await Api.post(SystemEndPoint.GetServerId);
    return response.server_id;
  }

  static async getAutoSignEnabled() {
    const response = await Api.post(SystemEndPoint.GetAutoSingStatus);
    return !!response.status;
  }

  static async addComment(tableId: TableId, parentId: number, comment: MaybeRef<string>) {
    await Api.post(SystemEndPoint.AddComment, {
      parent_table_id: tableId,
      parent_id: parentId,
      comment: unref(comment),
    });
  }

  static async markAsReadAll() {
    await Api.post(SystemEndPoint.SetReadAll, {
      table_id: ROUTES_POINTS_TABLE,
    });
  }

  static async markAsUnread(id: number) {
    await Api.post(SystemEndPoint.SetUnread, {
      parent_table_id: ROUTES_POINTS_TABLE,
      parent_id: id,
    });
  }

  static async markRoutePointAsRead(rpId: number) {
    await Api.post(DocumentEndPoint.SetRouteRead, { rp_id: rpId });
  }

  static async markDocumentAsRead(docId: number) {
    await Api.post(DocumentEndPoint.SetRead, { doc_id: docId });
  }

  static async checkReplicationSystem() {
    return await Api.post(SystemEndPoint.CheckReplicationSystem);
  }
}
