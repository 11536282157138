<script setup lang="ts">
import type { TableId } from '@/common/enums/table-id.ts';
import useBankDetailsForm from '@/composables/forms/use-bank-details-form.ts';
import type BankDetails from '@/common/models/bank-details.ts';
import CancelButton from '@/components/buttons/CancelButton.vue';
import FormBuilder from '@/components/form/FormBuilder.vue';
import ModalForm from '@/components/common/ModalForm.vue';
import { usePreviewStore } from '@/stores/preview';
import { ref, useTemplateRef } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { NotifyTypes } from '@/configs/notify-types';
import SaveButton from '@/components/buttons/SaveButton.vue';
import BankDetailsApi from '@/services/api/bank-details-api.ts';
import { BankDetailsEndPoint } from '@/common/enums/end-point.ts';

const isOpen = defineModel<boolean>({ required: true });

const { tableId, parentId, bankDetails } = defineProps<{
  tableId: TableId;
  parentId: number;
  bankDetails: BankDetails;
}>();

const { INITIAL_FORM_DATA, fields } = useBankDetailsForm();

const previewStore = usePreviewStore();

const formBuilder = useTemplateRef('formBuilder');

const isLoading = ref(false);
const isDirty = ref(false);
const formData = ref(INITIAL_FORM_DATA);

async function onShow() {
  isDirty.value = false;
  formData.value = { ...bankDetails };
}

async function apply() {
  if (!formBuilder.value!.validate()) {
    return;
  }

  isLoading.value = true;

  try {
    await BankDetailsApi.edit({
      tableId,
      parentId,
      id: bankDetails.id,
      formData: formData as any, // TODO: Отказаться при рефакторинге форм
    });
    previewStore.refreshPartlyPreviewAction(BankDetailsEndPoint.Get);
    isDirty.value = false;
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При редактировании банковских реквизитов возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    title="Редактирование банковских реквизитов"
    :confirm="isDirty"
    @show="onShow"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="formData"
        :fields="fields"
        :readonly="isLoading"
        margins
        @update:model-value="isDirty = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />
      <SaveButton
        :loading="isLoading"
        @click="apply"
      />
    </template>
  </ModalForm>
</template>
