<template>
  <div class="address-row">
    <EditAddressModal
      v-model="isEditOpen"
      :table-id="TableId.Clients"
      :address="address"
    />

    <DeleteAddressModal
      v-model="isDeleteOpen"
      :address-id="row['ADDRESS_ID']"
      :address="row['Адрес']"
    />

    <div class="info">
      <p
        v-if="row['Примечание'] || row['Тип']"
        class="top"
      >
        <b
          v-if="row['Примечание']"
          class="comment"
        >
          {{ row['Примечание'] }}
        </b>
        <span
          v-if="row['Тип']"
          class="_muted"
        >
          {{ row['Тип'] }}
        </span>
      </p>

      <p
        v-if="row['Адрес']"
        class="address"
      >
        {{ row['Адрес'] }}
      </p>
    </div>

    <EditBlockRow
      @edit="isEditOpen = true"
      @delete="isDeleteOpen = true"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EditBlockRow from '@/components/clients/rows/EditBlockRow';
import EditAddressModal from '@/components/modals/Address/EditAddressModal.vue';
import { TableId } from '@/common/enums/table-id.ts';
import DeleteAddressModal from '@/components/modals/Address/DeleteAddressModal.vue';
import Address from '@/common/models/address.ts';

export default defineComponent({
  name: 'AddressRow',
  components: {
    DeleteAddressModal,
    EditAddressModal,
    EditBlockRow,
  },
  props: ['row'],
  data() {
    return {
      isEditOpen: false,
      isDeleteOpen: false,
    };
  },
  computed: {
    TableId() {
      return TableId;
    },
    address() {
      return new Address(this.row);
    },
  },
});
</script>

<style scoped lang="scss">
.address-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  margin-right: 8px;

  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  word-break: break-word;
}

.comment {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.address {
  padding: 4px 8px;
  border-left: var(--border-gray-100);
  word-break: break-word;
}
</style>
