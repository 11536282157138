<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue';
import SolutionAutocorrect from '@/components/doc/solution/SolutionAutocorrect.vue';
import { CLIENTS_COMMENTS_GET, DOC_COMMENTS_GET } from '@/configs/end-points.js';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import UploadUi from '@/components/ui/UploadUi.vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import { useAutocorrectStore } from '@/stores/autocorrect.js';
import { usePreviewStore } from '@/stores/preview.js';
import { TableId } from '@/common/enums/table-id.js';
import { notify } from '@kyvg/vue3-notification';
import SystemApi from '@/services/api/system-api.ts';
import ModalForm from '@/components/common/ModalForm.vue';
import useUpload from '@/composables/use-upload.ts';
import useSolutionAutocorrect from '@/composables/use-solution-autocorrect.ts';

const isOpen = defineModel<boolean>({ required: true });

const { tableId, parentId, resolution } = defineProps<{
  tableId: TableId;
  parentId: number;
  resolution?: boolean;
}>();

const autocorrectStore = useAutocorrectStore();
const previewStore = usePreviewStore();

const textareaRef = useTemplateRef('textareaRef');

const { files, upload } = useUpload();

const comment = ref('');
const error = ref('');
const isLoading = ref(false);
const isDirty = ref(false);

const { addSolutionAutocorrect } = useSolutionAutocorrect(isLoading, isDirty, comment, textareaRef);

const showUpload = computed(() => tableId === TableId.Documents);
const title = computed(() => `Добавление ${resolution ? 'резолюции' : 'комментария'}`);

function onShow() {
  isDirty.value = false;
  files.value = [];
  comment.value = '';
}

async function apply() {
  if (!comment.value) {
    error.value = 'Заполните обязательное поле';
    return;
  }

  error.value = '';
  isLoading.value = true;

  const { hasError } = await upload(tableId, parentId);

  if (hasError) {
    isLoading.value = false;
    return;
  }

  try {
    await SystemApi.addComment(tableId, parentId, comment);
    void previewStore.refreshPartlyPreviewAction(CLIENTS_COMMENTS_GET);
    void previewStore.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
    void autocorrectStore.addCommentAutocorrect(comment.value);
    isDirty.value = false;
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: `При добавлении ${resolution ? 'резолюции' : 'комментария'} возникла ошибка.`,
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    :title="title"
    :confirm="isDirty"
    @show="onShow"
  >
    <template #body>
      <!-- TODO: Передавать disabled -->
      <SolutionAutocorrect
        class="item"
        @add-solution-autocorrect="addSolutionAutocorrect"
      />

      <TextareaUi
        ref="textareaRef"
        v-model="comment"
        class="item"
        placeholder="Текст комментария"
        :disabled="isLoading"
        :error="error"
        focus-on-mount
        @update:model-value="isDirty = true"
      />

      <UploadUi
        v-if="showUpload"
        v-model="files"
        class="item"
        :readonly="isLoading"
        @update:model-value="isDirty = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />
      <AddButton
        :loading="isLoading"
        @click="apply"
      />
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
