import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';
import UpdatedFile from '@/common/models/file/updated-file.ts';
import { FileEditorScope } from '@/common/enums/file-editor-scope.ts';
import { Route } from '@/common/enums/route.ts';
import type { Router } from 'vue-router';
import type TemplateFile from '@/common/models/file/template-file.ts';
import type DocumentFile from '@/common/models/file/document-file.ts';
import type { RequiredKey } from '@/common/types/utils.ts';

export type EditorFile = {
  id: string;
  fileId: number;
  documentId: number;
  name: string;
  timestamp: number;
  scope: FileEditorScope;
  isLoaded: boolean;
  url: string;
  updatedUrl?: string;
};

export type UpdatedEditorFile = RequiredKey<EditorFile, 'updatedUrl'>;

type State = {
  files: EditorFile[];
};

export const useFileEditorStore = defineStore(StoreId.FileEditor, {
  state: (): State => ({
    files: [],
  }),
  getters: {
    isFileOpen(state) {
      return (file: EditorFile) =>
        state.files.some(
          (f) => f.documentId === file.documentId && f.name === file.name && f.timestamp !== file.timestamp,
        );
    },
    getFile(state) {
      return (id: string, timestamp: number) =>
        state.files.find((file) => file.id === id && file.timestamp === timestamp);
    },
    getUpdatedFiles(state) {
      return (scope: FileEditorScope) =>
        state.files
          .filter((file): file is UpdatedEditorFile => file.scope === scope && !!file.updatedUrl)
          .map((file) => new UpdatedFile(file));
    },
  },
  actions: {
    addFile(router: Router, newFile: DocumentFile | TemplateFile, isLoaded = false, scope = FileEditorScope.Global) {
      const id = newFile.id.toString();
      const timestamp = Date.now();

      this.files = [
        ...this.files,
        {
          id,
          fileId: newFile.fileId,
          documentId: newFile.documentId,
          name: newFile.name,
          timestamp,
          scope,
          isLoaded,
          url: newFile.url,
        },
      ];

      const route = router.resolve({
        name: Route.FileEditorPage,
        params: { id },
        query: { t: timestamp },
      });

      window.open(route.href, '_blank');
    },
    setFileLoaded(id: string, isLoaded: boolean) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, isLoaded } : file));
    },
    setFileUpdatedUrl(id: string, updatedUrl: string) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, updatedUrl } : file));
    },
    clearFileUpdatedUrl(id: string) {
      this.files = this.files.map((file) => (file.id === id ? { ...file, updatedUrl: undefined } : file));
    },
    removeFile(id: string, timestamp: number) {
      this.files = this.files.filter((file) => file.id !== id && file.timestamp !== timestamp);
    },
    clearScope(scope = FileEditorScope.Global) {
      this.files = this.files.filter((file) => file.scope !== scope);
    },
  },
  persist: true,
});
