<template>
  <div
    class="form form-builder"
    :class="{ _margin: margins }"
  >
    <template
      v-for="(row, rowIdx) in fieldsRows"
      :key="`f${formId}-r${rowIdx}${error?.row === rowIdx ? '-error' : ''}`"
    >
      <ErrorUi
        v-if="error?.row === rowIdx"
        :text="error.message"
      />

      <div
        v-show="_isRowVisible(row)"
        class="form__row"
        :class="[`form__row--${rowIdx}`, { '_last-child-margin': margins, '_spread': spread }]"
      >
        <div
          v-for="(fld, fldIdx) in _fieldsInRow(row)"
          v-show="_isFieldVisible(fld)"
          :id="`form-col-${_getFieldProperty(fld, 'name')}`"
          :key="`f${formId}-r${rowIdx}-c${fldIdx}`"
          class="form__col"
          :style="_getColStyle(row, fldIdx)"
        >
          <div
            v-if="fld.spoiler"
            class="form__col--spoiler"
            @click="toggleSpoiler(fld)"
          >
            <ChevronIcon
              class="chevron"
              :class="{ _open: isSpoilerOpen(fld) }"
            />
            <span>{{ fld.spoiler }}</span>
            <ReducedBadge
              v-if="fld.showBadge"
              v-postfix
              :value="_getCountObjVal(fld)"
            />
          </div>

          <div v-show="isSpoilerOpen(fld)">
            <InputUi
              v-if="fld.type === TYPES_FORM.TYPE_STRING"
              :model-value="_getObjVal(fld) || ''"
              :label="_getFieldPropertyFn(fld, 'label')"
              :required="_isFieldRequired(fld)"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :hint="_getFieldError(fld) ? undefined : _getFieldPropertyFn(fld, 'hint')"
              :error="_getFieldError(fld)"
              :max-length="_getFieldPropertyFn(fld, 'length')"
              :mask="_getFieldPropertyFn(fld, 'mask')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :no-clear="fld.noClear"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <TextareaUi
              v-else-if="fld.type === TYPES_FORM.TYPE_TEXT"
              :style="fld.style"
              :class="_getFieldPropertyFn(fld, 'classes')"
              :model-value="_getObjVal(fld) || ''"
              :label="_getFieldPropertyFn(fld, 'label')"
              :required="_isFieldRequired(fld)"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :hint="_getFieldError(fld) ? undefined : _getFieldPropertyFn(fld, 'hint')"
              :error="_getFieldError(fld)"
              :max-length="_getFieldPropertyFn(fld, 'length')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :rows="_getFieldPropertyFn(fld, 'rows')"
              :no-resize="_getFieldPropertyFn(fld, 'resize') === false"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            >
              <slot :name="fld.slot" />
            </TextareaUi>

            <PromptUi
              v-if="fld.type === TYPES_FORM.TYPE_PROMPT"
              :model-value="_getObjVal(fld) || ''"
              :field="_getFieldPropertyFn(fld, 'field')"
              :label="_getFieldPropertyFn(fld, 'label')"
              :required="_isFieldRequired(fld)"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :hint="_getFieldError(fld) ? undefined : _getFieldPropertyFn(fld, 'hint')"
              :error="_getFieldError(fld)"
              :max-length="_getFieldPropertyFn(fld, 'maxLength')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :no-clear="fld.noClear"
              :rows="_getFieldPropertyFn(fld, 'rows')"
              :no-resize="_getFieldPropertyFn(fld, 'resize') === false"
              :get-hints="fld.getHints"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <NumberInputForm
              v-else-if="fld.type === TYPES_FORM.TYPE_INTEGER"
              :label="_getFieldLabel(fld)"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :caption="_getFieldPropertyFn(fld, 'hint')"
              :min="_getFieldPropertyFn(fld, 'min')"
              :max="_getFieldPropertyFn(fld, 'max')"
              :init-value="_getFieldPropertyFn(fld, 'initValue')"
              :hide-buttons="_getFieldPropertyFn(fld, 'hideButtons')"
              :length="_getFieldPropertyFn(fld, 'length')"
              :readonly="_isFieldReadonly(fld)"
              :disabled="_isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              :classes="_getFieldPropertyFn(fld, 'classes')"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <DateForm
              v-else-if="fld.type === TYPES_FORM.TYPE_DATE"
              :label="_getFieldLabel(fld)"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :caption="_getFieldPropertyFn(fld, 'hint')"
              :readonly="_isFieldReadonly(fld)"
              :disabled="_isFieldDisabled(fld)"
              :copy-handler="_getFieldPropertyFn(fld, 'copyHandler')"
              :min-date="_getFieldPropertyFn(fld, 'minDate')"
              :only-calendar="_getFieldPropertyFn(fld, 'onlyCalendar')"
              :only-input="_getFieldPropertyFn(fld, 'onlyInput')"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              :classes="_getFieldPropertyFn(fld, 'classes')"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <CheckboxForm
              v-else-if="fld.type === TYPES_FORM.TYPE_BOOL"
              :style="fld.style"
              :label="_getFieldLabel(fld)"
              :description="_getFieldPropertyFn(fld, 'hint')"
              :classes="_getFieldPropertyFn(fld, 'classes')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <TileListUi
              v-else-if="fld.type === TYPES_FORM.TYPE_TILE_LIST"
              type="checkbox"
              :options="fld.options"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <DeadlineUi
              v-else-if="fld.type === TYPES_FORM.TYPE_DEADLINE"
              :style="fld.style"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              :exclude-options="fld.excludeOptions"
              :copy-handler="fld.copyHandler"
              :start-date="_getFieldPropertyFn(fld, 'startDate')"
              :count-backwards="_getFieldPropertyFn(fld, 'countBackwards')"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <SwitchInputForm
              v-else-if="fld.type === TYPES_FORM.TYPE_SWITCH"
              :style="fld.style"
              :label="_getFieldLabel(fld)"
              :options="_getFieldPropertyFn(fld, 'options')"
              :classes="_getFieldPropertyFn(fld, 'classes')"
              :title="_getFieldPropertyFn(fld, 'title')"
              :description="_getFieldPropertyFn(fld, 'hint')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :title-top="_getFieldProperty(fld, 'titleTop', false)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <CheckboxSwitchForm
              v-else-if="fld.type === TYPES_FORM.TYPE_CHECKBOX_SWITCH"
              :values="_getFieldPropertyFn(fld, 'values')"
              :label="_getFieldLabel(fld)"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            />

            <ComboboxUi
              v-else-if="fld.type === TYPES_FORM.TYPE_SELECT"
              :ref="fld.name"
              :style="fld.style"
              :class="_getFieldPropertyFn(fld, 'classes')"
              :label="_getFieldPropertyFn(fld, 'label')"
              :hint="_getFieldError(fld) ? undefined : _getFieldPropertyFn(fld, 'hint')"
              :error="_getFieldError(fld)"
              :required="_isFieldRequired(fld)"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :options="fld.getOptions || _getDataByProvider(fld, 'options')"
              :filter="fld.filter"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :multiple="_getFieldPropertyFn(fld, 'multiple', false)"
              :no-clear="fld.noClear"
              :with-custom-option="_getFieldPropertyFn(fld, 'input', false)"
              :update-after-load="_getFieldPropertyFn(fld, 'updateAfterLoad', false)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
              @focus="() => _onFocusField(fld)"
              @blur="() => _onBlurField(fld)"
            >
              <template
                v-for="(item, key) in _getFieldScopedSlots(fld)"
                #[key]="slotScope"
              >
                <slot
                  :name="`${fld.name}:${key}`"
                  v-bind="slotScope"
                />
              </template>
            </ComboboxUi>

            <SearchLegacyUi
              v-else-if="fld.type === TYPES_FORM.TYPE_SEARCH"
              :style="fld.style"
              :class="_getFieldPropertyFn(fld, 'classes')"
              :value-field="_getFieldPropertyFn(fld, 'valueField')"
              :key-field="_getFieldPropertyFn(fld, 'keyField')"
              :end-point="_getFieldPropertyFn(fld, 'endPoint')"
              :placeholder="_getFieldPropertyFn(fld, 'placeholder')"
              :model-value="_getObjVal(fld)"
              :label-formatter="_getFieldProperty(fld, 'labelFormatter')"
              :query-formatter="_getFieldProperty(fld, 'queryFormatter')"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <RadioCardsForm
              v-else-if="fld.type === TYPES_FORM.RADIO_CARDS"
              :ref="fld.name"
              :label="_getFieldLabel(fld)"
              :hint="_getFieldPropertyFn(fld, 'hint')"
              :options="_getDataByProvider(fld, 'options')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <RadioButtonsForm
              v-else-if="fld.type === TYPES_FORM.RADIO_BUTTONS"
              :ref="fld.name"
              :label="_getFieldLabel(fld)"
              :hint="_getFieldPropertyFn(fld, 'hint')"
              :options="_getDataByProvider(fld, 'options')"
              :disabled="_isFieldReadonly(fld) || _isFieldDisabled(fld)"
              :error="_getFieldError(fld)"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <SearchMultipleUi
              v-else-if="fld.type === TYPES_FORM.TYPE_SEARCH_MULTIPLE"
              :class="_getFieldPropertyFn(fld, 'classes')"
              :placeholder="fld.placeholder"
              :add-icon="fld.addIcon"
              :add-text="fld.addText"
              :get-options="fld.getOptions"
              :get-card="fld.getCard"
              :card-footer="fld.cardFooter"
              :model-value="_getObjVal(fld)"
              @update:model-value="(val) => _setObjVal(fld, val)"
            />

            <div v-else-if="fld.type === TYPES_FORM.TYPE_SUB_TEXT">
              <p
                :ref="`subtext#${fld.name}`"
                class="sub-text"
                :style="fld.style"
                :class="[_getFieldPropertyFn(fld, 'classes'), `_size-${_getFieldProperty(fld, 'size', 'l')}`]"
                v-html="_getFieldLabel(fld)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { compareObjects, getValue, setValue } from '@/common/utils/utils.js';
import { ofRequired, ofRules } from '@/common/utils/wrapper.js';
import InputUi from '@/components/ui/InputUi.vue';
import { TYPE_SELECT, TYPES_FORM } from '@/configs/form';
import NumberInputForm from '@/components/form/NumberInputForm';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import DateForm from '@/components/form/DateForm';
import CheckboxForm from '@/components/form/CheckboxForm';
import SwitchInputForm from '@/components/form/SwitchInputForm';
import CheckboxSwitchForm from '@/components/form/CheckboxSwitchForm';
import TileListUi from '@/components/ui/TileListUi.vue';
import RadioCardsForm from '@/components/form/RadioCardsForm.vue';
import RadioButtonsForm from '@/components/form/RadioButtonsForm.vue';
import SearchLegacyUi from '@/components/ui/SearchLegacyUi.vue';
import SearchMultipleUi from '@/components/ui/SearchMultipleUi.vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import PromptUi from '@/components/ui/PromptUi.vue';
import DeadlineUi from '@/components/ui/DeadlineUi.vue';
import { DeadlineType } from '@/common/enums/deadline-type.ts';
import ErrorUi from '@/components/ui/ErrorUi.vue';
import FormError from '@/common/models/form-error';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import ReducedBadge from '@/components/common/ReducedBadge.vue';
import { uniqueId } from 'lodash-es';

export default {
  name: 'FormBuilder',
  components: {
    ComboboxUi,
    ErrorUi,
    DeadlineUi,
    PromptUi,
    ChevronIcon,
    SearchMultipleUi,
    SearchLegacyUi,
    RadioCardsForm,
    RadioButtonsForm,
    TileListUi,
    CheckboxSwitchForm,
    SwitchInputForm,
    CheckboxForm,
    DateForm,
    TextareaUi,
    NumberInputForm,
    InputUi,
    ReducedBadge,
  },
  inject: { scope: { default: null } },

  /**
   * Событие изменения данных: update:modelValue. В аргументе события - объект данных
   *
   * Событие фокуса (focus) и потери фокуса (blur) контролом поля. В аргументе - объект описания поля (FormField).
   *
   * Именованые слоты передаются для полей типа slot текущего уровня.
   * Либо для вложенных полей типа slot, например в блоках. В этом случае имя слота имеет формат <имя блока>:<имя слота>.
   * Вложенность может быть многоуровневой.
   * А также, в качестве слотов для ячеек таблицы, указанной в полях типа table. В этом случае имя слота должно быть <имя таблицы>:<имя слота>
   */

  props: {
    /**
     * Имя формы.
     * На верхнем уровне не требуется. Служит для корректного постороения имен объектов во вложенных формах.
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * Данные формы.
     * Объект, свойства которого биндятся с контролами формы.
     */
    modelValue: {
      type: Object,
      required: true,
    },
    /**
     * Поля формы.
     * Массив, определяющий контролы формы, их тип, данные с которыми они связаны, их расположение и т.п.
     * Массив определяется типом FieldsArray (см. fields.d.ts)
     */
    fields: {
      type: Array,
      required: true,
    },

    disableScrollIntoFirstError: {
      type: Boolean,
      default: false,
    },

    /**
     *  Перевод формы в только для чтения
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * @deprecated Задавайте отступы снаружи
     */
    margins: {
      type: Boolean,
      default: false,
    },

    spread: {
      type: Boolean,
      default: false,
    },

    error: FormError,
  },

  emits: ['update:modelValue', 'blur', 'focus'],

  data: function () {
    return {
      TYPES_FORM,
      formId: uniqueId('form-builder-'),
      dataObj: {},
      dataProviders: {},
      errorsObj: {},
      spoilers: {},
    };
  },

  computed: {
    fieldsRows() {
      return this.fields.map((ff) => (Array.isArray(ff) ? ff : [ff]));
    },
    flatFields() {
      return this.fields.flatMap((ff) => ff);
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        this.dataObj = { ...value };
      },
      deep: true,
    },
  },

  created() {
    if (!compareObjects(this.modelValue, this.dataObj)) {
      this.dataObj = { ...this.modelValue };
    }
  },

  methods: {
    // Fields
    _fieldsInRow(row) {
      return row.map((fld) => ({ type: 'string', ...fld }));
    },
    _getHierarchyFieldName(fieldName) {
      if (this.name) {
        return this.name + ':' + fieldName;
      }
      return fieldName;
    },

    _getCountObjVal(field) {
      const value = this._getObjVal(field);
      return value ? value.length : 0;
    },
    // Values
    _getObjVal(field, defaultValue = undefined) {
      if (field) {
        let val = undefined;
        if (field.getter) {
          // есть геттер
          val = field.getter(this.dataObj);
        } else {
          // извлечение по имени
          val = getValue(this.dataObj, field.name);
        }
        return val ?? defaultValue;
      }
      return defaultValue;
    },

    _setObjVal(field, value) {
      if (typeof value === 'string' && TYPES_FORM[TYPE_SELECT] !== field.type) {
        value = value.trim();
      }
      if (field) {
        if (value === this._getObjVal(field)) {
          return;
        }
        this._clearFieldError(field);
        if (field.setter) {
          // есть сеттер
          field.setter(this.dataObj, value);
        } else {
          // запись по имени
          setValue(this.dataObj, field.name, value);
        }
        if (field.onChange && typeof field.onChange === 'function') {
          field.onChange(field, value, this.dataObj);
        }
        this._emitChange({ field, value });
      }
    },

    _emitChange(propChanged) {
      this.$emit('update:modelValue', this.dataObj, propChanged);
    },

    // Field properties
    _getFieldLabel(field) {
      if (field) {
        const label = this._getFieldPropertyFn(field, 'label', '');
        if (label && this._isFieldRequired(field)) {
          return `<b>${label}</b>`;
        }
        return label;
      }
      return '';
    },
    toggleSpoiler(field) {
      this.spoilers = {
        ...this.spoilers,
        [field.name]: !this.spoilers[field.name],
      };
    },
    isSpoilerOpen(fld) {
      return !fld.spoiler || !!this.spoilers[fld.name];
    },
    _getFieldProperty(field, prop, def = undefined) {
      const val = field ? field[prop] : undefined;
      return val != undefined ? val : def;
    },
    _getFieldPropertyFn(field, prop, def = undefined) {
      let propVal = field ? field[prop] : undefined;
      if (propVal) {
        if (typeof propVal === 'function') {
          propVal = propVal(this.dataObj);
        }
      }
      return propVal != undefined ? propVal : def;
    },

    _getColWidthPrc(row, idx) {
      const fld = row[idx];
      let fldCols = fld.gridCols;
      if (!fldCols) {
        // cols не задан - рассчитываем
        const flds = row.filter((f) => this._isFieldVisible(f));
        const autoFields = flds.filter((f) => !f.gridCols).length;
        const definedCols = flds.map((f) => f.gridCols || 0).reduce((prev, curr) => prev + curr, 0);
        fldCols = (12 - definedCols) / autoFields;
      }
      return (100 * fldCols) / 12;
    },

    _getColStyle(row, idx) {
      const styles = [];
      styles.push(`width: ${this._getColWidthPrc(row, idx)}%`);

      return styles.join(';');
    },

    _isRowVisible(fields) {
      return fields.some((field) => this._isFieldVisible(field));
    },

    _isFieldVisible(field) {
      if (field.hidden) {
        if (typeof field.hidden === 'function') {
          return !field.hidden(this.dataObj);
        }
        return !field.hidden;
      }
      return true;
    },

    _isFieldRequired(field) {
      if (field.required) {
        if (typeof field.required === 'function') {
          return field.required(field, this.dataObj);
        }
        return field.required;
      }
      return false;
    },
    _isFieldReadonly(field) {
      if (this.readonly) {
        return true;
      }
      if (field.readonly) {
        if (typeof field.readonly === 'function') {
          return field.readonly(this.dataObj);
        }
        return field.readonly;
      } else if (field.readonly === undefined) return false;
      return false;
    },
    _isFieldDisabled(field) {
      if (this.readonly) {
        return true;
      }

      if (typeof field.disabled === 'boolean') {
        return field.disabled;
      }
      if (typeof field.disabled === 'function') {
        return field.disabled(this.dataObj);
      }
      return false;
    },

    // Field events
    _onFocusField(field) {
      this.$emit('focus', { ...field, hierarchyName: this._getHierarchyFieldName(field.name) });
    },
    _onBlurField(field) {
      this.$emit('blur', { ...field, hierarchyName: this._getHierarchyFieldName(field.name) });
    },

    // Slots
    _getFieldScopedSlots(field) {
      const entries = Object.entries(this.$slots)
        .filter((ent) => ent[0].startsWith(field.name + ':'))
        .map((ent) => [ent[0].substring(field.name.length + 1), ent[1]]);

      return Object.fromEntries(entries);
    },

    // Data providers
    _setProviderData(provider, setter) {
      let result = null;
      if (typeof provider === 'function') {
        // провайдер - функция
        result = provider();
      } else {
        // непосредственно данные
        result = provider;
      }
      if (result instanceof Promise) {
        // промис
        setter(null);
        result.then((res) => {
          setter(res);
        });
      } else {
        setter(result);
      }
    },

    _getDataByProvider(field, property) {
      if (field && property && field.name) {
        const provider = field[property];
        this._setProviderData(provider, (val) => (this.dataProviders[field.name] = val));
        return this.dataProviders[field.name];
      }
      return null;
    },

    // Errors
    _getFieldError(field) {
      if (field && field.name) {
        return this.errorsObj[field.name] ?? '';
      }
      return '';
    },
    _setFieldError(field, msg) {
      if (field && field.name) {
        if (msg) {
          this.errorsObj[field.name] = msg;
        } else {
          this.errorsObj[field.name] = '';
        }
      }
    },
    _clearFieldError(field) {
      this._setFieldError(field, '');
      if (field.clearByChangeFieldsError) {
        field.clearByChangeFieldsError.forEach((fieldName) => (this.errorsObj[fieldName] = ''));
      }
    },

    // Methods
    validate() {
      let valid = true;
      this.flatFields
        .filter((ff) => this._isFieldVisible(ff))
        .forEach((ff) => {
          const req = this._isFieldRequired(ff);
          if (ff.validator || req) {
            const val = this._getObjVal(ff);

            if (ff.type === TYPES_FORM.TYPE_BOOL && val === false && req) {
              this._setFieldError(ff, 'Поле обязательно для выбора');
              valid = false;

              return;
            }

            if (ff.type === TYPES_FORM.TYPE_DEADLINE && val.type === DeadlineType.Date && !val.date) {
              this._setFieldError(ff, 'Поле обязательно для заполнения');
              valid = false;

              return;
            }

            const validators = [
              req ? ofRequired() : null,
              Array.isArray(ff.validator)
                ? ofRules(ff.validator) // массив правил
                : ff.validator, // валидатор
            ];
            const errs = validators
              .map((vv) => {
                if (vv) {
                  const res = vv(val, this.dataObj);
                  if (res !== true) return res;
                  return null;
                }
              })
              .filter((err) => err);
            if (errs.length) {
              this._setFieldError(ff, errs.join(' '));
              valid = false;
            } else {
              this._setFieldError(ff, undefined);
            }
          }

          if (ff?.max) {
            const val = this.dataObj[ff.name];
            if (val && val.length > ff.max) {
              this._setFieldError(ff, 'Значение не должно превышать ' + ff.max + ' символов.');
              valid = false;
            }
          }

          if (ff?.min) {
            const val = this.dataObj[ff.name];
            if (val && val.length < ff.min) {
              this._setFieldError(ff, 'Значение не должно быть меньше ' + ff.min + ' символов.');
              valid = false;
            }
          }
        });
      // вложенные блоки
      this.flatFields
        .filter((ff) => ff.type === 'block')
        .flatMap((ff) => this.$refs[`form#${ff.name}`])
        .forEach((subForm) => {
          if (!subForm.validate()) {
            valid = false;
          }
        });

      this.scrollIntoFirstError();

      return valid;
    },
    scrollIntoFirstError() {
      if (this.disableScrollIntoFirstError || this.scope === 'dialog') {
        return;
      }

      const names = Object.keys(this.errorsObj).filter((k) => Boolean(this.errorsObj[k]));

      if (!names.length) {
        return;
      }

      names
        .map((name) => window.document.getElementById(`form-col-${name}`))
        .pop()
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
    },
  },
};
</script>

<style scoped lang="scss">
.error-ui {
  margin: 8px 0;
}

.sub-text {
  color: var(--color-gray-700);
  font-weight: var(--font-weight);

  &._size-s {
    font-size: var(--font-size-s);
    line-height: var(--line-height-s);
  }

  &._size-l {
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
  }

  &._size-m {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
  }
}
</style>
