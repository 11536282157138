<template>
  <div class="select-doc-route-step-master">
    <FormBuilder
      ref="form"
      v-model="formDataProxy"
      :fields="fields"
      @update:model-value="change"
    />

    <LoaderUi
      v-if="isLoading"
      color="white"
      text="Загрузка данных"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { RADIO_BUTTONS, TYPE_BOOL, TYPE_SEARCH, TYPE_SELECT, TYPE_SUB_TEXT } from '@/configs/form';
import { RouteType } from '@/common/enums/route-type.ts';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOCS_GET, ROUTES_RESPONSIBLES_UNSPECIFIED, ROUTES_STANDARD } from '@/configs/end-points.js';
import { TABLE_SORT_ASC } from '@/configs/tables/table';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import Option from '@/common/models/option/option';
import { useMasterContentStore } from '@/stores/master-content.js';
import { useMasterContractorsStore } from '@/stores/master-contractors.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterParametersStore } from '@/stores/master-parameters.js';
import { useMasterTypeStore } from '@/stores/master-type.js';
import { useActionsStore } from '@/stores/actions.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';

const DOC_ID_NONE_ROUTE = -1;
const DOC_ID_NEW_ROUTE = -2;
const notify = (value, color = '#FF0F1D') => `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="8" fill="none"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4298 17.7511L12.4329 5.74819C12.24 5.41727 11.7619 5.41727 11.569 5.7482L4.57214 17.7511C4.37783 18.0845 4.61828 18.5029 5.00411 18.5029H18.9978C19.3836 18.5029 19.6241 18.0845 19.4298 17.7511ZM13.7288 4.99278C12.9572 3.66907 11.0447 3.66908 10.2731 4.99278L3.27625 16.9957C2.49902 18.329 3.46079 20.0029 5.00411 20.0029H18.9978C20.5411 20.0029 21.5029 18.329 20.7257 16.9957L13.7288 4.99278Z" fill="${color}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8551 9.74664C12.9236 9.35705 12.6239 9 12.2283 9C11.9096 9 11.64 9.23578 11.5976 9.55166L11 14H12.107L12.8551 9.74664ZM11.5 17C12.3284 17 13 16.5523 13 16C13 15.4477 12.3284 15 11.5 15C10.6716 15 10 15.4477 10 16C10 16.5523 10.6716 17 11.5 17Z" fill="${color}"/>
  </svg>
  ${value}
`;

export default defineComponent({
  name: 'StepRoute',
  components: { LoaderUi, FormBuilder },
  mixins: [StepMixin, DbAdapterMixin],
  data() {
    return {
      isLoading: false, // TODO: Отказаться
      standardRoutes: [],
      fields: [
        {
          type: RADIO_BUTTONS,
          name: 'type_route',
          options: [
            {
              code: RouteType.Standard,
              label: 'Стандартный',
            },
            {
              code: RouteType.New,
              label: 'Новый',
            },
            {
              code: RouteType.Copy,
              label: 'Копировать',
              activeColor: '#FFDB65',
            },
            {
              code: RouteType.None,
              label: 'Без маршрута',
              activeColor: '#FFBCBC',
            },
          ],
        },
        {
          type: TYPE_SELECT,
          name: 'route_doc_id',
          placeholder: 'Выберите маршрут из списка',
          options: () => this.standardRoutes,
          required: true,
          style: { marginTop: '6px' },
          hidden: () => this.formDataProxy.type_route !== RouteType.Standard,
        },
        {
          type: TYPE_SEARCH,
          name: 'route_copy_doc_id',
          placeholder: 'Найти документ',
          required: true,
          endPoint: DOCS_GET,
          keyField: 'DOC_ID',
          valueField: 'Содержание',
          queryFormatter: (v) => {
            const getFieldName = this.dbAdapter[this.generateKey];

            const where = Number(v)
              ? `${getFieldName('Содержание')} like '%${v}%' or ${getFieldName('DOC_ID')} = '${v}'`
              : `${getFieldName('Содержание')} like '%${v}%'`;

            return {
              where,
              orderby: `${getFieldName('Содержание')} ${TABLE_SORT_ASC}`,
            };
          },
          labelFormatter: (item) => {
            return `№${item['DOC_ID']} ${item['Содержание']}`;
          },
          style: { marginTop: '6px' },
          hidden: () => this.formDataProxy.type_route !== RouteType.Copy,
        },
        {
          type: TYPE_SUB_TEXT,
          label: notify(
            'Маршрут не будет создан по результатам работы данного мастера. Вы должны будете сделать это самостоятельно в карточке документа.',
            '#e3ae00',
          ),
          style: { marginTop: '6px' },
          classes: ['message-warning'],
          hidden: () => this.formDataProxy.type_route !== RouteType.New,
        },
        {
          type: TYPE_BOOL,
          name: 'route_none',
          label: 'Я понимаю, что это значит',
          style: { marginTop: '8px' },
          classes: ['font-weight-normal'],
          required: true,
          hidden: () => this.formDataProxy.type_route !== RouteType.None,
        },
        {
          type: TYPE_SUB_TEXT,
          label: notify('Документ, который вы создаете, будет остановлен автоматически. Вы не сможете его править.'),
          style: { marginTop: '16px' },
          classes: ['message-error'],
          hidden: () => this.formDataProxy.type_route !== RouteType.None,
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;

    this.standardRoutes = await this.getStandardRoutes();

    const defaultDocId = this.type.defaultDocId;
    if (defaultDocId === DOC_ID_NEW_ROUTE) {
      const RadioType = this.$refs.form.$refs.type_route[0];
      RadioType.select(RadioType.options.find((i) => i.code === RouteType.New));
    } else if (defaultDocId === DOC_ID_NONE_ROUTE) {
      const RadioType = this.$refs.form.$refs.type_route[0];
      RadioType.select(RadioType.options.find((i) => i.code === RouteType.None));
    } else if (defaultDocId) {
      const ComboboxRoutes = this.$refs.form.$refs.route_doc_id[0];
      const defaultDocOption = this.standardRoutes.find((i) => i.code === defaultDocId);
      if (defaultDocOption) {
        ComboboxRoutes.select(defaultDocOption);
      }
    }

    this.isLoading = false;
  },
  computed: {
    ...mapState(useMasterTypeStore, ['type', 'project']),
    ...mapState(useMasterParametersStore, { parametersModel: 'model' }),
    ...mapState(useMasterContentStore, ['content']),
    ...mapState(useMasterDeadlineStore, ['deadline']),
  },
  methods: {
    ...mapActions(useMasterContractorsStore, { setContractors: 'set' }),
    ...mapActions(useActionsStore, {
      fetchStandardRoutesAction: VuexAdapter.getNameAction(ROUTES_STANDARD),
      fetchRoutesResponsibleUnspecifiedAction: VuexAdapter.getNameAction(ROUTES_RESPONSIBLES_UNSPECIFIED),
    }),
    getStandardRoutes() {
      const getFieldName = this.dbAdapter[this.generateKey];
      return this.fetchStandardRoutesAction({
        orderby: `${getFieldName('Содержание')} ${TABLE_SORT_ASC}`,
      }).then((r) => {
        const routes = r.data.items.map((item) => {
          return {
            code: item['DOC_ID'],
            label: item['Содержание'],
          };
        });
        const typeDocIds = this.type.docIds;
        if (typeDocIds) {
          return routes.filter((i) => typeDocIds.includes(i.code));
        } else {
          return routes;
        }
      });
    },
  },
  watch: {
    'modelValue.route_doc_id.code': {
      handler(routeDocId) {
        if (!routeDocId) {
          this.formDataProxy.routes_responsible_unspecified_steps = [];
          this.change();
          this.setContractors([]);
          return;
        }

        this.isLoading = true;

        const request = {
          doc_id: routeDocId,
          substitutions: {
            'DT_ID': this.type.code,
            'Содержание': this.content,
            'Срок': this.deadline,
            'Проект': this.project.label,
            'Рег.номер': this.formDataProxy.reg_number,
            'Дата': this.formDataProxy.reg_date,
            'MASK': null,
          },
        };

        if (this.type.mask) {
          request['substitutions']['MASK'] = this.type.mask;

          for (const maskFormDataKey in this.parametersModel) {
            const parameter = this.parametersModel[maskFormDataKey];
            request['substitutions']['MASK_PARAM' + maskFormDataKey] =
              parameter instanceof Option ? parameter.code : parameter;
          }
        }

        this.fetchRoutesResponsibleUnspecifiedAction(request)
          .then((r) => {
            this.formDataProxy.routes_responsible_unspecified_steps = r.data.items;
            this.change();
            this.setContractors(r.data.items);
          })
          .catch((error) => {
            this.$notify({
              type: NotifyTypes.Error,
              text: 'При получении параметров маршрута возникла ошибка.',
              data: error,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      immediate: true,
    },
  },
});
</script>
