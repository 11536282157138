<script setup>
import { onMounted, ref } from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import vRotate from '@/directives/rotate';

const modelValue = defineModel({
  type: String,
});

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  compact: {
    type: Boolean,
    default: false,
  },
});

const selected = ref();

onMounted(() => {
  selected.value = props.options.find((option) => option.code === modelValue.value) || props.options[0];
});

function handle(option) {
  selected.value = option;
  modelValue.value = option.code;
  option.onClick();
}
</script>

<template>
  <DropdownUi
    v-if="options.length > 1"
    class="dropdown-button-ui"
    tag="ul"
    placement="bottom-end"
    :full-width="!compact"
    close-if-outside-click
    close-if-inner-click
  >
    <template #anchor="{ toggle, isOpen }">
      <ButtonUi
        v-if="selected"
        size="s"
        color="gray"
        mode="outline"
        @click="selected.onClick"
      >
        <component
          :is="selected.icon"
          v-prefix="compact ? null : 's'"
        />

        <span v-if="!compact">{{ selected.title }}</span>

        <button
          class="button-chevron"
          @click.stop="toggle"
        >
          <ChevronIcon v-rotate="isOpen" />
        </button>
      </ButtonUi>
    </template>

    <DropdownItemUi
      v-for="option in options"
      :key="option.code"
      tag="li"
      @click="handle(option)"
    >
      <component
        :is="option.icon"
        v-prefix
      />

      <span>{{ option.title }}</span>
    </DropdownItemUi>
  </DropdownUi>

  <ButtonUi
    v-else-if="options.length === 1"
    size="s"
    color="gray"
    mode="outline"
    @click="options[0].onClick"
  >
    <component
      :is="options[0].icon"
      v-prefix="compact ? null : 's'"
    />

    <span v-if="!compact">{{ options[0].title }}</span>
  </ButtonUi>
</template>

<style scoped lang="scss">
.button-ui {
  @media (hover: hover) {
    &:hover {
      .button-chevron {
        border-left: var(--border-gray-600);
      }
    }
  }
}

.button-chevron {
  align-self: stretch;

  margin: -1px -8px -1px 7px;
  padding: 0 5px 0 4px;

  border-left: var(--border-gray-500);
  transition: border-left-color var(--transition-fast);
}
</style>
