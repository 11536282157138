import LinkIcon from '@/assets/icons/link.svg';
import LinkCard from '@/common/models/card/link-card';
import { markRaw } from 'vue';
import { TableId } from '@/common/enums/table-id.ts';

export default class DocumentCard extends LinkCard {
  constructor(response) {
    super(response, 'DOC_ID', 'Содержание', markRaw(LinkIcon), 'document', TableId.Documents);
  }
}
