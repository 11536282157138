import LinkCard from '@/common/models/card/link-card';
import { markRaw } from 'vue';
import ClientType from '@/common/enums/client-type.js';
import ClientIcon from '@/assets/icons/card/client.svg';
import DepartmentIcon from '@/assets/icons/card/department.svg';
import LegalIcon from '@/assets/icons/card/legal.svg';
import IpIcon from '@/assets/icons/card/ip.svg';
import OwnLegalIcon from '@/assets/icons/card/own-legal.svg';
import PrivatePersonIcon from '@/assets/icons/card/private-person.svg';
import { TableId } from '@/common/enums/table-id.ts';

const ICONS = {
  [ClientType.Client]: ClientIcon,
  [ClientType.Department]: DepartmentIcon,
  [ClientType.Legal]: LegalIcon,
  [ClientType.IP]: IpIcon,
  [ClientType.OwnLegal]: OwnLegalIcon,
  [ClientType.PrivatePerson]: PrivatePersonIcon,
};

export default class ClientCard extends LinkCard {
  constructor(response) {
    const icon = markRaw(ICONS[response.TYPE_ID]);
    super(response, 'CLIENT_ID', 'Название', icon, 'client', TableId.Clients);
  }
}
