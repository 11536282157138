<template>
  <div>
    <FormBuilder
      ref="form"
      v-model="formDataProxy"
      class="routes-responsible-unspecified-step-master"
      :fields="fields"
      @update:model-value="change"
    />

    <LoaderUi
      v-if="isLoading"
      color="white"
      text="Загрузка данных"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { TYPE_BOOL, TYPE_DATE, TYPE_INTEGER, TYPE_SELECT, TYPE_SWITCH, TYPE_TEXT } from '@/configs/form';
import { mapActions, mapState } from 'pinia';
import { ROUTESPOINTS_RESPONSIBLES_GET } from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import { POSITIONS_TABLE, RESPONSIBLES_DUMMIES_TABLE, USERS_GROUPS_TABLE } from '@/configs/db-tables.js';
import { getDateFromJSDate } from '@/common/utils/utils.js';
import DbAdapterMixin from '@/mixins/db-adapter-mixin.js';
import { useListsStore } from '@/stores/lists.js';
import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { TableId } from '@/common/enums/table-id.ts';
import LoaderUi from '@/components/ui/LoaderUi.vue';

const ALL_RESPONSIBLE_TYPE = 'ALL_RESPONSIBLE_TYPE';

export default defineComponent({
  name: 'StepContractor',
  components: { LoaderUi, FormBuilder },
  mixins: [StepMixin, DbAdapterMixin],
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false, // TODO: Отказаться
    };
  },
  computed: {
    ...mapState(useListsStore, {
      routePointsResponsibleGetter: VuexAdapter.getNameGetter(ROUTESPOINTS_RESPONSIBLES_GET),
    }),
    routePointsResponsibleSelect() {
      const type = this.formDataProxy['routes_responsible_type_' + this.step.number] || ALL_RESPONSIBLE_TYPE;

      const routePointsResponsible = this.routePointsResponsibleGetter.filter((item) => {
        if (type === ALL_RESPONSIBLE_TYPE) {
          return true;
        }

        return item.RESPONSIBLE_TABLE_ID === type;
      });

      return routePointsResponsible.map((item) => {
        return {
          code: `${item.RESPONSIBLE_TABLE_ID}_${item.RESPONSIBLE_ID}`,
          data: {
            RESPONSIBLE_TABLE_ID: item.RESPONSIBLE_TABLE_ID,
            RESPONSIBLE_ID: item.RESPONSIBLE_ID,
          },
          label: item['Исполнитель'],
        };
      });
    },
    fields() {
      return [
        [
          {
            type: TYPE_SWITCH,
            name: 'routes_responsible_type_' + this.step.number,
            options: [
              {
                label: 'Все',
                value: ALL_RESPONSIBLE_TYPE,
              },
              {
                label: 'Группа',
                value: USERS_GROUPS_TABLE,
              },
              {
                label: 'Должность',
                value: POSITIONS_TABLE,
              },
              {
                label: 'ФИО',
                value: TableId.Users,
              },
            ],
          },
        ],
        [
          {
            type: TYPE_SELECT,
            name: 'routes_responsible_' + this.step.number,
            placeholder: 'Выберите исполнителя',
            options: () => this.routePointsResponsibleSelect,
            required: () => !this.formDataProxy['routes_responsible_skip_' + this.step.number],
          },
        ],
        [
          {
            type: TYPE_SWITCH,
            name: 'routes_responsible_description_term_' + this.step.number,
            options: [
              {
                label: 'Срок в днях',
                value: DeadlineType.Days,
              },
              {
                label: 'Дата',
                value: DeadlineType.Date,
              },
            ],
            style: { marginTop: '16px' },
            disabled: this.step.item['Редактируемый срок'] === 0,
          },
        ],
        [
          {
            type: TYPE_INTEGER,
            name: 'routes_responsible_description_term_days' + this.step.number,
            min: 1,
            max: 360,
            initValue: 1,
            disabled: this.step.item['Редактируемый срок'] === 0,
            hideButtons: this.step.item['Редактируемый срок'] === 0,
            hidden: (data) => {
              return data['routes_responsible_description_term_' + this.step.number] !== DeadlineType.Days;
            },
          },
          {
            type: TYPE_DATE,
            name: 'routes_responsible_description_term_days_view' + this.step.number,
            onlyInput: true,
            hidden: (data) => {
              return data['routes_responsible_description_term_' + this.step.number] !== DeadlineType.Days;
            },
            disabled: true,
          },
          {
            type: TYPE_DATE,
            name: 'routes_responsible_description_term_date' + this.step.number,
            hidden: (data) => data['routes_responsible_description_term_' + this.step.number] !== DeadlineType.Date,
            required: () => !this.formDataProxy['routes_responsible_skip_' + this.step.number],
            disabled: this.step.item['Редактируемый срок'] === 0,
            minDate: new Date(),
          },
        ],
        [
          {
            type: TYPE_TEXT,
            name: 'routes_responsible_description_' + this.step.number,
            placeholder: 'Описание',
            length: 100,
            resize: false,
            style: { marginTop: '16px' },
            required: () => !this.formDataProxy['routes_responsible_skip_' + this.step.number],
            disabled: this.step.item['Редактируемое описание'] === 0,
          },
        ],
        [
          {
            type: TYPE_BOOL,
            name: 'routes_responsible_skip_' + this.step.number,
            label: 'Пропустить этот шаг',
            hidden: true,
          },
        ],
      ];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(useListsStore, {
      routePointsResponsibleAction: VuexAdapter.getNameAction(ROUTESPOINTS_RESPONSIBLES_GET),
    }),
    async init() {
      this.isLoading = true;
      await this.routePointsResponsibleAction({
        orderby: this.dbAdapter[this.getOrder]('Исполнитель', 'ASC'),
      });
      this.isLoading = false;

      if (this.formDataProxy['routes_responsible_skip_' + this.step.number] === undefined) {
        this.formDataProxy['routes_responsible_skip_' + this.step.number] = false;
      }

      if (!this.formDataProxy['routes_responsible_type_' + this.step.number]) {
        this.formDataProxy['routes_responsible_type_' + this.step.number] = ALL_RESPONSIBLE_TYPE;
      }

      if (!this.formDataProxy['routes_responsible_description_' + this.step.number]) {
        this.formDataProxy['routes_responsible_description_' + this.step.number] = this.step.item['Описание'];
      }

      if (!this.formDataProxy['routes_responsible_' + this.step.number]) {
        if (
          this.step.item['RESPONSIBLE_TABLE_ID'] &&
          this.step.item['RESPONSIBLE_TABLE_ID'] !== RESPONSIBLES_DUMMIES_TABLE
        ) {
          const responsible = this.routePointsResponsibleGetter.findLast((item) => {
            return (
              item['RESPONSIBLE_TABLE_ID'] === this.step.item['RESPONSIBLE_TABLE_ID'] &&
              item['RESPONSIBLE_ID'] === this.step.item['RESPONSIBLE_ID']
            );
          });
          if (responsible) {
            this.formDataProxy['routes_responsible_' + this.step.number] = {
              code: `${responsible.RESPONSIBLE_TABLE_ID}_${responsible.RESPONSIBLE_ID}`,
              data: {
                RESPONSIBLE_TABLE_ID: responsible.RESPONSIBLE_TABLE_ID,
                RESPONSIBLE_ID: responsible.RESPONSIBLE_ID,
              },
              label: responsible['Исполнитель'],
            };
            this.formDataProxy['routes_responsible_type_' + this.step.number] = responsible.RESPONSIBLE_TABLE_ID;
          }
        }
      }

      const term = this.step.item['Срок'] ? DeadlineType.Date : DeadlineType.Days;

      if (!this.formDataProxy['routes_responsible_description_term_' + this.step.number]) {
        this.formDataProxy['routes_responsible_description_term_' + this.step.number] = term;
        this.formDataProxy['routes_responsible_description_term_date' + this.step.number] = this.step.item['Срок'];
        this.formDataProxy['routes_responsible_description_term_days' + this.step.number] = this.step.item['Дней'];
      }

      this.change();
    },
  },
  watch: {
    step() {
      void this.init();
    },
    modelValue: {
      handler(next, prev) {
        const propKey = 'routes_responsible_description_term_days' + this.step.number;
        const viewPropKey = 'routes_responsible_description_term_days_view' + this.step.number;

        if (next[propKey] !== undefined) {
          const number = next[propKey];

          if (number) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + number);
            const date = getDateFromJSDate(currentDate, 'yyyy-MM-dd');
            this.formDataProxy[viewPropKey] = date;
            this.change();
          } else {
            this.formDataProxy[viewPropKey] = null;
          }
        }

        const propResponsibleType = 'routes_responsible_type_' + this.step.number;

        if (
          prev &&
          prev[propResponsibleType] !== undefined &&
          next[propResponsibleType] !== undefined &&
          next[propResponsibleType] !== prev[propResponsibleType] &&
          next[propResponsibleType] !== ALL_RESPONSIBLE_TYPE
        ) {
          this.formDataProxy['routes_responsible_' + this.step.number] = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>
