<template>
  <section class="step-content">
    <div class="left">
      <DocumentMasterHeader animation="opacity" />

      <div class="scroll">
        <LoaderUi
          v-if="isLoading"
          color="white"
          position="static"
          text="Загрузка параметров"
        />

        <FormBuilder
          ref="form"
          v-model="model"
          :fields="fields"
        />
      </div>
    </div>

    <div class="right">
      <UploadPreviews
        v-model="filesModel"
        :table-id="TableId.Users"
        :parent-id="userId"
      />

      <DocumentMasterFooter />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { mapActions, mapState } from 'pinia';
import { COUNTERPARTIES_REGIONS_SEGMENTS__VIEW } from '@/configs/events';
import UploadPreviews from '@/components/document-master/UploadPreviews.vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import DocumentMasterApi from '@/services/api/document-master-api.js';
import { CanceledError } from 'axios';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import DocumentMasterHeader from '@/components/document-master/wrapper/DocumentMasterHeader.vue';
import DocumentMasterFooter from '@/components/document-master/wrapper/DocumentMasterFooter.vue';
import DocumentMasterFields from '@/common/consts/document-master/fields.js';
import AbortMixin from '@/mixins/abort-mixin.js';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import { FileEditorScope } from '@/common/enums/file-editor-scope.ts';
import { useUserStore } from '@/stores/user.ts';
import { useMasterClientsStore } from '@/stores/master-clients.js';
import { useMasterCommentStore } from '@/stores/master-comment.js';
import { useMasterContentStore } from '@/stores/master-content.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterFilesStore } from '@/stores/master-files.js';
import { useMasterLinksStore } from '@/stores/master-links.js';
import { useMasterParametersStore } from '@/stores/master-parameters.js';
import { useMasterTypeStore } from '@/stores/master-type.js';
import { useRolesStore } from '@/stores/roles.js';
import { useFileEditorStore } from '@/stores/file-editor.ts';
import { TableId } from '@/common/enums/table-id.ts';

export default defineComponent({
  name: 'StepContent',
  components: {
    DocumentMasterFooter,
    DocumentMasterHeader,
    LoaderUi,
    UploadPreviews,
    FormBuilder,
  },
  mixins: [AbortMixin, StepMixin],
  mounted() {
    this.fileEditorClearScope(FileEditorScope.Master);
    this.getFields();
  },
  unmounted() {
    this.fileEditorClearScope(FileEditorScope.Master);
  },
  computed: {
    TableId() {
      return TableId;
    },
    ...mapState(useUserStore, { userId: 'id' }),
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useMasterTypeStore, ['type']),
    ...mapState(useMasterContentStore, ['content']),
    ...mapState(useMasterParametersStore, { parameters: 'parameters', parametersModel: 'model' }),
    ...mapState(useMasterLinksStore, ['links']),
    ...mapState(useMasterClientsStore, ['clients']),
    ...mapState(useMasterCommentStore, ['comment']),
    ...mapState(useMasterFilesStore, ['files']),
    ...mapState(useMasterDeadlineStore, { deadline: '$state' }),
    parametersFields() {
      if (this.isLoading) {
        return [];
      }

      return this.parameters.length
        ? DocumentMasterFields.parameters(this.parameters, this.type.code)
        : [DocumentMasterFields.CONTENT];
    },
    fields() {
      return [
        ...this.parametersFields,
        DocumentMasterFields.DEADLINE,
        DocumentMasterFields.LINKS,
        this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW) && DocumentMasterFields.CLIENTS,
        DocumentMasterFields.COMMENT,
      ].filter((field) => !!field);
    },
    model: {
      get() {
        return {
          content: this.content,
          parameters: this.parametersModel,
          deadline: this.deadline,
          links: this.links,
          clients: this.clients,
          comment: this.comment,
        };
      },
      set({ content, parameters, deadline, links, clients, comment }) {
        this.setContent(content);
        this.setParametersModel(parameters);
        this.setDeadline(deadline);
        this.setLinks(links);
        this.setClients(clients);
        this.setComment(comment);
      },
    },
    filesModel: {
      get() {
        return this.files;
      },
      set(files) {
        this.setFiles(files);
      },
    },
  },
  methods: {
    ...mapActions(useMasterContentStore, { setContent: 'set' }),
    ...mapActions(useMasterParametersStore, { setParameters: 'setParameters', setParametersModel: 'setModel' }),
    ...mapActions(useMasterDeadlineStore, { setDeadline: 'set' }),
    ...mapActions(useMasterCommentStore, { setComment: 'set' }),
    ...mapActions(useMasterLinksStore, { setLinks: 'set' }),
    ...mapActions(useMasterClientsStore, { setClients: 'set' }),
    ...mapActions(useMasterFilesStore, { setFiles: 'set' }),
    ...mapActions(useFileEditorStore, { fileEditorClearScope: 'clearScope' }),
    async getFields() {
      if (!this.type.mask || this.parameters.length) {
        return;
      }

      this.isLoading = true;

      try {
        const parameters = await DocumentMasterApi.getParameters(this.type.code, this.abortController.signal);
        this.setParameters(parameters);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        this.$notify({
          type: NotifyTypes.Error,
          text: 'При получении параметров типа документа возникла ошибка.',
          data: error,
        });
        this.back();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.step-content {
  height: 100%;
  display: flex;
}

.left {
  flex: 0 0 320px;
  min-width: 0;

  margin-right: 8px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;
}

.document-master-header {
  margin-bottom: 20px;
}

.scroll {
  flex: 1 1 100%;
  overflow-y: auto;

  margin: -12px -16px -16px -16px;
  padding: 12px 16px 16px 16px;
}

.loader-ui {
  max-height: 250px;
}

.form-builder {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  // TODO: Отказаться от перекрытия стилей
  :deep(.form__row) {
    &:nth-last-child(4),
    &:nth-last-child(5) {
      margin-bottom: 16px;
    }
  }
}

.right {
  flex: 1 1 100%;

  display: flex;
  flex-direction: column;
}

.upload-previews {
  flex: 1 1 100%;
  margin-bottom: 16px;
}
</style>
