import { markRaw } from 'vue';
import DOCIcon from '@/assets/icons/files/doc.svg?component';
import XLSIcon from '@/assets/icons/files/xls.svg?component';
import JPGIcon from '@/assets/icons/files/jpg.svg?component';
import ODSIcon from '@/assets/icons/files/ods.svg?component';
import ODTIcon from '@/assets/icons/files/odt.svg?component';
import PDFIcon from '@/assets/icons/files/pdf.svg?component';
import PNGIcon from '@/assets/icons/files/png.svg?component';
import RTFIcon from '@/assets/icons/files/rtf.svg?component';
import UnknownIcon from '@/assets/icons/files/unknown.svg?component';
import {
  CAN_EDIT_FILE_EXTENSIONS,
  CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS,
  CAN_PREVIEW_FILE_EXTENSIONS,
} from '@/common/consts/file.ts';

export default abstract class BaseFile {
  id: string;
  name: string;
  size?: number;
  title: string;
  extension: string;
  icon: typeof UnknownIcon;
  canEdit: boolean;
  canPreview: boolean;
  canPreviewDocument: boolean;

  protected constructor(id: string, name: string, size?: number) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.title = this.getTitle(name);
    this.extension = this.getExtension(name);
    this.icon = markRaw(this.getIcon(this.extension));
    this.canEdit = this.getCanEdit(this.extension);
    this.canPreview = this.getCanPreview(this.extension);
    this.canPreviewDocument = this.getCanPreviewDocument(this.extension);
  }

  private getTitle(name: string) {
    return name.split('.').slice(0, -1).join('.');
  }

  private getExtension(name: string) {
    return name.split('.').pop()?.toLowerCase() || '';
  }

  private getIcon(extension: string) {
    switch (extension) {
      case 'doc':
      case 'docx':
        return DOCIcon;
      case 'xls':
      case 'xlsm':
      case 'xlsx':
      case 'xltx':
      case 'xlsb':
      case 'xlam':
        return XLSIcon;
      case 'jpg':
      case 'jpeg':
        return JPGIcon;
      case 'ods':
        return ODSIcon;
      case 'odt':
        return ODTIcon;
      case 'pdf':
        return PDFIcon;
      case 'png':
        return PNGIcon;
      case 'rtf':
        return RTFIcon;
      default:
        return UnknownIcon;
    }
  }

  private getCanEdit(extension: string) {
    return CAN_EDIT_FILE_EXTENSIONS.includes(extension);
  }

  private getCanPreview(extension: string) {
    return CAN_PREVIEW_FILE_EXTENSIONS.includes(extension);
  }

  private getCanPreviewDocument(extension: string) {
    return CAN_PREVIEW_DOCUMENT_FILE_EXTENSIONS.includes(extension);
  }

  static async toBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const result = reader.result;
        if (typeof result !== 'string') {
          reject(new Error('Ошибка при конвертировании Blob в Base64'));
        } else {
          resolve(result.split('base64,')[1]);
        }
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
