<template>
  <svg
    width="94"
    height="119"
    viewBox="0 0 94 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.9678 22.2058L63.8484 22.9226C63.7813 23.3251 64.0916 23.6915 64.4997 23.6915H67.3999C67.7094 23.6915 67.9773 23.4765 68.0445 23.1744L68.2597 22.2058"
      stroke="black"
      stroke-width="1.32058"
      stroke-linejoin="round"
    />
    <path
      d="M61 11.9712C61 11.2419 61.5912 10.6506 62.3206 10.6506H72.8852V21.2152C72.8852 21.9446 72.2939 22.5358 71.5646 22.5358H62.3206C61.5912 22.5358 61 21.9446 61 21.2152V11.9712Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.3733 10.1069C67.1048 10.1069 66.875 10.2998 66.8283 10.5642L66.8175 10.6507H65.6721L65.7382 10.3719C65.8782 9.57842 66.5676 9 67.3733 9H69.8701C70.7454 9 71.455 9.70961 71.455 10.585C71.455 10.6622 71.4494 10.7393 71.4382 10.8156L70.2914 18.6116L70.1837 19.1396C69.9458 20.3059 68.3907 20.5567 67.7982 19.5243L67.3244 18.6986L66.7285 19.7201C66.497 20.1168 66.0723 20.3608 65.6129 20.3608H63.9685L63.0601 18.6193L63.4836 16.0779L65.0037 14.0512C65.2475 13.726 65.6303 13.5346 66.0368 13.5346H67.2815L68.2507 15.0315V16.5156H67.1438V15.3586L66.6795 14.6416H66.0368C65.9787 14.6416 65.9241 14.6689 65.8892 14.7154L64.5311 16.5261L64.2168 18.4124L64.6288 19.2539H65.6129C65.6786 19.2539 65.7392 19.219 65.7723 19.1623L66.8502 17.3146L67.8083 17.3179L68.7583 18.9733C68.843 19.1208 69.0518 19.0709 69.0858 18.9043L69.2508 18.244L70.343 10.6545C70.343 10.6778 70.3464 10.6315 70.343 10.6545C70.343 10.3905 70.1341 10.1069 69.8701 10.1069H67.3733Z"
      fill="black"
    />
    <path
      d="M42 50L15 50"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M68 44L15 44"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M68 38L15 38"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.5308 55.01L80.8625 16.1194C80.8954 12.262 80.914 9.57087 80.6577 7.53962C80.4085 5.56598 79.9311 4.52078 79.1922 3.77549C78.4532 3.0302 77.4121 2.54389 75.4407 2.27796C73.4117 2.00428 70.7205 2 66.863 2H43.1932H27.4733C23.6502 2 22.3613 2.04318 21.2567 2.55389C20.152 3.06461 19.2842 4.01852 16.8077 6.93105L5.33444 20.4242C3.35626 22.7506 2.70874 23.556 2.36858 24.4812C2.02843 25.4064 2 26.4394 2 29.4932V91C2 94.8278 2.00425 97.4974 2.27506 99.5117C2.53813 101.468 3.01932 102.505 3.75736 103.243C4.49539 103.981 5.53159 104.462 7.48832 104.725C9.50259 104.996 12.1722 105 16 105H50.0868C50.1989 105.673 50.3233 106.34 50.4598 107H16C8.45753 107 4.68629 107 2.34315 104.657C0 102.314 0 98.5425 0 91V29.4932C0 26.5828 0 25.1276 0.491444 23.791C0.982888 22.4544 1.92552 21.3458 3.81079 19.1286L15.2841 5.63549C17.6413 2.86319 18.82 1.47705 20.4174 0.738524C22.0148 0 23.8343 0 27.4733 0H43.1932H66.863C74.4644 0 78.2652 0 80.6124 2.36732C82.9596 4.73464 82.9272 8.53524 82.8624 16.1364L82.5296 55.1561C81.8691 55.0788 81.2026 55.0298 80.5308 55.01Z"
      fill="black"
    />
    <path
      d="M70.7697 87.4169L92.0345 65.6996C92.6543 65.0667 92.2058 64 91.32 64H37.5775C36.6916 64 36.2432 65.0667 36.8629 65.6996L58.4132 87.7084C58.5962 87.8953 58.6987 88.1465 58.6987 88.4081V116.877C58.6987 117.723 59.6835 118.186 60.3356 117.648L69.8356 109.8C70.0656 109.61 70.1987 109.327 70.1987 109.029V88.8161C70.1987 88.293 70.4037 87.7907 70.7697 87.4169Z"
      fill="#C3CADB"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M78.1987 71L67.6987 81"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'EmptyTableIcon',
};
</script>
