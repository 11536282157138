<script setup lang="ts">
import CancelButton from '@/components/buttons/CancelButton.vue';
import { DOC_GANTT_ITEMS, DOCS_GET } from '@/configs/end-points.js';
import PlayCircleIcon from '@/assets/icons/circle/play.svg';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import Emitter from '@/services/emitter.js';
import { usePreviewStore } from '@/stores/preview.js';
import DocumentApi from '@/services/api/document-api.js';
import { notify } from '@kyvg/vue3-notification';
import { NotifyTypes } from '@/configs/notify-types';
import { ref } from 'vue';
import ModalUi from '@/components/ui/ModalUi.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';

const isOpen = defineModel<boolean>({ required: true });

const { documentId, events } = defineProps<{
  documentId: number;
  events: string[];
}>();

const previewStore = usePreviewStore();

const isLoading = ref(false);

async function activate() {
  isLoading.value = true;

  try {
    await DocumentApi.activate(documentId);

    events.forEach((value) => Emitter.emit(value));
    previewStore.refreshPartlyPreviewAction(DOCS_GET);
    previewStore.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);

    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При отправке документа в работу возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalUi
    v-model="isOpen"
    title="Запустить документ"
    popup
  >
    <template #body>Вы собираетесь отправить документ в работу. Продолжить?</template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />

      <ButtonUi
        :disabled="isLoading"
        @click="activate"
      >
        <SpinnerUi
          v-if="isLoading"
          v-prefix
          color="white"
        />
        <PlayCircleIcon
          v-else
          v-prefix
        />
        <span>Запустить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>
