<script setup lang="ts">
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import route_point_types from '@/configs/route-point-types.js';
import SolutionAutocorrect from '@/components/doc/solution/SolutionAutocorrect.vue';
import IndexSign from '@/components/sign/IndexSign.vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import { DOC_COMMENTS_GET, DOC_GANTT_ITEMS, DOCS_GET } from '@/configs/end-points.js';
import UploadUi from '@/components/ui/UploadUi.vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import Emitter from '@/services/emitter.js';
import { useSystemStore } from '@/stores/system.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useSignStore } from '@/stores/sign.js';
import { useActionsStore } from '@/stores/actions.js';
import { TableId } from '@/common/enums/table-id.ts';
import useUpload from '@/composables/use-upload.ts';
import { notify } from '@kyvg/vue3-notification';
import ModalForm from '@/components/common/ModalForm.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import useSolutionAutocorrect from '@/composables/use-solution-autocorrect.ts';

const isOpen = defineModel<boolean>({ required: true });

const { configs } = defineProps<{
  configs: any;
}>();

const signStore = useSignStore();
const systemStore = useSystemStore();
const previewStore = usePreviewStore();
const actionsStore = useActionsStore() as any;

const textareaRef = useTemplateRef('textareaRef');

const { files, upload } = useUpload();

const comment = ref('');
const isLoading = ref(false);
const isDirty = ref(false);
const isSignLoaded = ref(false);

const { addSolutionAutocorrect } = useSolutionAutocorrect(isLoading, isDirty, comment, textareaRef);

const isAgreement = computed(
  () => configs.routePointType === route_point_types.agreement && configs.title === 'Одобрить',
);
const showSignBlock = computed(() => isAgreement.value && systemStore.isAutoSignEnabled);
const isApplyDisabled = computed(
  () => isLoading.value || (!showSignBlock.value ? false : !signStore.filesForSignAllLoadGetter),
);
const showUpload = computed(() => !showSignBlock.value || (!signStore.filesForSignLengthGetter && isSignLoaded.value));

onMounted(() => {
  if (isAgreement.value) {
    systemStore.fetchAutoSignEnabled(); // TODO: Нужно ли получать здесь, если было получено при авторизации / нужно ли получать при авторизации?
  }
});

function onShow() {
  isDirty.value = false;
  files.value = [];
  comment.value = '';
}

function getRequestComment() {
  let start = '';

  switch (configs.routePointType) {
    case route_point_types.agreement:
      start = configs.title === 'Одобрить' ? 'Согласовано' : 'Не согласовано';
      break;
    case route_point_types.notification:
      start = 'Принято';
      break;
    case route_point_types.assignment:
      start = 'Выполнено';
      break;
  }

  return comment.value ? `${start} / ${comment.value} /` : start;
}

async function sign() {
  if (!(signStore.hasFilesForSignSelectedGetter && isAgreement.value && systemStore.isAutoSignEnabled)) {
    return false;
  }

  try {
    await signStore.applyFilesForSignAction(configs.doc_id);
    return false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При подписании файлов возникла ошибка.',
      data: error,
    });
    return true;
  }
}

async function action() {
  try {
    await actionsStore[configs.action]({
      rp_id: configs.rp_id,
      comment: getRequestComment(),
    });
    configs.events.forEach((value: string) => Emitter.emit(value));
    void previewStore.refreshPartlyPreviewAction(DOCS_GET);
    void previewStore.refreshPartlyPreviewAction(DOC_COMMENTS_GET);
    void previewStore.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);
    return false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При отправке запроса возникла ошибка.',
      data: error,
    });
    return true;
  }
}

async function apply() {
  isLoading.value = true;

  const isSignError = await sign();

  if (isSignError) {
    isLoading.value = false;
    return;
  }

  const { hasError: isUploadError } = await upload(TableId.Documents, configs.doc_id);

  if (isUploadError) {
    isLoading.value = false;
    return;
  }

  const isActionError = await action();

  isLoading.value = false;

  if (!isActionError) {
    isDirty.value = false;
    isOpen.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    :title="configs.title"
    :confirm="isDirty"
    @show="onShow"
  >
    <template #body>
      <!-- TODO: Передавать disabled -->
      <SolutionAutocorrect
        class="item"
        @add-solution-autocorrect="addSolutionAutocorrect"
      />

      <TextareaUi
        ref="textareaRef"
        v-model="comment"
        class="item"
        placeholder="Текст резолюции"
        :disabled="isLoading"
        focus-on-mount
        @update:model-value="isDirty = true"
      />

      <UploadUi
        v-if="showUpload"
        v-model="files"
        class="item"
        :readonly="isLoading"
        @update:model-value="isDirty = true"
      />

      <!-- TODO: Помечать форму isDirty = true, передавать disabled -->
      <IndexSign
        v-if="showSignBlock"
        v-show="signStore.filesForSignLengthGetter"
        class="item"
        :doc-id="configs.doc_id"
        @loaded="isSignLoaded = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />

      <ButtonUi
        :color="configs.color"
        :disabled="isApplyDisabled"
        @click="apply"
      >
        <SpinnerUi
          v-if="isLoading"
          v-prefix
          color="white"
        />
        <component
          :is="configs.icon"
          v-else
          v-prefix
        />
        <span>{{ configs.title }}</span>
      </ButtonUi>
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
