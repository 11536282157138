<script setup lang="ts">
import { computed, ref } from 'vue';
import { notLike } from '@/common/utils/utils.js';
import CancelButton from '@/components/buttons/CancelButton.vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import CloseDocOrdersModal from '@/components/modals/Doc/CloseDocOrdersModal.vue';
import DocumentApi from '@/services/api/document-api.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';
import Emitter from '@/services/emitter.js';
import { useListsStore } from '@/stores/lists';
import VuexAdapter from '@/services/vuex-adapter';
import { DOC_GANTT_ITEMS } from '@/configs/end-points';
import { notify } from '@kyvg/vue3-notification';
import useAbort from '@/composables/use-abort';
import ModalForm from '@/components/common/ModalForm.vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';

const isOpen = defineModel<boolean>({ required: true });

const { documentId, events } = defineProps<{
  documentId: number;
  events: string[];
}>();

const { signal, abort } = useAbort();

const listsStore = useListsStore() as any;

const isConfirmOpen = ref(false);
const isLoading = ref(false);
const isDirty = ref(false);
const isCompleted = ref(false);
const isClosingConfirmed = ref<null>(null);
const comment = ref('');
const unconfirmedOrdersRpIds = ref<any[]>([]);

const title = computed(() => (isCompleted.value ? 'Снять с контроля' : 'Остановка'));
const action = computed(() => (isCompleted.value ? 'снять документ с контроля' : 'остановить документ'));

async function onShow() {
  isClosingConfirmed.value = null;
  comment.value = '';
  unconfirmedOrdersRpIds.value = [];

  isLoading.value = true;

  // TODO: Обработать ошибки
  const [unconfirmedOrders, progress] = await Promise.all([
    await getUnconfirmedOrdersRpIds(),
    await DocumentApi.getProgress(documentId, signal.value),
  ]);

  unconfirmedOrdersRpIds.value = unconfirmedOrders;
  isCompleted.value = progress === 100;

  if (unconfirmedOrders.length) {
    isConfirmOpen.value = true;
  }

  isLoading.value = false;
}

async function getUnconfirmedOrdersRpIds() {
  const data = await listsStore[VuexAdapter.getNameAction(DOC_GANTT_ITEMS)]({ doc_id: documentId });

  return data.data.items
    .filter((item: any) => ConstantsRPS.rp_status_type_completed_id == item.RPS_ID && notLike(item.PARENTS, '##'))
    .map((item: any) => item.RP_ID);
}

async function stop() {
  isLoading.value = true;

  try {
    // TODO: Отправлять параллельно
    if (isClosingConfirmed.value && unconfirmedOrdersRpIds.value.length) {
      for (const rp_id of unconfirmedOrdersRpIds.value) {
        await DocumentApi.confirmRoutepoint(rp_id, 'Подтверждено при остановке документа');
      }
    }

    await DocumentApi.stop(documentId, comment.value);
    events.forEach((value) => Emitter.emit(value));
    isDirty.value = false;
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При остановке документа возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}

function onConfirmModalHide() {
  if (isClosingConfirmed.value === null) {
    isOpen.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    :title="title"
    :confirm="isDirty"
    @show="onShow"
    @hide="abort"
  >
    <template #body>
      <TextareaUi
        v-model="comment"
        :label="`Вы собираетесь ${action}. Данная операция необратима! Продолжить?`"
        placeholder="Комментарий"
        :disabled="isLoading"
        @update:model-value="isDirty = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />

      <ButtonUi
        :disabled="isLoading"
        @click="stop"
      >
        <SpinnerUi
          v-if="isLoading"
          v-prefix
          color="white"
        />
        <StopCircleIcon
          v-else
          v-prefix
        />
        <span>Остановить</span>
      </ButtonUi>
    </template>
  </ModalForm>

  <CloseDocOrdersModal
    v-model="isConfirmOpen"
    v-model:confirmed="isClosingConfirmed"
    @hide="onConfirmModalHide"
  />
</template>
