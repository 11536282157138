import ApiFile from '@/common/models/file/api-file.ts';
import { TableId } from '@/common/enums/table-id.ts';

export default class DocumentFile extends ApiFile {
  tableId: TableId;
  parentId: number;
  user: string;
  signCount: number;
  documentId: number;
  isLast: boolean;
  downloadName: string;

  constructor(response: any) {
    super(response);
    this.tableId = response['PARENT_TABLE_ID'];
    this.parentId = response['PARENT_ID'];
    this.user = response['Пользователь'];
    this.signCount = response['ЭП'];
    this.documentId = response['DOC_ID'];
    this.isLast = response['Индикатор'] > 0;
    this.downloadName = `${this.documentId}_${this.revision.value}_${this.name}`;
  }
}
