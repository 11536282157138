import { type Component, defineComponent } from 'vue';

export type Icon = {
  name: string;
  component: unknown;
  raw: string;
  import: string;
};

const iconsImport = import.meta.glob('@/assets/icons/**/*.svg', { eager: true });
const rawImport = import.meta.glob('@/assets/icons/**/*.svg', { eager: true, query: '?raw' });

function toPascalCase(str: string) {
  return str.replace(/(?:^|-)(\w)/g, (_, char) => char.toUpperCase());
}

export function useIcons() {
  return Object.entries(iconsImport).reduce((acc: Record<string, Icon[]>, [path, component]) => {
    const relativePath = path.replace(/^.*\/assets\/icons\//, '');
    const pathParts = relativePath.split('/');
    const isRoot = pathParts.length === 1;
    const folder = isRoot ? 'root' : pathParts[0];

    const fileName = pathParts
      .slice(isRoot ? 0 : 1)
      .join('/')
      .replace('.svg', '');

    const name = toPascalCase(fileName);
    const importPath = path.replace('/src', '@');

    if (!acc[folder]) {
      acc[folder] = [];
    }

    acc[folder].push({
      name,
      import: `import ${name}Icon from '${importPath}';`, // Формируем import-путь
      component: defineComponent(component as { default: Component }).default,
      raw: (rawImport[path] as { default: string }).default,
    });

    return acc;
  }, {});
}
