<template>
  <footer
    v-if="!controlsDisabled"
    class="document-master-footer"
  >
    <CloseButton
      v-if="showForceClose"
      @click="close"
    />

    <template v-else>
      <CancelButton
        v-if="!isCreated"
        @click="cancel"
      />

      <ButtonUi
        v-if="!isFirstPage && !isCreated"
        color="black"
        @click="back"
      >
        Назад
      </ButtonUi>

      <ButtonUi
        v-if="!isLastPage"
        @click="next"
      >
        Далее
      </ButtonUi>

      <ButtonUi
        v-else
        @click="ready"
      >
        Готово
      </ButtonUi>
    </template>
  </footer>
</template>

<script>
import { defineComponent } from 'vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { mapActions, mapState } from 'pinia';
import CancelButton from '@/components/buttons/CancelButton.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import hotkeys from 'hotkeys-js';
import { useMasterStore } from '@/stores/master.ts';

const HOTKEY_NEXT = 'ctrl+s, command+s';

export default defineComponent({
  name: 'DocumentMasterFooter',
  components: {
    CloseButton,
    CancelButton,
    ButtonUi,
  },
  mounted() {
    hotkeys(HOTKEY_NEXT, 'modal', this.onHotkeyNext);
  },
  unmounted() {
    hotkeys.unbind(HOTKEY_NEXT, 'modal', this.onHotkeyNext);
  },
  computed: {
    ...mapState(useMasterStore, ['controlsDisabled', 'isCreated', 'showForceClose', 'isFirstPage', 'isLastPage']),
  },
  methods: {
    ...mapActions(useMasterStore, ['close', 'cancel', 'back', 'next', 'ready']),
    onHotkeyNext(event) {
      event.preventDefault();
      if (this.isLastPage) {
        this.ready();
      } else {
        this.next();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
</style>
