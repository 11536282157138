<script setup>
import FilePreviewPage from '@/components/doc/file-preview/FilePreviewPage.vue';
import { onMounted, ref } from 'vue';
import { State } from '@/common/enums/state.ts';
import FileApi from '@/services/api/file-api.js';
import useAbort from '@/composables/use-abort.js';
import StorageApi from '@/services/api/storage-api.js';

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  previewUrl: {
    type: String,
    required: true,
  },
  icon: {
    type: Object,
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  canPreview: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['select']);

const { signal } = useAbort();

const state = ref(State.Undefined);

onMounted(load);

async function load() {
  if (!props.canEdit) {
    return;
  }

  state.value = State.Loading;

  void FileApi.addView(props.id, signal.value);

  try {
    await StorageApi.load(
      {
        url: props.canPreview ? props.previewUrl : props.url,
        onlyHeaders: !props.canPreview,
      },
      signal.value,
    );
    state.value = props.canPreview ? State.Defined : State.Undefined;
  } catch {
    state.value = State.Error;
  }
}

async function onClick() {
  if (state.value === State.Defined || state.value === State.Undefined) {
    emit('select');
  }
}
</script>

<template>
  <div
    class="template-preview"
    @click="onClick"
  >
    <FilePreviewPage
      :state="state"
      :url="previewUrl"
      :alt="`${name}. Страница 1`"
      :icon="icon"
      @retry="load"
    />
    <div class="title">{{ name }}</div>
  </div>
</template>

<style scoped lang="scss">
.template-preview {
  padding: 8px;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  transition: background-color var(--transition-fast);

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: var(--color-gray-100);
    }
  }
}

.file-preview-page {
  margin-bottom: 8px;

  aspect-ratio: 1 / 1.414;
  object-fit: contain;
  border-radius: 8px;
}

.title {
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  text-align: center;
  word-break: break-word;
}
</style>
