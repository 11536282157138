<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.7587 1L12 1C12.5523 1 13 1.44772 13 2C13 2.55229 12.5523 3 12 3H6.8C5.94342 3 5.36113 3.00078 4.91104 3.03755C4.47262 3.07337 4.24842 3.1383 4.09202 3.21799C3.7157 3.40973 3.40973 3.7157 3.21799 4.09202C3.1383 4.24842 3.07337 4.47262 3.03755 4.91104C3.00078 5.36113 3 5.94342 3 6.8V17.2C3 18.0566 3.00078 18.6389 3.03755 19.089C3.07337 19.5274 3.1383 19.7516 3.21799 19.908C3.40973 20.2843 3.7157 20.5903 4.09202 20.782C4.24842 20.8617 4.47262 20.9266 4.91104 20.9624C5.36113 20.9992 5.94342 21 6.8 21H17.2C18.0566 21 18.6389 20.9992 19.089 20.9624C19.5274 20.9266 19.7516 20.8617 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C20.8617 19.7516 20.9266 19.5274 20.9624 19.089C20.9992 18.6389 21 18.0566 21 17.2V12C21 11.4477 21.4477 11 22 11C22.5523 11 23 11.4477 23 12V17.2413C23 18.0463 23 18.7106 22.9558 19.2518C22.9099 19.8139 22.8113 20.3306 22.564 20.816C22.1805 21.5686 21.5686 22.1805 20.816 22.564C20.3306 22.8113 19.8139 22.9099 19.2518 22.9558C18.7106 23 18.0463 23 17.2413 23H6.75868C5.95372 23 5.28936 23 4.74817 22.9558C4.18608 22.9099 3.66937 22.8113 3.18404 22.564C2.43139 22.1805 1.81947 21.5686 1.43597 20.816C1.18868 20.3306 1.09012 19.8139 1.04419 19.2518C0.999977 18.7106 0.999988 18.0463 1 17.2413V6.7587C0.999988 5.95373 0.999977 5.28937 1.04419 4.74817C1.09012 4.18608 1.18868 3.66937 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C3.66937 1.18868 4.18608 1.09012 4.74817 1.04419C5.28937 0.999977 5.95373 0.999988 6.7587 1Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.585 18.0577C14.3196 18.0049 13.977 18 12.9998 18H5.99976C4.62329 18 3.46096 18.9279 3.10897 20.1942C2.96106 20.7263 2.40979 21.0377 1.87768 20.8898C1.34557 20.7419 1.03412 20.1906 1.18203 19.6585C1.76833 17.5494 3.70183 16 5.99976 16H12.9998C13.0389 16 13.0775 16 13.1155 16C13.9331 15.9998 14.4904 15.9997 14.9752 16.0961C16.9587 16.4906 18.5092 18.0411 18.9037 20.0246L17.9229 20.2197L18.9037 20.0246C19.0001 20.5094 19 21.0667 18.9998 21.8843C18.9998 21.9223 18.9998 21.9609 18.9998 22C18.9998 22.5523 18.552 23 17.9998 23C17.4475 23 16.9998 22.5523 16.9998 22C16.9998 21.0228 16.9949 20.6802 16.9421 20.4148C16.7054 19.2247 15.7751 18.2944 14.585 18.0577Z"
      fill="#9DA4B4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.7071 2.29289C23.0976 2.68342 23.0976 3.31658 22.7071 3.70711L18.7071 7.70711C18.3166 8.09763 17.6834 8.09763 17.2929 7.70711L15.2929 5.70711C14.9024 5.31658 14.9024 4.68342 15.2929 4.29289C15.6834 3.90237 16.3166 3.90237 16.7071 4.29289L18 5.58579L21.2929 2.29289C21.6834 1.90237 22.3166 1.90237 22.7071 2.29289Z"
      fill="#9DA4B4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 6.5C8.34315 6.5 7 7.84315 7 9.5C7 11.1569 8.34315 12.5 10 12.5C11.6569 12.5 13 11.1569 13 9.5C13 7.84315 11.6569 6.5 10 6.5ZM5 9.5C5 6.73858 7.23858 4.5 10 4.5C12.7614 4.5 15 6.73858 15 9.5C15 12.2614 12.7614 14.5 10 14.5C7.23858 14.5 5 12.2614 5 9.5Z"
      fill="#9DA4B4"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'ContractorIcon',
};
</script>
