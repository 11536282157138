import { defineStore } from 'pinia';
import SystemApi from '@/services/api/system-api.ts';
import mssqlAdapter from '@/services/adapters/mssql.js';
import postgresqlAdapter from '@/services/adapters/postgresql.js';
import StoreId from '@/common/enums/store-id.js';

export const useSystemStore = defineStore(StoreId.System, {
  state: () => ({
    serverId: null,
    dbType: null,
    storageUrl: null,
    isAutoSignEnabled: false,
    isCollapsed: false,
    isCollapsedMinimizer: false,
    systemRPLObjects: null,
    fileWorkingType: 'edit',
  }),
  getters: {
    isMssql(state) {
      return state.dbType === 'mssql';
    },
    dbAdapter() {
      return this.isMssql ? mssqlAdapter : postgresqlAdapter;
    },
  },
  actions: {
    setDBType(dbType) {
      this.dbType = dbType;
    },
    setStorage(storageUrl) {
      this.storageUrl = storageUrl;
    },
    async fetchServerId() {
      this.serverId = await SystemApi.getServerId();
    },
    async fetchAutoSignEnabled() {
      this.isAutoSignEnabled = await SystemApi.getAutoSignEnabled();
    },
    setIsCollapsed(isCollapsed) {
      this.isCollapsed = isCollapsed;
    },
    setIsCollapsedMinimizer(isCollapsedMinimizer) {
      this.isCollapsedMinimizer = isCollapsedMinimizer;
    },
    async systemRPLObjectsCheckAction() {
      this.systemRPLObjects = await SystemApi.checkReplicationSystem();
    },
    setFileWorkingType(fileWorkingType) {
      this.fileWorkingType = fileWorkingType;
    },
  },
  persist: true,
});
