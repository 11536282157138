import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M4 10H2a.683.683 0 0 0-.677.677v4c0 .37.308.677.677.677s.677-.308.677-.677V14H4c.738 0 1.323-.585 1.323-1.323v-1.323C5.323 10.584 4.738 10 4 10m0 2.677H2.677v-1.323H4zM8.338 10H7.015a.683.683 0 0 0-.677.677v4c0 .37.308.677.677.677h1.323c1.108 0 2-.893 2-2V12c0-1.108-.892-2-2-2m.647 3.323c0 .37-.308.677-.677.677H7.63v-2.677h.677c.369 0 .677.308.677.677zM14 12h-1.323v-.677H14c.37 0 .677-.308.677-.677A.683.683 0 0 0 14 9.97h-2a.683.683 0 0 0-.677.677v4c0 .37.308.677.677.677s.677-.308.677-.677v-1.323H14c.37 0 .677-.308.677-.677 0-.338-.308-.646-.677-.646M12.677 8.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523V8c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8v2.768c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769zM10.03 4.646C10 4.554 10 4.462 10 4.246V2.954l1.723 1.723H10.4c-.185 0-.308 0-.37-.03",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }