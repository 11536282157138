<template>
  <div>
    <DropdownUi
      tag="ul"
      close-if-inner-click
      close-if-outside-click
    >
      <template #anchor="{ toggle }">
        <MenuButton
          :is-gray="!small"
          @click.stop="toggle"
        />

        <StartDocModal
          v-model="isStartOpen"
          :document-id="dataRow['DOC_ID']"
          :events="events"
        />
        <PauseDocModal
          v-model="isPauseOpen"
          :document-id="dataRow['DOC_ID']"
          :events="events"
        />
        <StopDocModal
          v-model="isStopOpen"
          :document-id="dataRow['DOC_ID']"
          :events="events"
        />
        <DeleteDocModal
          v-model="isDeleteOpen"
          :document-id="dataRow['DOC_ID']"
          :index-row="indexRow"
          :table-name="config_g.name"
        />
      </template>

      <DropdownItemUi
        tag="li"
        @click="open"
      >
        <DocumentTwoIcon v-prefix />
        Документ
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showStopMethod"
        tag="li"
        @click.prevent="route_point_solution.pause.method"
      >
        <component
          :is="route_point_solution.pause.icon"
          v-prefix
          class="icon"
        />
        {{ route_point_solution.pause.title }}
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showStartMethod"
        tag="li"
        @click.prevent="route_point_solution.start.method"
      >
        <component
          :is="route_point_solution.start.icon"
          v-prefix
          class="icon"
        />
        {{ route_point_solution.start.title }}
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showRemoveFromControl"
        tag="li"
        @click.prevent="route_point_solution.stop.method"
      >
        <component
          :is="route_point_solution.stop.icon"
          v-prefix
          class="icon"
        />
        {{ route_point_solution.stop.title }}
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showDelButton"
        tag="li"
        @click.prevent="isDeleteOpen = true"
      >
        <DeleteIcon v-prefix />
        Удалить
      </DropdownItemUi>
    </DropdownUi>

    <div class="flags">
      <NoveltyFlag
        v-if="small && dataRow['Новое']"
        :data="dataRow['Новое']"
      />
      <IndicatorFlag
        v-if="small && !dataRow['Новое']"
        :data="dataRow['Индикатор']"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ControlsDocSolutionMixin from '@/mixins/controls-doc-solution-mixin.js';
import DocumentTwoIcon from '@/components/icons/DocumentTwoIcon';
import NoveltyFlag from '@/components/table/flags/NoveltyFlag';
import IndicatorFlag from '@/components/table/flags/IndicatorFlag';
import { mapState } from 'pinia';
import { DOCUMENTS__DELETE } from '@/configs/events';
import StopDocModal from '@/components/modals/Doc/StopDocModal';
import StartDocModal from '@/components/modals/Doc/StartDocModal';
import PauseDocModal from '@/components/modals/Doc/PauseDocModal';
import DeleteDocModal from '@/components/modals/Doc/DeleteDocModal.vue';
import DeleteIcon from '@/components/icons/DeleteIcon';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import MenuButton from '@/components/buttons/MenuButton.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import { useUserStore } from '@/stores/user.ts';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'EyeControls',
  components: {
    DropdownItemUi,
    MenuButton,
    DropdownUi,
    DeleteIcon,
    DeleteDocModal,
    PauseDocModal,
    StartDocModal,
    StopDocModal,
    NoveltyFlag,
    DocumentTwoIcon,
    IndicatorFlag,
  },
  mixins: [ControlsDocSolutionMixin],
  props: ['dataRow', 'indexRow', 'config_g', 'small'],
  data() {
    return {
      isDeleteOpen: false,
    };
  },
  computed: {
    ...mapState(useUserStore, { userName: 'name' }),
    ...mapState(useRolesStore, ['accessToEvent']),
    showStopMethod() {
      return (
        this.dataRow['Контролер'] === this.userName && this.dataRow['Активен'] === 1 && this.dataRow['DS_ID'] !== 6
      );
    },
    showStartMethod() {
      return (
        this.dataRow['Контролер'] === this.userName && this.dataRow['Активен'] !== 1 && this.dataRow['DS_ID'] !== 6
      );
    },
    showRemoveFromControl() {
      return this.dataRow['Контролер'] === this.userName && this.dataRow['DS_ID'] !== 6;
    },
    showDelButton() {
      return this.accessToEvent(DOCUMENTS__DELETE);
    },
  },
  methods: {
    open() {
      window.open('/document/' + this.dataRow['DOC_ID'], '_blank');
    },
  },
});
</script>

<style scoped lang="scss">
.icon {
  width: 16px;
  height: 16px;
}

.flags {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
}
</style>
