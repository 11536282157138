<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.6218 7.33594H58.3346C58.5998 7.33594 58.8542 7.44129 59.0417 7.62883L84.0417 32.6288C84.2293 32.8164 84.3346 33.0707 84.3346 33.3359V52.5026C84.3346 53.0549 83.8869 53.5026 83.3346 53.5026C82.7823 53.5026 82.3346 53.0549 82.3346 52.5026V33.7502L57.9204 9.33594H36.668C33.1511 9.33594 30.5737 9.33672 28.5394 9.50292C26.5169 9.66817 25.1196 9.99195 23.9471 10.5894C21.7832 11.6919 20.0239 13.4512 18.9214 15.615C18.324 16.7875 18.0002 18.1848 17.8349 20.2074C17.6687 22.2416 17.668 24.8191 17.668 28.3359V50.0026C17.668 50.5549 17.2203 51.0026 16.668 51.0026C16.1157 51.0026 15.668 50.5549 15.668 50.0026V28.2897C15.668 24.8285 15.668 22.1697 15.8416 20.0445C16.0169 17.8983 16.3744 16.2085 17.1394 14.7071C18.4337 12.1669 20.4989 10.1016 23.0391 8.80735C24.5405 8.04234 26.2303 7.68492 28.3766 7.50956C30.5017 7.33593 33.1605 7.33593 36.6218 7.33594Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.332 8.4375C58.8843 8.4375 59.332 8.88522 59.332 9.4375V26.6481C59.332 27.8314 59.3328 28.6587 59.3855 29.3033C59.4372 29.9363 59.5339 30.3045 59.6772 30.5857C59.9808 31.1816 60.4652 31.666 61.0611 31.9696C61.3422 32.1129 61.7105 32.2096 62.3435 32.2613C62.9881 32.314 63.8154 32.3147 64.9987 32.3147H82.2093C82.7616 32.3147 83.2093 32.7625 83.2093 33.3147C83.2093 33.867 82.7616 34.3147 82.2093 34.3147H64.9558C63.8254 34.3148 62.9163 34.3148 62.1806 34.2547C61.424 34.1928 60.7632 34.0625 60.1531 33.7516C59.1809 33.2563 58.3905 32.4659 57.8952 31.4937C57.5843 30.8836 57.4539 30.2228 57.3921 29.4662C57.332 28.7305 57.332 27.8214 57.332 26.6911L57.332 9.4375C57.332 8.88522 57.7797 8.4375 58.332 8.4375Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.668 49C17.2203 49 17.668 49.4477 17.668 50V71.6667C17.668 75.1835 17.6687 77.761 17.8349 79.7952C18.0002 81.8178 18.324 83.2151 18.9214 84.3876C20.0239 86.5514 21.7832 88.3107 23.9471 89.4132C25.1196 90.0106 26.5169 90.3344 28.5394 90.4997C30.5737 90.6659 33.1511 90.6667 36.668 90.6667H63.3346C66.8515 90.6667 69.4289 90.6659 71.4632 90.4997C73.4857 90.3344 74.883 90.0106 76.0555 89.4132C78.2194 88.3107 79.9787 86.5514 81.0812 84.3876C81.6786 83.2151 82.0024 81.8178 82.1676 79.7952C82.3339 77.761 82.3346 75.1835 82.3346 71.6667V52.5C82.3346 51.9477 82.7823 51.5 83.3346 51.5C83.8869 51.5 84.3346 51.9477 84.3346 52.5V71.7129C84.3346 75.1742 84.3346 77.833 84.161 79.9581C83.9857 82.1043 83.6282 83.7941 82.8632 85.2955C81.5689 87.8357 79.5037 89.901 76.9635 91.1953C75.4621 91.9603 73.7723 92.3177 71.626 92.493C69.5009 92.6667 66.8421 92.6667 63.3809 92.6667H36.6217C33.1605 92.6667 30.5017 92.6667 28.3766 92.493C26.2303 92.3177 24.5405 91.9603 23.0391 91.1953C20.4989 89.901 18.4337 87.8357 17.1394 85.2955C16.3744 83.7941 16.0169 82.1043 15.8416 79.9581C15.668 77.833 15.668 75.1741 15.668 71.7129V50C15.668 49.4477 16.1157 49 16.668 49Z"
      fill="black"
    />
    <path
      d="M24 64.0703C24 62.9657 24.8954 62.0703 26 62.0703H74C75.1046 62.0703 76 62.9657 76 64.0703V76.0703C76 80.4886 72.4183 84.0703 68 84.0703H32C27.5817 84.0703 24 80.4886 24 76.0703V64.0703Z"
      fill="#C3CADB"
    />
    <path
      d="M73 52.0703H27C25.3431 52.0703 24 53.4135 24 55.0703C24 56.7272 25.3431 58.0703 27 58.0703H73C74.6569 58.0703 76 56.7272 76 55.0703C76 53.4135 74.6569 52.0703 73 52.0703Z"
      fill="#C3CADB"
    />
    <path
      d="M24 17.8481C24 17.4185 24.3482 17.0703 24.7778 17.0703H31V23.2925C31 23.7221 30.6518 24.0703 30.2222 24.0703H24.7778C24.3482 24.0703 24 23.7221 24 23.2925V17.8481Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.569 16.7524C27.409 16.7524 27.2721 16.8712 27.2443 17.0341L27.2379 17.0874H26.5557L26.5951 16.9156C26.6785 16.4267 27.0891 16.0703 27.569 16.0703H29.056C29.5774 16.0703 30 16.5075 30 17.0469C30 17.0945 29.9966 17.142 29.99 17.189L29.307 21.9925L29.2428 22.3179C29.1011 23.0365 28.1749 23.191 27.822 22.5549L27.5398 22.0461L27.1849 22.6755C27.047 22.92 26.794 23.0703 26.5205 23.0703H25.5411L25 21.9973L25.2523 20.4314L26.1576 19.1826C26.3029 18.9823 26.5308 18.8643 26.7729 18.8643H27.5143L28.0915 19.7866V20.7011H27.4322V19.9882L27.1557 19.5464H26.7729C26.7383 19.5464 26.7058 19.5632 26.685 19.5918L25.8762 20.7075L25.6889 21.8698L25.9343 22.3883H26.5205C26.5596 22.3883 26.5957 22.3668 26.6154 22.3319L27.2574 21.1934L27.828 21.1954L28.3938 22.2154C28.4443 22.3063 28.5686 22.2755 28.5889 22.1729L28.6872 21.7661L29.3377 17.0898C29.3377 17.1041 29.3397 17.0756 29.3377 17.0898C29.3377 16.9271 29.2132 16.7524 29.056 16.7524H27.569Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'DocumentEmptyTableIcon',
};
</script>
