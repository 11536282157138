<template>
  <div :class="classWrapper">
    <div
      class="mobile-menu-bg"
      @click="hideMobileMenu"
    />
    <div
      v-touch:swipe="swipeHandler"
      class="mobile-menu-content"
      @click="clickHandler"
    >
      <RouterLink
        class="sidebar-logo _mobile"
        :to="'/'"
        @click.stop="hideMobileMenu"
      >
        <LogoIcon
          class="logo"
          alt="АЛЬФА ДОК"
        />
        <span class="logo-text">
          <span class="title">АЛЬФАДОК</span>
          <ReleaseInfo />
        </span>
      </RouterLink>

      <UpdateButton />

      <RouterLink
        class="sidebar-link _mobile"
        to="/commissions"
        @click.stop="hideMobileMenu"
      >
        <CommissionsIcon />
        <span class="sidebar-link-text">Поручения</span>
        <MenuCounter :counters="commissionsCount" />
      </RouterLink>

      <RouterLink
        v-if="accessToControls"
        class="sidebar-link _mobile"
        to="/controls"
        @click.stop="hideMobileMenu"
      >
        <ControlsIcon />
        <span class="sidebar-link-text">Контроль</span>
        <MenuCounter :counters="controlsCount" />
      </RouterLink>

      <RouterLink
        v-if="accessToDocuments"
        class="sidebar-link _mobile"
        to="/search"
        @click.stop="hideMobileMenu"
      >
        <SearchDocIcon />
        <span class="sidebar-link-text">Поиск документов</span>
      </RouterLink>

      <RouterLink
        v-if="accessToClients"
        class="sidebar-link _mobile"
        to="/clients"
        @click.stop="hideMobileMenu"
      >
        <ContractorIcon />
        <span class="sidebar-link-text">Контрагенты</span>
      </RouterLink>

      <a
        class="sidebar-link sidebar-toggler"
        @click.stop="hideMobileMenu"
      >
        <span class="sidebar-link-text">Скрыть</span>
        <CollapseIcon />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/constants';
import ReleaseInfo from '@/components/common/ReleaseInfo.vue';
import CommissionsIcon from '@/components/icons/CommissionsIcon.vue';
import MenuCounter from '@/components/widgets/MenuCounter.vue';
import ControlsIcon from '@/components/icons/ControlsIcon.vue';
import SearchDocIcon from '@/components/icons/SearchDocIcon.vue';
import ContractorIcon from '@/components/icons/ContractorIcon';
import CollapseIcon from '@/components/icons/CollapseIcon';
import { mapState } from 'pinia';
import { COUNTERPARTIES_REGIONS_SEGMENTS__VIEW, DOCUMENTS__CONTROLS, DOCUMENTS__VIEW } from '@/configs/events';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import LogoIcon from '@/assets/icons/logo.svg';
import Emitter from '@/services/emitter.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useControlsStore } from '@/stores/controls.js';
import { useRolesStore } from '@/stores/roles.js';

export default defineComponent({
  name: 'MobileMenu',
  components: {
    LogoIcon,
    UpdateButton,
    ReleaseInfo,
    CommissionsIcon,
    MenuCounter,
    ControlsIcon,
    SearchDocIcon,
    ContractorIcon,
    CollapseIcon,
  },
  inject: ['app'],
  data: () => ({
    isShown: false,
    clickCount: 0,
    clickDelay: 700,
    clickTimer: null,
  }),
  mounted() {
    Emitter.on('mobile-menu-show', this.showMobileMenu);
  },
  methods: {
    showMobileMenu() {
      this.isShown = true;
    },
    hideMobileMenu() {
      this.isShown = false;
    },
    swipeHandler(direction) {
      switch (direction) {
        case 'left':
          this.hideMobileMenu();
          break;
      }
    },
    clickHandler() {
      this.clickCount++;
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.hideMobileMenu();
        this.clickCount = 0;
      }
    },
  },
  computed: {
    ...mapState(useRolesStore, ['accessToEvent']),
    ...mapState(useCommissionsStore, ['commissionsCount']),
    ...mapState(useControlsStore, ['controlsCount']),
    accessToControls() {
      return this.accessToEvent(DOCUMENTS__CONTROLS);
    },
    accessToDocuments() {
      return this.accessToEvent(DOCUMENTS__VIEW);
    },
    accessToClients() {
      return this.accessToEvent(COUNTERPARTIES_REGIONS_SEGMENTS__VIEW);
    },
    isHeightTiny() {
      return this.app.appHeight <= Constants.resolutionPoints.tiny;
    },
    isHeightBaby() {
      return this.app.appHeight <= Constants.resolutionPoints.baby;
    },
    classWrapper() {
      return {
        'mobile-menu-wrapper': true,
        'mobile-menu-height-tiny': this.isHeightTiny,
        'mobile-menu-height-baby': this.isHeightBaby,
        'shown': this.isShown,
      };
    },
  },
  unmounted() {
    Emitter.off('mobile-menu-show', this.showMobileMenu);
  },
});
</script>

<style scoped lang="scss">
.mobile-menu-wrapper {
  position: relative;

  .update-button {
    position: absolute;
    top: 24px;
    right: 16px;
  }

  &.mobile-menu-height-tiny {
    .update-button {
      position: absolute;
      top: 18px;
    }
  }

  &.mobile-menu-height-baby {
    .update-button {
      position: absolute;
      top: 14px;
    }
  }
}

.sidebar-link {
  position: relative;
}
</style>
