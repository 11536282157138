<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CLIENTS_ADDRESSES_GET } from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import AddButton from '@/components/buttons/AddButton.vue';
import { usePreviewStore } from '@/stores/preview.js';
import { useListsStore } from '@/stores/lists.js';
import { notify } from '@kyvg/vue3-notification';
import useAddressForm from '@/composables/forms/use-address-form.ts';
import ModalForm from '@/components/common/ModalForm.vue';
import AddressApi from '@/services/api/address-api.ts';
import type { TableId } from '@/common/enums/table-id.ts';
import { AddressEndPoint } from '@/common/enums/end-point.ts';

const isOpen = defineModel<boolean>({ required: true });

const { parentId, tableId } = defineProps<{
  tableId: TableId;
  parentId: number;
}>();

const { INITIAL_FORM_DATA, fields } = useAddressForm();

const listsStore = useListsStore() as any;
const previewStore = usePreviewStore();

const formBuilder = useTemplateRef('formBuilder');

const isLoading = ref(false);
const isDirty = ref(false);
const formData = ref(INITIAL_FORM_DATA);

function onShow() {
  isDirty.value = false;
  formData.value = INITIAL_FORM_DATA;
  listsStore[VuexAdapter.getNameAction(AddressEndPoint.GetTypes)]({ where: `PARENT_TABLE_ID = ${tableId}` });
}

async function apply() {
  if (!formBuilder.value!.validate()) {
    return;
  }

  isLoading.value = true;

  try {
    await AddressApi.add({
      tableId,
      parentId,
      formData: formData as any, // TODO: Отказаться при рефакторинге форм
    });
    previewStore.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
    isDirty.value = false;
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При добавлении адреса возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    title="Добавление адреса"
    :confirm="isDirty"
    @show="onShow"
  >
    <template #body>
      <FormBuilder
        ref="formBuilder"
        v-model="formData"
        :fields="fields"
        :readonly="isLoading"
        margins
        @update:model-value="isDirty = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />
      <AddButton
        :loading="isLoading"
        @click="apply"
      />
    </template>
  </ModalForm>
</template>
