<template>
  <PreviewBlock
    title="Вложения"
    :amount="lastRevisionList.length"
    :loading="loading"
    @resize="onResize"
  >
    <template #header-postfix>
      <AddPreviewButton
        v-if="showAdd"
        :show-title="!compact"
        @click="isUploadOpen = true"
      />
      <FileUploadModal
        v-model="isUploadOpen"
        :document-id="doc_id"
      />
    </template>

    <div
      v-if="isReady && lastRevisionList.length"
      class="files-document-preview"
    >
      <div
        v-for="file in filesView"
        :key.prop="file"
        class="item"
      >
        <div class="header">
          <b class="user">{{ file.user }}</b>
          <span class="_muted">{{ file.createdDate }}</span>
        </div>

        <div>
          <div class="file">
            <FileNameButton
              v-if="file.hash"
              :file="file"
              with-sign
              with-size
              @show-preview="$emit('show-preview', file.id)"
            />

            <div
              v-if="file.revision.value > 1"
              class="file-redaction-button"
              @click.prevent="filesPrevRevisionActive = filesPrevRevisionActive === file.id ? null : file.id"
            >
              {{ file.revision.text }}
            </div>

            <TooltipUi
              v-if="file.comment"
              :text="file.comment"
            >
              <NoteIcon class="note-icon _green" />
            </TooltipUi>
          </div>

          <div
            v-if="file.revision.value > 1 && filesPrevRevisionActive === file.id"
            class="prev-files-block"
          >
            <div
              v-for="prevFile in getOldRevisionListByName(file.name)"
              :key="prevFile.id"
              class="prev-file"
            >
              <div class="header">
                <b class="user _muted">{{ prevFile.user }}</b>
                <span class="_muted">{{ prevFile.createdDate }}</span>
              </div>

              <div class="file">
                <FileNameButton
                  v-if="prevFile.hash"
                  :file="prevFile"
                  with-sign
                  with-size
                />

                <div
                  v-if="prevFile.revision.value > 1"
                  class="file-redaction"
                >
                  {{ prevFile.revision.text }}
                </div>

                <TooltipUi
                  v-if="prevFile.comment"
                  :text="prevFile.comment"
                >
                  <NoteIcon class="note-icon" />
                </TooltipUi>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showMoreButton"
        class="uploader-block-footer"
      >
        <ShowMoreButton
          v-if="showMoreButton"
          @click="load"
        />
      </div>
    </div>
    <span
      v-else-if="error"
      class="_muted"
    >
      Ошибка получения данных
    </span>
    <span
      v-else
      class="_muted"
    >
      Список вложений пуст
    </span>
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import { getDate } from '@/common/utils/utils.js';
import ShowMoreButton from '@/components/buttons/ShowMoreButton';
import FileUploadModal from '@/components/modals/File/FileUploadModal.vue';
import { NotifyTypes } from '@/configs/notify-types.js';
import FileNameButton from '@/components/doc/files/FileNameButton.vue';
import { mapState } from 'pinia';
import PreviewBlockMixin from '@/mixins/preview-block-mixin.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddPreviewButton from '@/components/buttons/AddPreviewButton.vue';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import NoteIcon from '@/assets/icons/note.svg';
import TooltipUi from '@/components/ui/TooltipUi.vue';

export default defineComponent({
  name: 'FilesDocumentPreview',
  components: {
    TooltipUi,
    NoteIcon,
    AddPreviewButton,
    PreviewBlock,
    FileNameButton,
    FileUploadModal,
    ShowMoreButton,
  },
  mixins: [PreviewBlockMixin],
  props: {
    doc_id: {
      type: Number,
      required: true,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show-preview'],
  data: () => ({
    isUploadOpen: false,
    filesPrevRevisionActive: null,
    limit: 5,
    views: 0,
    viewsAll: 5,
    filesView: [],
  }),
  mounted() {
    this.getFilesLastRevision();
  },
  methods: {
    getDate,
    getFilesLastRevision() {
      this.lastRevisionList.forEach((file, key) => {
        if (this.views < this.viewsAll && key === this.views) {
          this.views++;
          this.filesView.push(file);
        }
      });
    },
    load() {
      this.viewsAll = this.viewsAll + this.limit;
      this.getFilesLastRevision();
    },
  },
  computed: {
    ...mapState(usePreviewFilesStore, ['error', 'isReady', 'lastRevisionList', 'getOldRevisionListByName']),
    showMoreButton() {
      return this.lastRevisionList.length > this.viewsAll;
    },
  },
  watch: {
    error(error) {
      if (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: 'При получении данных возникла ошибка.',
          data: error,
        });
      }
    },
    isReady(value) {
      if (value) {
        this.filesView = [];
        this.views = 0;
        this.getFilesLastRevision();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 4px;
  display: flex;
}

.user {
  margin-right: 8px;
}

.item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.file {
  display: flex;
  align-items: center;
}

.file-name-button {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.note-icon {
  fill: var(--color-gray-600);

  &._green {
    fill: var(--color-green-800);
  }
}

.prev-file {
  margin-bottom: 4px;
}

.show-more-button {
  width: 100%;
  margin: -12px;
}
</style>
