<template>
  <div class="documents-links">
    <div class="row">
      <span class="label">Номер:</span>
      <RouterLink
        :to="'/document/' + row['Номер']"
        :target="'_blank'"
      >
        {{ row['Номер'] }}
      </RouterLink>
    </div>
    <div
      v-if="row['Проект']"
      class="row"
    >
      <span class="label">Проект:</span>
      <b>{{ row['Проект'] }}</b>
    </div>
    <div
      v-if="row['Содержание']"
      class="row"
    >
      <span class="label">Содержание:</span>
      <b>{{ row['Содержание'] }}</b>
    </div>
    <div
      v-if="row['Контролер']"
      class="row"
    >
      <span class="label">Контролер:</span>
      <b class="_extra">{{ row['Контролер'] }}</b>
    </div>
    <div
      v-if="row['Дата']"
      class="row"
    >
      <span class="label">Дата:</span>
      <Date
        :config="{ format: date_f }"
        :data="row['Дата']"
      />
    </div>
    <div
      v-if="row['Ссылка']"
      class="row"
    >
      <span class="label">Тип связи:</span>
      <span>{{ row['Ссылка'] }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Date from '@/components/table/columns/DateColumn';

export default defineComponent({
  name: 'DocumentsLinks',
  components: { Date },
  props: ['dataTable', 'configs', 'row', 'componentWidth'],
  data: () => ({
    date_f: 'dd.LL.yyyy',
    term_date_f: 'dd.LL.yyyy',
  }),
  mounted() {
    if (this.configs !== undefined) {
      this.date_f = this.configs.info['Дата'];
      this.term_date_f = this.configs.info['Срок'];
    }
  },
});
</script>

<style scoped lang="scss">
.documents-links {
  padding: 16px;

  border: var(--border-gray-100);
  border-radius: 8px;
}

.row {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.label {
  flex: 0 0 110px;
  color: var(--color-gray-500);
}
</style>
