import { TYPE_SELECT, TYPE_STRING, TYPE_TEXT } from '@/configs/form';
import { useListsStore } from '@/stores/lists';
import VuexAdapter from '@/services/vuex-adapter';
import type { AddressFormData } from '@/services/api/address-api.ts';
import { AddressEndPoint } from '@/common/enums/end-point.ts';

export default function useAddressForm() {
  const listsStore = useListsStore() as any;

  const INITIAL_FORM_DATA: Partial<AddressFormData> = {
    comment: '',
    address: '',
  };

  const fields = [
    {
      type: TYPE_SELECT,
      name: 'type',
      input: true,
      label: 'Тип',
      placeholder: 'Введите тип',
      options: () => listsStore[VuexAdapter.getNameSelectGetter(AddressEndPoint.GetTypes)],
      required: true,
    },
    {
      type: TYPE_STRING,
      name: 'address',
      label: 'Адрес',
      placeholder: 'Введите адрес',
      max: 255,
      required: true,
    },
    {
      type: TYPE_TEXT,
      name: 'comment',
      label: 'Примечание',
      placeholder: 'Введите примечание',
      max: 255,
      required: false,
    },
  ];

  return {
    INITIAL_FORM_DATA,
    fields,
  };
}
