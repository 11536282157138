import type { Ref } from 'vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';

export default function useSolutionAutocorrect(
  isLoading: Ref<boolean>,
  isDirty: Ref<boolean>,
  comment: Ref<string>,
  textareaRef: Ref<InstanceType<typeof TextareaUi> | null>,
) {
  function addSolutionAutocorrect(text: string) {
    if (isLoading.value) {
      return;
    }

    comment.value += `${text} `;
    isDirty.value = true;
    textareaRef.value!.focus();
  }

  return { addSolutionAutocorrect };
}
