<script setup lang="ts">
import { computed } from 'vue';

export type BadgeSize = 'm' | 'l';
export type BadgeColor = 'gray' | 'blue' | 'red' | 'yellow';

const {
  value,
  color = 'gray',
  size = 'm',
} = defineProps<{
  value: string | number;
  size?: BadgeSize;
  color?: BadgeColor;
  pulse?: boolean;
}>();

const wide = computed(() => value.toString().length > 2);
</script>

<template>
  <span
    class="badge-ui"
    :class="[`_size-${size}`, `_color-${color}`, { _wide: wide, _pulse: pulse }]"
  >
    {{ value }}
  </span>
</template>

<style scoped lang="scss">
.badge-ui {
  display: block;

  text-align: center;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  border-radius: 100px;

  transition: background-color var(--transition);

  @mixin size($size, $padding, $font-size) {
    height: $size;
    line-height: $size;

    font-size: $font-size;

    &:not(._wide) {
      width: $size;
    }

    &._wide {
      padding: 0 $padding;
    }
  }

  &._size-m {
    @include size(17px, 4px, var(--font-size-s));
  }

  &._size-l {
    @include size(24px, 8px, var(--font-size));
  }

  @mixin color($r, $g, $b) {
    background-color: rgb($r, $g, $b);

    $animation: pulse-#{$r}-#{$g}-#{$b};

    &._pulse {
      animation: $animation 2s infinite;

      @keyframes #{$animation} {
        0% {
          transform: scale(0.9);
          box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.9);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 12px rgba($r, $g, $b, 0);
        }

        100% {
          transform: scale(0.9);
          box-shadow: 0 0 0 0 rgba($r, $g, $b, 0);
        }
      }
    }
  }

  &._color-gray {
    @include color(186, 192, 207); // --color-gray-400
  }

  &._color-blue {
    @include color(46, 144, 251); // --color-blue-600
  }

  &._color-red {
    @include color(255, 15, 29); // --color-red-1000
  }

  &._color-yellow {
    color: var(--color-gray-1000);
    font-weight: var(--font-weight);

    @include color(255, 225, 141); // --color-yellow-400
  }
}
</style>
