import { defineComponent } from 'vue';
import PauseCircleIcon from '@/assets/icons/circle/pause.svg';
import PlayCircleIcon from '@/assets/icons/circle/play.svg';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';

/** @deprecated Используйте composables */
export default defineComponent({
  data() {
    return {
      isPauseOpen: false,
      isStartOpen: false,
      isStopOpen: false,
      events: ['counter-reload-controls', 'table-trigger-refresh-only-state'],
    };
  },
  computed: {
    route_point_solution() {
      return {
        pause: {
          icon: PauseCircleIcon,
          title: 'Поставить на паузу',
          method: () => (this.isPauseOpen = true),
        },
        start: {
          icon: PlayCircleIcon,
          title: 'Запустить документ',
          method: () => (this.isStartOpen = true),
        },
        stop: {
          icon: StopCircleIcon,
          title: 'Остановить',
          color: 'gray',
          mode: 'outline',
          method: () => (this.isStopOpen = true),
        },
      };
    },
  },
});
