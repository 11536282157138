<script setup lang="ts">
import { computed, defineAsyncComponent, ref, useTemplateRef } from 'vue';
import { DOC_FILES_GET } from '@/configs/end-points.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import FileApi from '@/services/api/file-api.js';
import DocumentFile from '@/common/models/file/document-file.ts';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { State } from '@/common/enums/state.ts';
import FileChangesConfirmModal from '@/components/modals/File/FileChangesConfirmModal.vue';
import useAbort from '@/composables/use-abort.js';
import StorageApi from '@/services/api/storage-api.js';
import { usePreviewStore } from '@/stores/preview.js';
import { notify } from '@kyvg/vue3-notification';
import type { Page } from '@alphadoc/alphadoc-annotation';
import ModalForm from '@/components/common/ModalForm.vue';

const AdAnnotation = defineAsyncComponent({
  loader: () => import('@alphadoc/alphadoc-annotation'),
  loadingComponent: LoaderUi,
});

const isOpen = defineModel<boolean>({ required: true });

const { file, pages, state } = defineProps<{
  file: DocumentFile;
  pages: Page[];
  state: State;
  initial: number;
}>();

defineEmits<{
  (e: 'fetch', page: number): void;
  (e: 'retry', page: number): void;
}>();

const { signal } = useAbort();
const previewStore = usePreviewStore();
const annotationRef = useTemplateRef('annotationRef');

const isDirty = ref(false);
const isLoading = ref(false);

const computedState = computed(() => (isLoading.value ? State.Loading : state));
const loaderText = computed(() => (isLoading.value ? 'Отправка данных' : 'Загрузка данных'));

function download() {
  StorageApi.download(file, signal.value);
}

async function onApply(annotations = annotationRef.value!.getAnnotations()) {
  isLoading.value = true;

  try {
    await FileApi.review(file, pages, annotations);
    void previewStore.refreshPartlyPreviewAction(DOC_FILES_GET);
    notify({
      type: NotifyTypes.Success,
      text: 'Рецензия успешно добавлена',
    });
    isDirty.value = false;
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При рецензировании документа возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    width="l"
    height="100%"
    :confirm="isDirty"
    @show="isDirty = false"
  >
    <AdAnnotation
      ref="annotationRef"
      :file="file"
      :pages="pages"
      :initial="initial"
      :state="computedState"
      :loader-text="loaderText"
      @fetch="$emit('fetch', $event)"
      @retry="$emit('retry', $event)"
      @download="download"
      @change="isDirty = true"
      @apply="onApply"
      @close="isOpen = false"
    />

    <template #confirm="{ isConfirmOpen, toggle, discard }">
      <FileChangesConfirmModal
        :file-name="file.name"
        :model-value="isConfirmOpen"
        @update:model-value="toggle"
        @save="
          toggle(false);
          onApply();
        "
        @discard="discard"
        @cancel="toggle(false)"
      />
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.annotation {
  height: 100%;
}
</style>
