import Api from '@/services/api/api.js';
import DocumentFile from '@/common/models/file/document-file.ts';
import { DocumentEndPoint } from '@/common/enums/end-point.ts';
import DocumentOption from '@/common/models/option/document-option.js';
import DocumentCard from '@/common/models/card/document-card.js';

export default class DocumentApi {
  static GET_HINTS_COUNT = 500;

  static async getDocument(id: number, signal: AbortSignal) {
    const response = await Api.post(DocumentEndPoint.GetList, { where: `DOC_ID = ${id}` }, signal);
    return new DocumentCard(response.items[0]);
  }

  static async getProgress(id: number, signal: AbortSignal) {
    const response = await Api.post(DocumentEndPoint.GetProgress, { where: `DOC_ID = ${id}` }, signal);
    return response.items[0]['Выполнение'] || 0;
  }

  static async getOptions(query: string, signal: AbortSignal) {
    const response = await Api.post(DocumentEndPoint.GetOptions, { query }, signal);
    return response.items.map((item: any) => new DocumentOption(item));
  }

  static async getFiles(id: number, signal: AbortSignal, cache: any) {
    const response = await Api.post(DocumentEndPoint.GetFiles, { doc_id: id }, signal, true, cache);
    return response.items.map((item: any) => new DocumentFile(item));
  }

  static async delete(id: number) {
    return await Api.post(DocumentEndPoint.Delete, { doc_id: id });
  }

  static async stop(id: number, comment: string) {
    return await Api.post(DocumentEndPoint.Stop, { doc_id: id, comment });
  }

  static async activate(id: number) {
    return await Api.post(DocumentEndPoint.SetActivation, { doc_id: id, active: 1 });
  }

  static async pause(id: number) {
    return await Api.post(DocumentEndPoint.SetActivation, { doc_id: id, active: 0 });
  }

  static async confirmRoutepoint(routepointId: number, comment: string) {
    return await Api.post(DocumentEndPoint.ConfirmRoutepoint, { rp_id: routepointId, comment });
  }
}
