import { FileEditorScope } from '@/common/enums/file-editor-scope.ts';
import { computed, watch } from 'vue';
import { useFileEditorStore } from '@/stores/file-editor.ts';
import type UpdatedFile from '@/common/models/file/updated-file.ts';

type FilesChangeHandler = (files: UpdatedFile[]) => void;

export default function useFileEditor(onFilesChange: FilesChangeHandler, scope = FileEditorScope.Global) {
  const fileEditorStore = useFileEditorStore();

  const files = computed(() => fileEditorStore.getUpdatedFiles(scope));
  const urlsSum = computed(() => files.value.map((file) => file.url).join('_'));

  watch(urlsSum, () => onFilesChange(files.value), { immediate: true });
}
