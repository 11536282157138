<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import BaseFile from '@/common/models/file/base-file.ts';
import TooltipUi from '@/components/ui/TooltipUi.vue';

defineProps<{
  file: BaseFile;
}>();

const titleRef = useTemplateRef('titleRef');

const disabled = ref(true);

function setDisabled() {
  disabled.value = titleRef.value!.scrollWidth === titleRef.value!.clientWidth;
}
</script>

<template>
  <TooltipUi
    class="file-truncated-name"
    tag="span"
    placement="top"
    :text="file.name"
    :disabled="disabled"
    @mouseenter="setDisabled"
  >
    <span
      ref="titleRef"
      class="title"
    >
      {{ file.title }}
    </span>
    <span class="extension">.{{ file.extension }}</span>
  </TooltipUi>
</template>

<style scoped lang="scss">
.file-truncated-name {
  min-width: 0;
  display: flex;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
