<script setup lang="ts">
import CircleCheckIcon from '@/assets/icons/circle/check.svg';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ModalUi from '@/components/ui/ModalUi.vue';

const isOpen = defineModel<boolean>({ required: true });

defineProps<{
  fileName: string;
}>();

defineEmits(['save', 'discard', 'cancel']);
</script>

<template>
  <ModalUi
    v-model="isOpen"
    title="Внимание"
    width="xs"
    popup
    @hide="$emit('cancel')"
  >
    <template #body>
      <p class="paragraph">
        <strong class="_extra">
          Файл <span class="file-name">«{{ fileName }}»</span> был изменен
        </strong>
      </p>
      <p class="paragraph">Хотите его добавить в документ с новой редакцией?</p>
    </template>

    <template #footer>
      <CancelButton @click="$emit('cancel')" />

      <ButtonUi
        color="black"
        @click="$emit('discard')"
      >
        <CircleCloseIcon v-prefix />
        <span>Hет</span>
      </ButtonUi>

      <ButtonUi @click="$emit('save')">
        <CircleCheckIcon v-prefix />
        <span>Да</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<style scoped lang="scss">
.paragraph {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.file-name {
  word-break: break-word;
}
</style>
