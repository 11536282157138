import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m14.43 4.862-4-4a.68.68 0 0 0-.46-.185H6.461c-.523 0-.985 0-1.354.03C4.738.74 4.4.8 4.062.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.215.676-.246 1.045-.03.37-.03.8-.03 1.354v3.908c0 .37.307.677.676.677s.677-.308.677-.677V4.523c0-.584 0-.954.031-1.261.03-.277.062-.431.123-.554.092-.246.308-.431.554-.554.092-.062.246-.092.554-.123C5.539 2 5.908 2 6.492 2h2.8v2.768c0 .154.031.339.123.523.124.247.339.462.585.585.185.092.37.123.523.123h2.769v2.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323c-.03-.154-.092-.338-.215-.461m-3.784-.216c-.03-.092-.03-.184-.03-.4V2.954l1.723 1.723h-1.324c-.184 0-.307 0-.369-.03M4.308 9.846H2.769a.617.617 0 0 0-.615.616c0 .338.277.615.615.615h.154v3.077h-.77a.617.617 0 0 0-.615-.615.617.617 0 0 0-.615.615c0 .677.554 1.23 1.23 1.23h.77c.677 0 1.23-.553 1.23-1.23v-3.077h.155a.617.617 0 0 0 .615-.615.617.617 0 0 0-.615-.616M8.154 9.846H6.462c-.523 0-.923.4-.923.923v4c0 .339.277.616.615.616a.617.617 0 0 0 .615-.616v-.923h1.385c.677 0 1.23-.554 1.23-1.23v-1.539c0-.677-.553-1.23-1.23-1.23m0 2.77H6.769v-1.539h1.385zM13.846 12h-1.23a.617.617 0 0 0-.616.615c0 .339.277.616.615.616h.616v.923h-1.846v-3.077h1.784a.6.6 0 0 0 .523.308.617.617 0 0 0 .616-.616c0-.523-.4-.923-.923-.923h-2c-.677 0-1.231.554-1.231 1.231v3.077c0 .677.554 1.23 1.23 1.23h2.154c.524 0 .923-.4.923-.922v-1.847a.617.617 0 0 0-.615-.615" }, null, -1)
  ])))
}
export default { render: render }