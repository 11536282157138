<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66666 3.33333C6.66666 2.59695 7.26361 2 7.99999 2C8.73637 2 9.33332 2.59695 9.33332 3.33333C9.33332 4.06971 8.73637 4.66667 7.99999 4.66667C7.26361 4.66667 6.66666 4.06971 6.66666 3.33333ZM6.66666 8C6.66666 7.26362 7.26361 6.66667 7.99999 6.66667C8.73637 6.66667 9.33332 7.26362 9.33332 8C9.33332 8.73638 8.73637 9.33333 7.99999 9.33333C7.26361 9.33333 6.66666 8.73638 6.66666 8ZM6.66666 12.6667C6.66666 11.9303 7.26361 11.3333 7.99999 11.3333C8.73637 11.3333 9.33332 11.9303 9.33332 12.6667C9.33332 13.403 8.73637 14 7.99999 14C7.26361 14 6.66666 13.403 6.66666 12.6667Z"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'MenuTableIcon',
};
</script>
