<template>
  <svg
    class="icon-minus"
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H11"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'MinusIcon',
};
</script>

<style scoped lang="scss">
.icon-minus {
  stroke: var(--color-gray-500);
}
</style>
