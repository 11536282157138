<script setup lang="ts">
import UserIcon from '@/assets/icons/user.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import { useAuthStore } from '@/stores/auth.js';
import { useUserStore } from '@/stores/user.js';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineProps<{
  collapsed?: boolean;
}>();

const userStore = useUserStore();
const { name, initials } = storeToRefs(userStore);
const { logout } = useAuthStore();

const short = computed(() => initials.value.length < 3);
</script>

<template>
  <DropdownUi
    tag="ul"
    placement="bottom-end"
    :offset="4"
    close-if-inner-click
    close-if-outside-click
  >
    <template #anchor="{ toggle }">
      <button
        class="toggle"
        :class="{ _small: collapsed }"
        @click="toggle"
      >
        <span
          class="initials"
          :class="{ _short: short }"
        >
          {{ initials }}
        </span>
      </button>
    </template>

    <DropdownItemUi tag="li">
      <UserIcon v-prefix />
      {{ name }}
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="logout"
    >
      <LogoutIcon v-prefix />
      Выход
    </DropdownItemUi>
  </DropdownUi>
</template>

<style scoped lang="scss">
.toggle {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-gray-600);
  font-weight: var(--font-weight-bold);

  border-radius: 50%;
  background-color: var(--color-gray-200);
  transition: background-color var(--transition-fast);

  &._small {
    transform: scale(0.75);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-gray-300);
    }
  }
}

.initials {
  &._short {
    transform: scale(1.2);
  }
}
</style>
