import UserApi from '@/services/api/user-api.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

type UserState = {
  id: number | null;
  name: string | null;
};

export const useUserStore = defineStore(StoreId.User, {
  state: (): UserState => ({
    id: null,
    name: null,
  }),
  getters: {
    initials(): string {
      if (!this.name) {
        return ''; // TODO: Выбрасывать ошибку
        // throw new Error('Имя пользователя не определено!');
      }

      return this.name
        .split(' ')
        .map((word) => word[0])
        .join('')
        .slice(0, 3)
        .toUpperCase();
    },
  },
  actions: {
    async fetch() {
      this.id = await UserApi.getId();
      this.name = await UserApi.getName(this.id);
    },
  },
  persist: true,
});
