import { defineComponent } from 'vue';
import SystemApi from '@/services/api/system-api.ts';
import { NotifyTypes } from '@/configs/notify-types.js';
import { mapActions } from 'pinia';
import Emitter from '@/services/emitter.js';
import { useCommissionsStore } from '@/stores/commissions.js';

/** @deprecated Используйте @/composables/useMarkCommissions */
export default defineComponent({
  data() {
    return {
      isPending: false,
    };
  },
  methods: {
    ...mapActions(useCommissionsStore, ['getCommissionsCountAction']),
    async markAsRead(row) {
      if (!row['Новое']) {
        return;
      }

      await this.mark(
        () => SystemApi.markRoutePointAsRead(row['RP_ID']),
        'Ошибка при попытке пометить прочитанной точку маршрута.',
      );
    },
    async markAsUnread(row) {
      if (row['Новое']) {
        return;
      }

      await this.mark(() => SystemApi.markAsUnread(row['RP_ID']), 'Ошибка при попытке пометить документ прочитанным.');
    },
    async markAsReadAll() {
      await this.mark(() => SystemApi.markAsReadAll(), 'Ошибка при попытке пометить все документы как прочитанные.');
    },
    async mark(method, errorTitle) {
      if (this.isPending) {
        return;
      }

      this.isPending = true;

      try {
        this.beforeMark?.();
        await method();
        Emitter.emit('table-start-reload-commissions');
        await this.getCommissionsCountAction();
      } catch (error) {
        this.$notify({
          type: NotifyTypes.Error,
          text: errorTitle,
          data: error,
        });
      } finally {
        this.isPending = false;
      }
    },
  },
});
