<template>
  <svg
    width="108"
    height="100"
    viewBox="0 0 108 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.2858 7.32813H49.9987C50.551 7.32813 50.9987 7.77584 50.9987 8.32812C50.9987 8.88041 50.551 9.32812 49.9987 9.32812H28.332C24.8152 9.32812 22.2377 9.3289 20.2035 9.49511C18.1809 9.66036 16.7836 9.98414 15.6111 10.5815C13.4473 11.6841 11.688 13.4434 10.5855 15.6072C9.98805 16.7797 9.66426 18.177 9.49901 20.1996C9.33281 22.2338 9.33203 24.8113 9.33203 28.3281V71.6615C9.33203 75.1783 9.33281 77.7558 9.49901 79.79C9.66426 81.8126 9.98805 83.2099 10.5855 84.3823C11.688 86.5462 13.4473 88.3055 15.6111 89.408C16.7836 90.0054 18.1809 90.3292 20.2035 90.4945C22.2377 90.6607 24.8152 90.6615 28.332 90.6615H71.6654C75.1822 90.6615 77.7597 90.6607 79.7939 90.4945C81.8165 90.3292 83.2138 90.0054 84.3863 89.408C86.5501 88.3055 88.3094 86.5462 89.4119 84.3823C90.0093 83.2099 90.3331 81.8126 90.4984 79.79C90.6646 77.7558 90.6654 75.1783 90.6654 71.6615V49.9948C90.6654 49.4425 91.1131 48.9948 91.6654 48.9948C92.2176 48.9948 92.6654 49.4425 92.6654 49.9948V71.7077C92.6654 75.1689 92.6654 77.8277 92.4917 79.9529C92.3164 82.0991 91.959 83.7889 91.1939 85.2903C89.8997 87.8305 87.8344 89.8958 85.2942 91.19C83.7928 91.9551 82.103 92.3125 79.9568 92.4878C77.8316 92.6615 75.1729 92.6615 71.7116 92.6615H28.2858C24.8245 92.6615 22.1657 92.6615 20.0406 92.4878C17.8944 92.3125 16.2046 91.9551 14.7032 91.19C12.163 89.8958 10.0977 87.8305 8.80344 85.2903C8.03843 83.7889 7.68101 82.0991 7.50565 79.9529C7.33203 77.8277 7.33203 75.1689 7.33203 71.7076V28.2819C7.33203 24.8207 7.33203 22.1618 7.50565 20.0367C7.68101 17.8905 8.03843 16.2007 8.80344 14.6993C10.0977 12.1591 12.163 10.0938 14.7032 8.79954C16.2046 8.03453 17.8944 7.6771 20.0406 7.50175C22.1657 7.32812 24.8246 7.32812 28.2858 7.32813Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.383 72.1292C59.894 71.833 58.08 71.8281 54.1599 71.8281H24.9933C17.799 71.8281 11.7349 76.6782 9.89732 83.2889C9.7494 83.821 9.19814 84.1325 8.66603 83.9846C8.13392 83.8367 7.82247 83.2854 7.97038 82.7533C10.0423 75.2997 16.8775 69.8281 24.9933 69.8281L54.3169 69.8281C58.0408 69.8281 60.0661 69.828 61.7732 70.1676C68.7814 71.5616 74.2598 77.04 75.6538 84.0482C75.9934 85.7553 75.9933 87.7806 75.9933 91.5045V91.6615C75.9933 92.2137 75.5455 92.6615 74.9933 92.6615C74.441 92.6615 73.9933 92.2137 73.9933 91.6615C73.9933 87.7414 73.9884 85.9274 73.6922 84.4384C72.456 78.2236 67.5978 73.3654 61.383 72.1292Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.6667 23.9141C33.0142 23.9141 26 30.9283 26 39.5807C26 48.2332 33.0142 55.2474 41.6667 55.2474C50.3191 55.2474 57.3333 48.2332 57.3333 39.5807C57.3333 30.9283 50.3191 23.9141 41.6667 23.9141ZM24 39.5807C24 29.8237 31.9096 21.9141 41.6667 21.9141C51.4237 21.9141 59.3333 29.8237 59.3333 39.5807C59.3333 49.3378 51.4237 57.2474 41.6667 57.2474C31.9096 57.2474 24 49.3378 24 39.5807Z"
      fill="black"
    />
    <path
      d="M27.1933 74H52.7472C56.1397 74 57.836 74 59.2465 74.2152C65.0391 75.099 69.5672 78.5721 70.7194 83.015C71 84.0969 71.5 85.8979 71.5 88.5H22.2518C12.8355 88.5 11.1591 79.0395 19.9253 75.6014C22.447 74.6123 25.0093 74 27.1933 74Z"
      fill="#C3CADB"
    />
    <path
      d="M84.2234 24.6047L99.3021 9.20519C99.7415 8.75638 99.4236 8 98.7954 8H60.6871C60.0589 8 59.741 8.75638 60.1804 9.20519L75.4615 24.8114C75.5913 24.944 75.664 25.1221 75.664 25.3075V45.4945C75.664 46.0942 76.3623 46.4231 76.8247 46.0412L83.561 40.4763C83.7241 40.3416 83.8185 40.1412 83.8185 39.9297V25.5969C83.8185 25.2259 83.9639 24.8697 84.2234 24.6047Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.6733 9.69944C58.7944 8.80181 59.4304 7.28906 60.6866 7.28906H98.795C100.051 7.28906 100.687 8.80182 99.8083 9.69944L84.7296 25.0989C84.5999 25.2315 84.5272 25.4096 84.5272 25.595V39.9278C84.5272 40.3508 84.3383 40.7518 84.0122 41.0212L77.2759 46.586C76.351 47.35 74.9545 46.6922 74.9545 45.4926V25.3057L59.6733 9.69944ZM98.795 8.70724L60.6866 8.70724L75.9678 24.3135C76.2273 24.5785 76.3726 24.9347 76.3726 25.3057V45.4926L83.109 39.9278V25.595C83.109 25.0386 83.327 24.5043 83.7163 24.1067L98.795 8.70724Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M84.7914 13.2634C85.1887 12.8797 85.2005 12.2459 84.8179 11.8477C84.4352 11.4494 83.8029 11.4376 83.4057 11.8213L80.0093 15.1011L76.7262 11.7014C76.3425 11.3041 75.7102 11.2939 75.3139 11.6785C74.9175 12.0631 74.9073 12.697 75.2909 13.0943L78.5706 16.4904L75.6822 19.2797C75.285 19.6633 75.2731 20.2972 75.6558 20.6954C76.0384 21.0936 76.6707 21.1054 77.068 20.7218L79.9599 17.9291L82.7493 20.8177C83.133 21.215 83.7653 21.2252 84.1616 20.8406C84.558 20.456 84.5682 19.8221 84.1846 19.4248L81.3986 16.5398L84.7914 13.2634Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'ClientsByFilterTableIcon',
};
</script>
