import { TYPE_STRING, TYPE_TEXT } from '@/configs/form';
import type { BankDetailsFormData } from '@/services/api/bank-details-api.ts';

export default function useBankDetailsForm() {
  const INITIAL_FORM_DATA: Partial<BankDetailsFormData> = {
    bankName: '',
    bankIdentificationCode: '',
    correspondentAccount: '',
    currentAccount: '',
    comment: '',
  };

  const fields = [
    {
      type: TYPE_STRING,
      name: 'bankName',
      label: 'Банк',
      placeholder: 'Укажите банк',
      required: true,
    },
    {
      type: TYPE_STRING,
      name: 'bankIdentificationCode',
      label: 'БИК',
      placeholder: 'Укажите БИК',
      required: true,
      max: 9,
    },
    {
      type: TYPE_STRING,
      name: 'correspondentAccount',
      label: 'КС',
      placeholder: 'Укажите КС',
      required: true,
    },
    {
      type: TYPE_STRING,
      name: 'currentAccount',
      label: 'РС',
      placeholder: 'Укажите РС',
      required: true,
    },
    {
      type: TYPE_TEXT,
      name: 'comment',
      label: 'Примечание',
      placeholder: 'Введите примечание',
      max: 2048,
      required: false,
    },
  ];

  return {
    INITIAL_FORM_DATA,
    fields,
  };
}
