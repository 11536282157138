<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import PreferencesList from '@/components/table/preferencesTable/PreferencesList.vue';
import MenuTableSettingArrowCycleIcon from '@/components/icons/MenuTableSettingArrowCycleIcon.vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import CheckboxForm from '@/components/form/CheckboxForm.vue';
import ComboboxUi from '@/components/ui/ComboboxUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ApplyButton from '@/components/buttons/ApplyButton.vue';
import Emitter from '@/services/emitter.js';
import { useTableStore } from '@/stores/table.js';
import type Option from '@/common/models/option/option';
import ModalForm from '@/components/common/ModalForm.vue';

const isOpen = defineModel<boolean>({ required: true });

const { configs } = defineProps<{
  configs: any;
}>();

const tableStore = useTableStore() as any;

const isDirty = ref(false);
const fix = ref(null);
const fixOptions = ref<Option[]>([]);
const checked = ref(false);

const tableFixCols = computed(() => tableStore[VuexAdapter.fixColsTableNameGetter(configs.name)]);

onMounted(() => {
  for (let i = 2; i <= 7; i++) {
    fixOptions.value[i - 1] = {
      label: 'Колонка: 1-' + i,
      code: i - 1,
      disabled: false,
    };
  }

  fix.value = tableFixCols.value;
});

function apply() {
  setFixCols();
  Emitter.emit('preferences-table-modal-apply-table-' + configs.name);
  isDirty.value = false;
  isOpen.value = false;
}

function setDefault() {
  fix.value = null;
  setFixCols();
  isDirty.value = false;
  isOpen.value = false;
}

function setFixCols() {
  tableSetFixCols(fix.value);
}

function tableSetFixCols(data: any) {
  tableStore[VuexAdapter.fixColsTableNameMutation(configs.name)](data);
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    title="Настройки таблицы"
    :confirm="isDirty"
    @show="isDirty = false"
  >
    <template #body>
      <ComboboxUi
        v-model="fix"
        class="item"
        label="Фиксация столбцов"
        :options="fixOptions"
        placeholder="Нет фиксированных"
        @update:model-value="isDirty = true"
      />

      <CheckboxForm
        v-model="checked"
        label="Выбрать все / снять выделение"
        class="item preferences-modal-toggle-checked-all"
        @update:model-value="isDirty = true"
      />

      <PreferencesList
        class="item"
        :configs="configs"
        @change="isDirty = true"
      />
    </template>

    <template #footer>
      <ButtonUi
        color="white"
        @click="setDefault"
      >
        <MenuTableSettingArrowCycleIcon v-prefix />
        <span>По-умолчанию</span>
      </ButtonUi>

      <ApplyButton @click="apply" />
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
