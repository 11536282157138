<script setup lang="ts">
import { computed, watch } from 'vue';
import hotkeys from 'hotkeys-js';

export type ModalSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'max';

const isOpen = defineModel<boolean>({ required: true });

const { width, popup, height } = defineProps<{
  title?: string;
  width?: ModalSize;
  height?: string;
  minHeight?: string;
  popup?: boolean;
  priority?: boolean;
}>();

const emit = defineEmits<{
  (e: 'show'): void;
  (e: 'hide'): void;
}>();

let scope = 'all';

const widthComputed = computed(() => width || (popup ? 'xxs' : 's'));
const contentClass = computed(() => [`_width-${widthComputed.value}`, { '_with-height': height }]);

watch(isOpen, (isOpen) => {
  if (isOpen) {
    onShow();
    emit('show');
  } else {
    onHide();
    emit('hide');
  }
});

function onShow() {
  scope = hotkeys.getScope();
  hotkeys.setScope('modal');
  hotkeys('escape', 'modal', close);
}

function onHide() {
  hotkeys.unbind('escape', 'modal', close);
  hotkeys.setScope(scope);
}

function close() {
  isOpen.value = false;
}
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isOpen"
        class="modal-ui"
        :class="{ _priority: priority }"
        @mouseup.self="close"
      >
        <div
          class="content"
          :class="contentClass"
          :style="{ height, minHeight }"
        >
          <slot>
            <div
              class="sheet"
              :class="{ _centered: popup }"
            >
              <header
                v-if="$slots.header || title"
                class="header"
              >
                <slot name="header">
                  <h2 class="title">
                    {{ title }}
                  </h2>
                </slot>
              </header>

              <div
                v-if="$slots.body"
                class="body"
                :class="{ _popup: popup }"
              >
                <slot name="body" />
              </div>

              <footer
                v-if="$slots.footer"
                class="footer"
              >
                <slot name="footer" />
              </footer>
            </div>
          </slot>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.modal-ui {
  position: fixed;
  z-index: var(--z-index-modal);
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 24px;

  overflow-y: auto;

  background-color: var(--color-backdrop);
  backdrop-filter: blur(5px);

  @include respond-down(xs) {
    padding: 8px;
  }

  &._priority {
    z-index: var(--z-index-priority-modal);
  }
}

.content {
  margin: 0 auto;

  transition:
    height var(--transition),
    max-width var(--transition);
  transition-delay: var(--duration);

  &._with-height {
    .sheet,
    .body {
      height: 100%;
    }
  }

  &._width-xxs {
    max-width: 300px;
  }

  &._width-xs {
    max-width: 400px;
  }

  &._width-s {
    max-width: 500px;
  }

  &._width-m {
    max-width: 720px;
  }

  &._width-l {
    max-width: 900px;
  }

  &._width-xl {
    max-width: 1140px;
  }
}

.sheet {
  position: relative;

  border-radius: 8px;
  background-color: white;

  &._centered {
    .title,
    .body {
      text-align: center;
    }

    .footer {
      justify-content: center;
    }
  }
}

.header {
  padding: 16px;
}

.body {
  padding: 0 16px;

  &._popup {
    padding: 16px;
    color: var(--color-gray-600);
    word-break: break-word;
  }

  &:first-child {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 16px;
  }
}

.footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition);

    .content {
      transition: transform var(--transition);
    }
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;

    .content {
      transform: translateY(-32px);
    }
  }
}
</style>
