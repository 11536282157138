import Api from '@/services/api/api.js';
import { delay } from '@/common/utils/promise.js';
import { FileEndPoint } from '@/common/enums/end-point.ts';
import ApiFile from '@/common/models/file/api-file.ts';
import axios from 'axios';
import UpdatedFile from '@/common/models/file/updated-file.ts';

export default class FileApi {
  static FILE_ATTRIBUTES = 'T';
  static FILE_GET_META_DELAY = 2000;
  static FILE_GET_META_MAX_ATTEMPTS = 15;

  static async getUserFile(id, file, signal) {
    const response = await Api.post(FileEndPoint.GetUserFiles, { where: `f_id = ${id}` }, signal);

    return new ApiFile(response.items[0], file);
  }

  static async upload({ tableId, parentId, file, comment }, signal) {
    const { file_edition } = await Api.post(
      FileEndPoint.GetEdition,
      {
        parent_table_id: tableId,
        parent_id: parentId,
        name: file.name,
      },
      signal,
    );

    if (file instanceof UpdatedFile) {
      await file.upload();
    }

    const { file_id } = await Api.post(
      FileEndPoint.Add,
      {
        parent_table_id: tableId,
        parent_id: parentId,
        file_name: file.name,
        file_size: file.size,
        file_content: file.base64,
        file_edition,
        comment: comment || file.comment || undefined,
        attributes: FileApi.FILE_ATTRIBUTES,
      },
      signal,
    );

    return file_id;
  }

  static async uploadList({ tableId, parentId, files, comment }, signal) {
    return await Promise.allSettled(files.map((file) => FileApi.upload({ tableId, parentId, file, comment }, signal)));
  }

  static async review(file, pages, annotations) {
    const edits = Object.entries(annotations).map(([page, svg]) => ({
      page_url: pages[page - 1].url,
      page_edits_svg: svg,
    }));

    return await Api.post(FileEndPoint.AddEdits, {
      parent_table_id: file.tableId,
      parent_id: file.parentId,
      file_edits: edits,
      file_name: file.name,
      comment: file.comment,
      attributes: FileApi.FILE_ATTRIBUTES,
    });
  }

  static async getPagesCount(id, hash, signal, attempt = 1) {
    try {
      const { count_pages } = await Api.post(FileEndPoint.GetMeta, { file_hash: hash }, signal);

      return count_pages;
    } catch (error) {
      if (axios.isCancel(error) || error.error_code !== 1001 || attempt >= FileApi.FILE_GET_META_MAX_ATTEMPTS) {
        throw error;
      }

      await delay(FileApi.FILE_GET_META_DELAY);
      return await FileApi.getPagesCount(id, hash, signal, attempt + 1);
    }
  }

  static async addView(id, signal) {
    try {
      return await Api.post(FileEndPoint.AddView, { file_ids: [id] }, signal);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.warn('Ошибка проигнорирована', error);
      }
    }
  }

  static async move(fileId, documentId, signal) {
    return await Api.post(FileEndPoint.Move, { file_id: fileId, doc_id: documentId }, signal);
  }

  static async getEditConfig(id, timestamp, events) {
    const response = await Api.post(FileEndPoint.GetEditConfig, {
      file_id: id,
      file_prev_key: timestamp,
    });

    return {
      ...response,
      config: {
        ...response.config,
        events,
      },
    };
  }
}
