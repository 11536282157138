import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8.677 4.277v.492c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769v2.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523v3.908c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8zm1.354.37C10 4.553 10 4.461 10 4.246V2.953l1.723 1.723H10.4c-.185 0-.308 0-.37-.03M7.938 10.154H6.615a.683.683 0 0 0-.677.677v4c0 .369.308.677.677.677h1.323c1.108 0 2-.893 2-2v-1.323c0-1.139-.923-2.031-2-2.031m.647 3.323c0 .37-.308.677-.677.677H7.23v-2.677h.677c.369 0 .677.308.677.677zM4 10.154H2.46c-.676 0-1.23.554-1.23 1.23v2.924c0 .677.554 1.23 1.23 1.23H4c.677 0 1.23-.553 1.23-1.23v-2.923c0-.677-.553-1.231-1.23-1.231m0 4.154H2.46v-2.923H4zM13.846 10.154H10.77a.617.617 0 0 0-.615.615c0 .339.277.616.615.616h.923v3.538c0 .338.277.615.616.615a.617.617 0 0 0 .615-.615v-3.538h.923a.617.617 0 0 0 .615-.616.617.617 0 0 0-.615-.615" }, null, -1)
  ])))
}
export default { render: render }