import { delay } from '@/common/utils/promise.js';
import axios, { CanceledError } from 'axios';
import { saveFile } from '@/common/utils/save-file.js';
import FileApi from '@/services/api/file-api.js';

export default class StorageApi {
  static ATTEMPTS_COUNT = 15;
  static RELOAD_DELAY = 2000;

  static async load({ url, onlyHeaders = false }, signal, _attempt = 1) {
    try {
      await axios[onlyHeaders ? 'head' : 'get'](url, { signal });
    } catch (error) {
      if (error instanceof CanceledError || _attempt >= StorageApi.ATTEMPTS_COUNT) {
        throw error;
      }

      await delay(StorageApi.RELOAD_DELAY);
      await StorageApi.load({ url, onlyHeaders }, signal, _attempt + 1);
    }
  }

  static async download({ url, downloadName, fileId }, signal, _attempt = 1) {
    try {
      await axios.get(url, { signal });
      saveFile(url, downloadName);
    } catch (error) {
      if (error instanceof CanceledError || _attempt >= StorageApi.ATTEMPTS_COUNT) {
        throw error;
      }

      await FileApi.addView(fileId);
      await delay(StorageApi.RELOAD_DELAY);
      await StorageApi.download({ url, fileId, downloadName }, signal, _attempt + 1);
    }
  }
}
