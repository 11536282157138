import { defineStore } from 'pinia';
import StaticApi from '@/services/api/static-api.js';
import StoreId from '@/common/enums/store-id.js';
import type { Version } from '@/common/types/version.ts';

type VersionState = {
  showModal: boolean;
  current: Version | null;
  next: Version | null;
};

const WATCH_VERSION_DELAY = 30_000;

function isDifferent(a: Version | null, b: Version | null) {
  return a?.version !== b?.version || a?.date !== b?.date || a?.hash !== b?.hash;
}

export const useVersionStore = defineStore(StoreId.Version, {
  state: (): VersionState => ({
    showModal: false,
    current: null,
    next: null,
  }),
  actions: {
    async watch() {
      this.current = await StaticApi.getVersion();

      setInterval(async () => {
        const response = await StaticApi.getVersion();

        if (isDifferent(response, this.current)) {
          if (isDifferent(response, this.next)) {
            this.setShowModal(true);
          }

          this.next = response;
        }
      }, WATCH_VERSION_DELAY);
    },
    setShowModal(showModal: boolean) {
      this.showModal = showModal;
    },
  },
});
