<script setup lang="ts">
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ChevronRightIcon from '@/assets/icons/chevron-right.svg';
import { useScroll } from '@vueuse/core';
import { computed } from 'vue';

const SHOW_DISTANCE = 100;

const props = defineProps<{
  element: HTMLElement | null;
}>();

const { y } = useScroll(() => props.element, { behavior: 'smooth' });

const showButton = computed(() => y.value > SHOW_DISTANCE);
</script>

<template>
  <Transition name="opacity-fast">
    <ButtonUi
      v-if="element && showButton"
      class="scroll-to-start-button"
      size="l"
      shape="round"
      shadow
      @click="y = 0"
    >
      <ChevronRightIcon class="icon" />
    </ButtonUi>
  </Transition>
</template>

<style scoped lang="scss">
.button-ui {
  @include respond-down(m) {
    transform: scale(0.8);
  }
}

.icon {
  transform: rotate(-90deg);
}
</style>
