<template>
  <div class="sign--table--row">
    <div class="sign--table--col--actions">
      <label
        v-if="file.base64"
        class="checkbox-container-custom"
        :for="checkboxId"
      >
        <input
          :id="checkboxId"
          v-model="file.active"
          type="checkbox"
        />
        <span class="checkmark" />
      </label>

      <SpinnerUi
        v-else
        size="s"
      />
    </div>

    <FileNameButton
      class="sign--table--col--description"
      :file="documentFile"
    />

    <div class="sign--table--col--redaction">
      {{ file['Редакция'] }}
    </div>

    <div
      v-if="filesForSignHasRemarkGetter"
      class="sign--table--col--remark"
      :title="file['Примечание']"
    >
      {{ file['Примечание'] }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import FileNameButton from '@/components/doc/files/FileNameButton.vue';
import DocumentFile from '@/common/models/file/document-file.ts';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import { useSignStore } from '@/stores/sign.js';

export default defineComponent({
  name: 'RowTableSign',
  components: {
    SpinnerUi,
    FileNameButton,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useSignStore, ['filesForSignHasRemarkGetter']),
    checkboxId() {
      return 'select-sign-file-' + this.file['F_ID'];
    },
    documentFile() {
      return new DocumentFile(this.file);
    },
  },
});
</script>

<style scoped lang="scss">
.spinner-ui {
  margin-left: 8px;
}
</style>
