<template>
  <ul>
    <DropdownItemUi
      tag="li"
      @click="changeCondition"
    >
      <FilterIcon v-prefix />
      <span>
        Изменить условие на <b>"{{ tableWhereConditionMirrorFormatted }}"</b>
      </span>
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="$emit('show-preferences')"
    >
      <GearSolidIcon v-prefix />
      <span>Настройка таблицы</span>
    </DropdownItemUi>

    <DropdownItemUi
      tag="li"
      @click="refreshTable"
    >
      <SyncIcon v-prefix />
      <span>Перезагрузить таблицу</span>
    </DropdownItemUi>
  </ul>
</template>

<script>
import { defineComponent } from 'vue';
import VuexAdapter from '@/services/vuex-adapter.js';
import { TABLE_CONDITION_AND, TABLE_CONDITION_OR } from '@/configs/tables/table';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import Emitter from '@/services/emitter.js';
import { useTableStore } from '@/stores/table.js';
import FilterIcon from '@/assets/icons/filter.svg';
import GearSolidIcon from '@/assets/icons/gear-solid.svg';
import SyncIcon from '@/assets/icons/sync.svg';

export default defineComponent({
  name: 'EyeFilter',
  components: {
    SyncIcon,
    GearSolidIcon,
    FilterIcon,
    DropdownItemUi,
  },
  props: ['data', 'col', 'key_column'],
  emits: ['show-preferences'],
  data() {
    return {
      tableStore: useTableStore(),
    };
  },
  computed: {
    tableWhereCondition() {
      return this.tableStore[VuexAdapter.conditionTableNameGetter(this.data.name)];
    },
    tableWhereConditionMirrorFormatted() {
      return this.tableWhereCondition === TABLE_CONDITION_AND ? 'ИЛИ' : 'И';
    },
  },
  methods: {
    tableSetCondition(data) {
      this.tableStore[VuexAdapter.conditionTableNameAction(this.data.name)](data);
    },
    tableDeleteALLFiltersAndOrderBy() {
      this.tableStore[VuexAdapter.delFilterAndOrderByTableNameAction(this.data.name)]();
    },
    refreshTable() {
      Emitter.emit('table-set-refresh-total-' + this.data.name);
      this.tableDeleteALLFiltersAndOrderBy();
    },
    changeCondition() {
      const condition = this.tableWhereCondition === TABLE_CONDITION_AND ? TABLE_CONDITION_OR : TABLE_CONDITION_AND;
      this.tableSetCondition(condition);
      Emitter.emit('table-start-reload-' + this.data.name);
    },
  },
});
</script>
