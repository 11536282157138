<script setup lang="ts">
import { nextTick, ref, useTemplateRef } from 'vue';
import FileChangesConfirmModal from '@/components/modals/File/FileChangesConfirmModal.vue';
import FileUploadModal from '@/components/modals/File/FileUploadModal.vue';
import useFileEditor from '@/composables/use-file-editor.ts';
import TransitionDuration from '@/common/enums/transition-duration.js';
import { useFileEditorStore } from '@/stores/file-editor.ts';
import type UpdatedFile from '@/common/models/file/updated-file.ts';

const fileEditorStore = useFileEditorStore();

const uploadModalRef = useTemplateRef('uploadModalRef');

const isUploadOpen = ref(false);
const isConfirmOpen = ref(false);
const file = ref<UpdatedFile>();

useFileEditor(onFilesChange);

function switchToUpload(file: UpdatedFile) {
  isConfirmOpen.value = false;
  isUploadOpen.value = true;

  nextTick(() => uploadModalRef.value!.addFiles(file));
}

function discard(id: string) {
  isConfirmOpen.value = false;
  stopFileWatching(id);
}

function onFilesChange(files: UpdatedFile[]) {
  const isRunning = isConfirmOpen.value || isUploadOpen.value;

  if (files.length) {
    file.value = files[0];

    if (!isRunning) {
      nextTick(() => (isConfirmOpen.value = true));
    }
  } else {
    if (isRunning) {
      isConfirmOpen.value = false;
    }
  }
}

function stopFileWatching(id: string) {
  setTimeout(() => fileEditorStore.clearFileUpdatedUrl(id), TransitionDuration.Normal);
}
</script>

<template>
  <template v-if="file">
    <FileChangesConfirmModal
      v-model="isConfirmOpen"
      :file-name="file.name"
      @save="switchToUpload(file)"
      @discard="discard(file.id)"
      @cancel="discard(file.id)"
    />
    <FileUploadModal
      ref="uploadModalRef"
      v-model="isUploadOpen"
      :document-id="file.documentId"
      @hide="stopFileWatching(file.id)"
    />
  </template>
</template>
