import Api from '@/services/api/api.js';
import {
  DOC_ADD,
  DOC_CONTROLLER_SET,
  DOC_LINK_ADD,
  DOC_MARK_VIEW,
  DOC_ROUTE_ADD,
  DOC_ROUTE_POINT_ACCEPT,
  DOC_ROUTE_POINT_ADD,
  DOC_ROUTE_POINT_DECLINE,
  DOC_ROUTE_POINT_MARK_FINISH,
  DOC_ROUTE_POINT_MARK_VIEW,
  DOC_STARTDATE_SET,
  ROUTES_RESPONSIBLES_UNSPECIFIED,
  ROUTES_STANDARD,
  SYSTEM_GET_DEADLINE,
  SYSTEM_USER_ID,
} from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import { defineStore } from 'pinia';
import StoreId from '@/common/enums/store-id.js';

/** @deprecated Используйте сервисы */
export const useActionsStore = defineStore(StoreId.Actions, {
  actions: [
    DOC_ROUTE_ADD,
    DOC_ROUTE_POINT_ADD,
    DOC_ADD,
    DOC_ROUTE_POINT_MARK_VIEW,
    DOC_ROUTE_POINT_ACCEPT,
    DOC_ROUTE_POINT_MARK_FINISH,
    DOC_ROUTE_POINT_DECLINE,
    DOC_MARK_VIEW,
    ROUTES_STANDARD,
    DOC_LINK_ADD,
    SYSTEM_GET_DEADLINE,
    DOC_STARTDATE_SET,
    DOC_CONTROLLER_SET,
    SYSTEM_USER_ID,
    ROUTES_RESPONSIBLES_UNSPECIFIED,
  ].reduce((acc, endPoint) => {
    const actionName = VuexAdapter.getNameAction(endPoint);

    return {
      ...acc,
      [actionName]: (request) => Api.post(endPoint, request, undefined, false),
    };
  }, {}),
});
