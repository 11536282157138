<script setup lang="ts">
import DeleteButton from '@/components/buttons/DeleteButton.vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import { CLIENTS_ADDRESSES_GET } from '@/configs/end-points.js';
import AddressApi from '@/services/api/address-api.ts';
import { notify } from '@kyvg/vue3-notification';
import { NotifyTypes } from '@/configs/notify-types';
import ModalUi from '@/components/ui/ModalUi.vue';
import { ref } from 'vue';
import { usePreviewStore } from '@/stores/preview';

const isOpen = defineModel<boolean>({ required: true });

const { addressId } = defineProps<{
  addressId: number;
  address: string;
}>();

const previewStore = usePreviewStore();

const isLoading = ref(false);

async function deleteAddress() {
  isLoading.value = true;

  try {
    await AddressApi.delete(addressId);
    previewStore.refreshPartlyPreviewAction(CLIENTS_ADDRESSES_GET);
    isOpen.value = false;
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При удалении адреса возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ModalUi
    v-model="isOpen"
    title="Удаление адреса"
    popup
  >
    <template #body>
      Вы уверены что хотите удалить адрес "{{ address }}". Данная операция необратима! Продолжить?
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />
      <DeleteButton
        :loading="isLoading"
        @click="deleteAddress"
      />
    </template>
  </ModalUi>
</template>
