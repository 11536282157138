import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.677 8.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523v3.908c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8v2.768c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769zM10.03 4.646C10 4.554 10 4.462 10 4.246V2.954l1.723 1.723H10.4c-.185 0-.308 0-.37-.03M4.154 9.846H2.462c-.523 0-.923.4-.923.923v4c0 .339.276.616.615.616a.617.617 0 0 0 .615-.616v-.923h1.385c.677 0 1.23-.554 1.23-1.23v-1.539c0-.677-.553-1.23-1.23-1.23m0 2.77H2.769v-1.539h1.385zM14.154 12h-1.23a.617.617 0 0 0-.616.615c0 .339.277.616.615.616h.616v.923h-1.847v-3.077h1.785a.6.6 0 0 0 .523.308.617.617 0 0 0 .616-.616c0-.523-.4-.923-.924-.923h-2c-.677 0-1.23.554-1.23 1.231v3.077c0 .677.553 1.23 1.23 1.23h2.154c.523 0 .923-.4.923-.922v-1.847a.617.617 0 0 0-.615-.615M9.231 9.846a.617.617 0 0 0-.615.616v2l-1.323-2.308a.56.56 0 0 0-.677-.277.59.59 0 0 0-.462.585v4.307c0 .339.277.616.615.616a.617.617 0 0 0 .616-.616v-2l1.323 2.308c.123.185.308.308.523.308.062 0 .092 0 .154-.031a.59.59 0 0 0 .461-.585v-4.307a.617.617 0 0 0-.615-.616" }, null, -1)
  ])))
}
export default { render: render }