<script setup lang="ts">
import { computed, onMounted } from 'vue';
import ReloadIcon from '@/assets/icons/reload.svg';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import LogoIcon from '@/assets/icons/logo.svg';
import { useVersionStore } from '@/stores/version.ts';
import { useCommonStore } from '@/stores/common.js';
import ModalUi from '@/components/ui/ModalUi.vue';

const commonStore = useCommonStore();
const versionStore = useVersionStore();

const isOpen = computed({
  get: () => versionStore.showModal,
  set: (isOpen) => versionStore.setShowModal(isOpen),
});

const current = computed(() => versionStore.current!.version);
const next = computed(() => versionStore.next!.version);

onMounted(() => versionStore.watch());

function update() {
  commonStore.cleanAllStoresAction(false);
  window.location.reload();
}
</script>

<template>
  <ModalUi
    v-model="isOpen"
    priority
    popup
  >
    <template #header>
      <div class="header">
        <LogoIcon class="icon" />
        <h2>Обновление системы</h2>
      </div>
    </template>

    <template #body>
      <p class="paragraph">Доступно обновление<br />с версии {{ current }} до версии {{ next }}</p>
      <p class="paragraph">Для использования новых функций необходимо перезагрузить приложение</p>
    </template>

    <template #footer>
      <CloseButton @click="isOpen = false" />

      <ButtonUi @click="update">
        <ReloadIcon v-prefix />
        <span>Обновить</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 8px;

  width: 80px;
  height: auto;
}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
