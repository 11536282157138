<script setup>
import { computed } from 'vue';
import CheckIcon from '@/assets/icons/check.svg';
import { only } from '@/common/utils/props-validators';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
    validator: only('div', 'li'),
  },
  type: {
    type: String,
    default: 'context',
    validator: only('list', 'context'),
  },
  checked: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const classList = computed(() => [
  {
    _rounded: props.rounded,
    _checked: props.checked,
    _active: props.active,
    _disabled: props.disabled,
  },
  `_type-${props.type}`,
]);

function onClick(event) {
  if (!props.disabled) {
    emit('click', event);
  }
}
</script>

<template>
  <component
    :is="tag"
    class="dropdown-item-ui"
    :class="classList"
    @click="onClick"
  >
    <slot />
    <CheckIcon
      v-if="type === 'list' && checked"
      v-postfix="'s'"
    />
  </component>
</template>

<style scoped lang="scss">
.dropdown-item-ui {
  display: flex;
  align-items: center;

  cursor: pointer;
  transition:
    color var(--transition-fast),
    background-color var(--transition-fast);

  :deep(svg) {
    width: 16px;
    height: 16px;
  }

  &:not(&._disabled) {
    &._active {
      background-color: var(--color-gray-100);
    }

    &._type-context {
      @media (hover: hover) {
        &:hover {
          background-color: var(--color-gray-100);
        }
      }
    }
  }

  &._checked {
    background-color: var(--color-yellow-200);

    &:not(&._disabled) {
      &._active {
        background-color: var(--color-yellow-400);
      }

      &._type-context {
        @media (hover: hover) {
          &:hover {
            background-color: var(--color-yellow-400);
          }
        }
      }
    }
  }

  &._type-context {
    height: 32px;
    padding: 0 8px;

    color: var(--color-gray-500);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;

    :deep(svg) {
      fill: var(--color-gray-500);
      transition: fill var(--transition-fast);
    }

    &:not(&._disabled) {
      &._active {
        color: var(--color-gray-600);

        :deep(svg) {
          fill: var(--color-gray-600);
        }
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-gray-600);

          :deep(svg) {
            fill: var(--color-gray-600);
          }
        }
      }
    }
  }

  &._type-list {
    padding: 10px 12px;
    justify-content: space-between;
    color: var(--color-gray-1000);

    :deep(svg) {
      fill: var(--color-gray-1000);
    }
  }

  &._rounded {
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &._disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
</style>
