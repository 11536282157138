<template>
  <FieldUi
    :id="id"
    class="search-ui"
    :label="label"
    :required="required"
    :error="errorMessage"
    :hint="hint"
  >
    <DropdownUi
      ref="dropdown"
      :offset="4"
      shrink
      full-width
      close-if-inner-click
      close-if-outside-click
    >
      <template #anchor>
        <InputUi
          v-if="field === 'input'"
          :id="id"
          ref="control"
          :placeholder="placeholder"
          :color="color"
          :size="size"
          :disabled="disabled"
          :error="!!error"
          :max-length="maxLength"
          :no-clear="noClear"
          autocomplete="off"
          :model-value="modelValue"
          @update:model-value="onInput($event)"
          @focus="onFocus"
          @blur="onBlur"
          @clear="onClear"
          @keydown="onKeydown"
        />

        <TextareaUi
          v-else
          :id="id"
          ref="control"
          :placeholder="placeholder"
          :disabled="disabled"
          :error="!!error"
          :max-length="maxLength"
          :rows="rows"
          autocomplete="off"
          :no-resize="noResize"
          :model-value="modelValue"
          @update:model-value="onInput($event)"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="onKeydown"
        />
      </template>

      <DropdownListUi
        :options="filteredHints"
        @select="onSelect"
      />
    </DropdownUi>
  </FieldUi>
</template>

<script>
import { defineComponent } from 'vue';
import InputUi from '@/components/ui/InputUi.vue';
import FieldMixin from '@/mixins/form/field-mixin.js';
import { only } from '@/common/utils/props-validators';
import { debounce, uniqueId } from 'lodash-es';
import FieldUi from '@/components/ui/FieldUi.vue';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import DropdownListUi from '@/components/ui/DropdownListUi.vue';
import { INPUT_DEBOUNCE } from '@/common/consts/app.js';
import { NotifyTypes } from '@/configs/notify-types.js';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import { CanceledError } from 'axios';
import AbortMixin from '@/mixins/abort-mixin.js';
import DocumentApi from '@/services/api/document-api.ts';

export default defineComponent({
  name: 'PromptUi',
  components: {
    TextareaUi,
    DropdownListUi,
    DropdownUi,
    FieldUi,
    InputUi,
  },
  mixins: [AbortMixin, FieldMixin],
  props: {
    modelValue: String,
    field: {
      type: String,
      default: 'input',
      validator: only('input', 'textarea'),
    },
    color: {
      type: String,
      default: 'gray',
      validator: only('gray', 'white'),
    },
    size: {
      type: String,
      default: 'm',
      validator: only('m', 'l'),
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
    },
    maxLength: {
      type: [Number, null],
      default: null,
      validator: (value) => value === null || value > 0,
    },
    rows: {
      type: Number,
    },
    noClear: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    getHints: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  data() {
    return {
      id: uniqueId('prompt-ui-'),
      hints: [],
      isLoading: false,
      isAsync: null,
    };
  },
  computed: {
    isInit() {
      return this.isAsync !== null;
    },
    filteredHints() {
      if (this.isAsync || !this.modelValue) {
        return this.hints;
      }

      return this.hints.filter((hint) => {
        const normalizedHint = this.normalize(hint.label);
        const normalizedCurrent = this.normalize(this.modelValue);
        return normalizedHint.includes(normalizedCurrent) && hint.label.trim() !== this.modelValue.trim();
      });
    },
  },
  methods: {
    async init() {
      if (this.isInit) {
        return;
      }

      try {
        this.isLoading = true;
        const hints = await this.getHints();

        this.isAsync = hints.length >= DocumentApi.GET_HINTS_COUNT;

        if (!this.isAsync) {
          this.hints = hints;
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.isLoading = false;
      }
    },
    onInput(value, noOpen) {
      this.$emit('update:modelValue', value);

      if (!this.isInit) {
        return;
      }

      if (this.isAsync) {
        this.getAsyncHints(value, noOpen);
      } else {
        this.getSyncHints(value);
      }
    },
    onFocus() {
      this.init();

      if (this.modelValue) {
        this.open();
      }

      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onClear() {
      this.$emit('update:modelValue', '');
    },
    onKeydown(event) {
      if (event.code === 'Tab') {
        this.close();
      }

      if ((event.metaKey || event.ctrlKey) && event.code === 'Space') {
        this.open();
      }
    },
    onSelect({ option }) {
      this.close();
      this.onInput(option.label, true);
    },
    getAsyncHints(query, noOpen) {
      this.close();
      this.abort('Получено новое значение');
      void this.getHintsDebounced(query, noOpen);
    },
    getSyncHints(query) {
      if (query) {
        this.open();
      } else {
        this.close();
      }
    },
    getHintsDebounced: debounce(async function (query, noOpen) {
      try {
        this.isLoading = true;
        this.hints = await this.getHints(query, this.abortController.signal);
        this.isLoading = false;

        if (!noOpen && this.$refs.control.isFocused) {
          this.open();
        }
      } catch (error) {
        this.onError(error);
      }
    }, INPUT_DEBOUNCE),
    open() {
      if (!this.isLoading && this.filteredHints.length > 0) {
        this.$refs.dropdown.show();
      }
    },
    close() {
      this.$refs.dropdown.hide();
    },
    normalize(value) {
      return value.toLowerCase().replaceAll('ё', 'е');
    },
    onError(error) {
      if (error instanceof CanceledError) {
        return;
      }

      this.isLoading = false;

      this.$notify({
        type: NotifyTypes.Error,
        text: `При получении списка часто используемых значений для поля "${this.label || this.placeholder}" возникла ошибка.`,
        data: error,
      });
    },
  },
});
</script>
