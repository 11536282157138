<template>
  <div
    class="toolbar-wrapper"
    :class="{ 'toolbar-collapsed': isCollapsedState }"
    @click="onToolbarClick"
  >
    <ButtonUi
      v-if="isSlideMode"
      class="burger"
      color="gray"
      mode="outline"
      size="s"
      @click="onMenu"
    >
      <ChevronIcon v-if="isActivePreview" />
      <MenuIcon v-else />
    </ButtonUi>

    <div class="toolbar-content">
      <div class="toolbar-content-left">
        <div
          class="page-title"
          @click="scrollTop"
        >
          {{ textHeader }}
        </div>

        <ButtonUi
          v-if="showAddDocument"
          class="button-add m-down-hide"
          @click="$emit('add-document')"
        >
          <PlusIcon v-prefix="isLarge ? 'm' : null" />
          <span v-if="isLarge">Добавить</span>
        </ButtonUi>

        <ButtonUi
          v-if="$route.meta.table"
          color="gray"
          mode="outline"
          :size="refreshSize"
          @click="reloadTable"
        >
          <ReloadIcon />
        </ButtonUi>

        <component
          :is="$route.meta.informer"
          v-if="posibleShowInformer"
          view="informer"
        />
      </div>

      <ToolbarRight :collapsed="isCollapsedState" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Constants from '@/configs/constants';
import ToolbarRight from '@/components/general/ToolbarRight';
import MenuIcon from '@/assets/icons/menu.svg';
import ChevronIcon from '@/assets/icons/circle/chevron.svg';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { DOC_ADD, DOC_ROUTE_POINT_ADD } from '@/configs/end-points.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import ReloadIcon from '@/assets/icons/reload.svg';
import { COMMISSIONS_TABLE_NAME, CONTROLS_TABLE_NAME } from '@/configs/tables/table';
import Emitter from '@/services/emitter.js';
import { useActionsStore } from '@/stores/actions.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useCommissionsStore } from '@/stores/commissions.js';
import { useControlsStore } from '@/stores/controls.js';
import PlusIcon from '@/assets/icons/plus.svg';

export default defineComponent({
  name: 'ToolbarGeneral',
  components: {
    PlusIcon,
    ChevronIcon,
    MenuIcon,
    ReloadIcon,
    ButtonUi,
    ToolbarRight,
  },
  inject: ['app', 'layout'],
  props: {
    showAddDocument: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add-document'],
  computed: {
    ...mapState(usePreviewStore, ['isActivePreview']),
    isSmall() {
      return this.app.appWidth < Constants.resolutionPoints.small;
    },
    isLarge() {
      return this.app.appWidth > Constants.resolutionPoints.large;
    },
    isSlideMode() {
      return this.layout?.isSlideMode;
    },
    refreshSize() {
      return this.isSlideMode ? 's' : 'm';
    },
    textHeader() {
      return this.isSmall ? this.$route.meta.smallTitle : this.$route.meta.title;
    },
    posibleShowInformer() {
      return this.$route.meta.informer !== undefined && this.app.appWidth >= Constants.resolutionPoints.tinys;
    },
    isCollapsedState() {
      return !!this.layout?.isSlideMode || this.app.appHeight < Constants.resolutionPoints.tiny;
    },
  },
  methods: {
    ...mapActions(useActionsStore, {
      docRoutePointAdd: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD),
      docAddAction: VuexAdapter.getNameAction(DOC_ADD),
    }),
    ...mapActions(usePreviewStore, ['refreshPreviewAction', 'setActivePreviewAction']),
    ...mapActions(useControlsStore, ['getControlsCountAction']),
    ...mapActions(useCommissionsStore, ['getCommissionsCountAction']),
    onMenu() {
      if (this.isActivePreview) {
        this.setActivePreviewAction(false);
      } else {
        Emitter.emit('mobile-menu-show');
      }
    },
    scrollTop() {
      this.layout.frameLeft.element.children[0].scrollTo({ behavior: 'smooth', top: 0 });
      this.layout.frameRight.element.children[0].scrollTo({ behavior: 'smooth', top: 0 });
    },
    reloadTable() {
      Emitter.emit('table-start-reload-' + this.$route.meta?.table);
      this.refreshPreviewAction();
      this.updateCounters();
    },
    updateCounters() {
      switch (this.$route.meta?.table) {
        case COMMISSIONS_TABLE_NAME:
          this.getCommissionsCountAction();
          break;
        case CONTROLS_TABLE_NAME:
          this.getControlsCountAction();
          break;
      }
    },
    onToolbarClick() {
      Emitter.emit('toolbar-click');
    },
  },
});
</script>

<style scoped lang="scss">
.burger {
  margin-left: 8px;
}

.toolbar-content-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-add {
  margin-right: 8px;
}
</style>
