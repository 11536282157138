<script setup lang="ts">
import ModalUi, { type ModalSize } from '@/components/ui/ModalUi.vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CheckIcon from '@/assets/icons/circle/check.svg';
import { computed, ref, watch } from 'vue';

const isOpen = defineModel<boolean>({ required: true });

const { confirm, confirmText, title } = defineProps<{
  confirm: boolean;
  confirmText?: string[];
  title?: string;
  width?: ModalSize;
  height?: string;
  minHeight?: string;
}>();

defineEmits<{
  (e: 'show'): void;
  (e: 'hide'): void;
}>();

let skip = false;

const isModalOpen = ref(false);
const isConfirmOpen = ref(false);

const confirmTextComputed = computed(
  () =>
    confirmText ||
    [
      `Вы собираетесь закрыть окно${title ? '' : '.'}`,
      title && `"${title}"`,
      'Внесенные изменения не будут сохранены.',
      'Продолжить?',
    ].filter(Boolean),
);

watch(isOpen, (value) => {
  if (skip) {
    skip = false;
    return;
  }

  if (value || !confirm) {
    isModalOpen.value = value;
    return;
  }

  isConfirmOpen.value = true;
});

function onUpdateModalOpen(value: boolean) {
  if (skip) {
    skip = false;
    return;
  }

  if (value || !confirm) {
    isOpen.value = value;
    return;
  }

  isConfirmOpen.value = true;
}

function discard() {
  skip = true;
  isConfirmOpen.value = false;
  isModalOpen.value = false;
  isOpen.value = false;
}
</script>

<template>
  <ModalUi
    :model-value="isModalOpen"
    :title="title"
    :width="width"
    :height="height"
    :min-height="minHeight"
    @update:model-value="onUpdateModalOpen"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <slot />

    <template
      v-if="$slots.header"
      #header
    >
      <slot name="header" />
    </template>

    <template
      v-if="$slots.body"
      #body
    >
      <slot name="body" />
    </template>

    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </ModalUi>

  <slot
    name="confirm"
    :is-confirm-open="isConfirmOpen"
    :toggle="(value: boolean) => (isConfirmOpen = value)"
    :discard="discard"
  >
    <ModalUi
      v-model="isConfirmOpen"
      title="Внимание"
      popup
    >
      <template #body>
        <p
          v-for="part in confirmTextComputed"
          :key="part"
        >
          {{ part }}
        </p>
      </template>

      <template #footer>
        <CancelButton @click="isConfirmOpen = false" />

        <ButtonUi @click="discard">
          <CheckIcon v-prefix />
          <span>Продолжить</span>
        </ButtonUi>
      </template>
    </ModalUi>
  </slot>
</template>
