export default class Contact {
  id: number;
  type: string;
  contact: string;
  comment: string;
  mask: string;

  constructor(response: any) {
    this.id = response['CONTACT_ID'];
    this.type = response['Тип'];
    this.contact = response['Контакт'];
    this.comment = response['Примечание'];
    this.mask = response['Контакт_MASK'];
  }
}
