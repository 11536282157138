<script setup lang="ts">
import { useVersionStore } from '@/stores/version.ts';
import { storeToRefs } from 'pinia';

const { size = 'xs', color = 'light-gray' } = defineProps<{
  size?: 'xs' | 's' | 'm';
  color?: 'light-gray' | 'gray' | 'black';
}>();

const versionStore = useVersionStore();
const { current } = storeToRefs(versionStore);
</script>

<template>
  <div
    v-if="current"
    class="release-info"
    :class="[`_color-${color}`, `_size-${size}`]"
  >
    <p>
      Версия: <strong class="_extra">{{ current.version }}</strong>
    </p>
    <p>Дата релиза: {{ current.date }}</p>
  </div>
</template>

<style scoped lang="scss">
.release-info {
  display: flex;
  flex-direction: column;

  &._size-xs {
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
  }

  &._size-s {
    font-size: var(--font-size-s);
    line-height: var(--line-height-s);
  }

  &._size-m {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  &._color-light-gray {
    color: var(--color-gray-300);
  }

  &._color-gray {
    color: var(--color-gray-600);
  }

  &._color-black {
    color: var(--color-gray-1000);
  }
}
</style>
