<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4714 3.52851C10.7318 3.78886 10.7318 4.21097 10.4714 4.47132L6.94285 7.99992L10.4714 11.5285C10.7318 11.7889 10.7318 12.211 10.4714 12.4713C10.2111 12.7317 9.78899 12.7317 9.52864 12.4713L5.52864 8.47132C5.26829 8.21097 5.26829 7.78886 5.52864 7.52851L9.52864 3.52851C9.78899 3.26816 10.2111 3.26816 10.4714 3.52851Z"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'CollapseIcon',
};
</script>
