import { RouteType } from '@/common/enums/route-type.ts';

/** @deprecated Используйте store или локальное состояние компонента */
export default {
  skip_barcode: true,
  created: false,
  route_none: false,
  errors: false,
  resolution: null,
  reg_number: null,
  reg_date: null,
  route_copy_doc_id: null,
  routes_responsible_unspecified_steps: [],
  type_route: RouteType.Standard,
};
