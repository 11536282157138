import BaseFile from '@/common/models/file/base-file.ts';
import { State } from '@/common/enums/state.ts';
import axios from 'axios';
import type { UpdatedEditorFile } from '@/stores/file-editor.ts';

export default class UpdatedFile extends BaseFile {
  documentId: number;
  url: string;
  state: State;
  base64?: string;

  private uploadPromise?: Promise<void>;

  constructor(file: UpdatedEditorFile) {
    super(file.id, file.name);
    this.documentId = file.documentId;
    this.url = file.updatedUrl;
    this.state = State.Undefined;
  }

  async upload() {
    this.uploadPromise ||= this.setBase64();
    await this.uploadPromise;
  }

  private async setBase64() {
    const { data: blob } = await axios.get(this.url, { responseType: 'blob' });
    this.size = blob.size;
    this.base64 = await BaseFile.toBase64(blob);
  }
}
