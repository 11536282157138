<template>
  <div
    v-show="show"
    class="solution-commissions-buttons"
  >
    <SolutionRoutePointModal
      v-if="solutionConfig"
      v-model="isSolutionRoutePointOpen"
      :configs="solutionConfig"
    />

    <ButtonUi
      v-for="{ key, title, icon, color } in buttons"
      :key="key"
      size="s"
      :color="color"
      @click="applyRoutePointSolution(key)"
    >
      <component
        :is="icon"
        v-prefix="'s'"
      />
      <span>{{ title }}</span>
    </ButtonUi>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RoutePointCommissionsSolutionMixin from '@/mixins/route-point-commissions-solution-mixin.js';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import { mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import SolutionRoutePointModal from '@/components/modals/RoutePoint/SolutionRoutePointModal';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { useTableStore } from '@/stores/table.js';

export default defineComponent({
  name: 'SolutionCommissionsButtons',
  components: {
    ButtonUi,
    SolutionRoutePointModal,
  },
  mixins: [RoutePointCommissionsSolutionMixin],
  props: {
    doc: Object,
  },
  data: () => ({
    excluded_statuses: [
      ConstantsRPS.rp_status_type_completed_id,
      ConstantsRPS.rp_status_type_approved_id,
      ConstantsRPS.rp_status_type_rejected_id,
    ],
  }),
  computed: {
    ...mapState(useTableStore, {
      activeRow: VuexAdapter.activeRowTableNameGetter('commissions'),
    }),
    show() {
      return Object.keys(this.buttons).length;
    },
    rp_id_solution() {
      return this.activeRow['RP_ID'];
    },
    doc_id_solution() {
      return this.activeRow['DOC_ID'];
    },
    buttons() {
      const buttons = {};
      if (this.activeRow['RP_ID'] && this.activeRow['DOC_ID'] === this.doc['DOC_ID']) {
        for (const key in this.route_point_solution) {
          if (this.route_point_solution[key]['routePointType'] === this.activeRow['RPT_ID']) {
            if (
              this.activeRow['RPT_ID'] === this.route_point_types.assignment &&
              this.activeRow['RPS_ID'] === ConstantsRPS.rp_status_type_completed_id
            ) {
              continue;
            }
            buttons[key] = {
              icon: this.route_point_solution[key]['icon'],
              title: this.route_point_solution[key]['title'],
              color: this.route_point_solution[key]['color'],
              key,
            };
          }
        }
      }

      return buttons;
    },
  },
  mounted() {
    this.events_solution.push('table-start-reload-commissions');
  },
});
</script>

<style scoped lang="scss">
.solution-commissions-buttons {
  display: flex;
  justify-content: flex-end;
}

.button-ui {
  &:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
