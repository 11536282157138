<template>
  <TransitionGroup
    name="list"
    tag="nav"
    class="document-master-steps"
  >
    <button
      v-for="(step, index) in menu"
      :key="step"
      class="item"
      :class="{ _active: isActive(index) }"
      :disabled="isDisabled(index)"
      @click="goTo(index)"
    >
      <span>{{ step }}</span>
      <ReducedBadge
        v-if="badges[step]"
        v-postfix
        :color="isActive(index) ? 'blue' : 'gray'"
        :value="badges[step]"
      />
    </button>
  </TransitionGroup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import ReducedBadge from '@/components/common/ReducedBadge.vue';
import { useMasterStore } from '@/stores/master.ts';

export default defineComponent({
  name: 'DocumentMasterSteps',
  components: {
    ReducedBadge,
  },
  computed: {
    ...mapState(useMasterStore, ['page', 'controlsDisabled', 'isCreated', 'menu', 'badges']),
    background() {
      return `translateY(${this.page * 32}px)`;
    },
  },
  methods: {
    ...mapActions(useMasterStore, ['goTo']),
    isActive(index) {
      return index === this.page;
    },
    isDisabled(index) {
      return this.controlsDisabled || this.isCreated || index >= this.page;
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-steps {
  position: relative;
  padding: 8px;

  overflow-y: auto;

  background-color: var(--color-gray-075);
  border-radius: 8px;

  &::before {
    content: '';
    display: block;

    position: absolute;
    top: 8px;
    left: 10px;
    right: 8px;
    height: 32px;

    border-left: var(--border-2-gray-700);
    border-radius: 8px;
    color: var(--color-gray-700);
    background-color: var(--color-gray-100);
    transform: v-bind(background);
    transition: transform var(--transition);
  }
}

.item {
  display: flex;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  border-radius: 8px;

  transition:
    color var(--transition),
    opacity var(--transition),
    transform var(--transition);

  &._active {
    color: var(--color-gray-700);
    transform: translateX(2px);
  }

  &:disabled {
    &:not(._active) {
      color: var(--color-gray-400);
    }
  }
}
</style>
