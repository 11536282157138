export default class BankDetails {
  id: number;
  bankName: string;
  bankIdentificationCode: string;
  correspondentAccount: string;
  currentAccount: string;
  comment: string;

  constructor(response: any) {
    this.id = response['ACCOUNT_ID'];
    this.bankName = response['Банк'];
    this.bankIdentificationCode = response['БИК'];
    this.correspondentAccount = response['РС'];
    this.currentAccount = response['КС'];
    this.comment = response['Примечание'];
  }
}
