<script setup lang="ts">
import { ref, useTemplateRef, watch } from 'vue';
import TextareaUi from '@/components/ui/TextareaUi.vue';
import UploadUi from '@/components/ui/UploadUi.vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import UploadIcon from '@/assets/icons/upload.svg';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import useUpload from '@/composables/use-upload.ts';
import ReducedBadge from '@/components/common/ReducedBadge.vue';
import { TableId } from '@/common/enums/table-id.ts';
import ModalForm from '@/components/common/ModalForm.vue';
import type UpdatedFile from '@/common/models/file/updated-file.ts';

const isOpen = defineModel<boolean>({ required: true });

const { documentId } = defineProps({
  documentId: {
    type: Number,
    required: true,
  },
});

defineEmits<{
  (e: 'hide'): void;
}>();

const uploadUiRef = useTemplateRef('uploadUiRef');

const { files, upload } = useUpload();

const comment = ref('');
const isLoading = ref(false);
const isDirty = ref(false);
const error = ref('');

watch(files, () => (error.value = ''));

function onShow() {
  isDirty.value = false;
  files.value = [];
  comment.value = '';
  error.value = '';
}

async function apply() {
  if (!files.value.length) {
    error.value = 'Нет выбранных файлов';
    return;
  }

  error.value = '';
  isLoading.value = true;

  const { hasError } = await upload(TableId.Documents, documentId);

  isLoading.value = false;

  if (!hasError) {
    isDirty.value = false;
    isOpen.value = false;
  }
}

function addFiles(files: File | UpdatedFile | (File | UpdatedFile)[]) {
  uploadUiRef.value!.add(files);
}

defineExpose({ addFiles });
</script>

<template>
  <ModalForm
    v-model="isOpen"
    title="Загрузка файлов"
    :confirm="isDirty"
    @show="onShow"
    @hide="$emit('hide')"
  >
    <template #header>
      <div class="header">
        <h2>Загрузка файлов</h2>
        <ReducedBadge
          v-postfix
          color="blue"
          :value="files.length"
        />
      </div>
    </template>

    <template #body>
      <UploadUi
        ref="uploadUiRef"
        v-model="files"
        :error="error"
        :readonly="isLoading"
        @update:model-value="isDirty = true"
      />
      <TextareaUi
        v-model="comment"
        placeholder="Примечание"
        :disabled="isLoading"
        @update:model-value="isDirty = true"
      />
    </template>

    <template #footer>
      <CancelButton
        :disabled="isLoading"
        @click="isOpen = false"
      />

      <ButtonUi
        :disabled="isLoading"
        @click="apply"
      >
        <SpinnerUi
          v-if="isLoading"
          v-prefix
          color="white"
        />
        <UploadIcon
          v-else
          v-prefix
        />
        <span>Загрузить</span>
      </ButtonUi>
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
}

.upload-ui {
  margin-bottom: 16px;
}
</style>
