<template>
  <component :is="layout">
    <RouterView />
  </component>
  <FileEditorWatcher v-if="showFileEditorWatcher" />
  <UpdateModal />
  <NotifyCustom />
  <VueQueryDevtools />
</template>

<script>
import { defineComponent, watch } from 'vue';
import { useTitle, useWindowSize } from '@vueuse/core';
import EmptyLayout from '@/layouts/EmptyLayout';
import ExtendedViewLayout from '@/layouts/ExtendedViewLayout';
import { mapActions, mapState } from 'pinia';
import UpdateModal from '@/components/modals/System/UpdateModal.vue';
import NotifyCustom from '@/components/common/NotifyCustom.vue';
import { useAuthStore } from '@/stores/auth.js';
import { useCommonStore } from '@/stores/common.js';
import FileEditorWatcher from '@/components/general/FileEditorWatcher.vue';
import { useRoute } from 'vue-router';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { Route } from '@/common/enums/route.ts';

export default defineComponent({
  name: 'App',
  components: {
    VueQueryDevtools,
    FileEditorWatcher,
    NotifyCustom,
    UpdateModal,
    EmptyLayout,
    ExtendedViewLayout,
  },
  provide() {
    const provider = {};
    const self = this;
    Object.defineProperty(provider, 'app', {
      enumerable: true,
      get: () => self,
    });
    return provider;
  },
  setup() {
    const route = useRoute();
    const title = useTitle();

    watch(
      () => route.meta.tabTitle,
      (tabTitle) => (title.value = `АЛЬФА ДОК - ${tabTitle}`),
    );

    const { width: appWidth, height: appHeight } = useWindowSize();

    return {
      appWidth,
      appHeight,
      error_message: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ['isAuthorized']),
    layout() {
      return `${this.$route.meta.layout || 'empty'}-layout`;
    },
    showFileEditorWatcher() {
      return this.isAuthorized && !this.$route.meta.disableFileEditorWatcher;
    },
  },
  watch: {
    isAuthorized(val) {
      if (!val && this.$route.name !== Route.LoginPage) {
        this.cleanAllStoresAction();
        this.$router.replace('/login').catch((e) => (this.error_message = e.error_message));
      }
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.windowResizeHandle);
  },
  mounted() {
    this.clearRefreshPromise();
    const vw = window.innerWidth * 0.01,
      vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    /* Events */
    window.addEventListener('resize', this.windowResizeHandle);
    this.windowResizeHandle();
  },
  updated() {
    this.windowResizeHandle();
  },
  methods: {
    ...mapActions(useCommonStore, ['cleanAllStoresAction']),
    ...mapActions(useAuthStore, ['clearRefreshPromise']),
    windowResizeHandle() {
      const vw = window.innerWidth * 0.01,
        vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
});
</script>
