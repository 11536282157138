<script setup lang="ts">
import { type Icon, useIcons } from '@/composables/use-icons.ts';
import { notify } from '@kyvg/vue3-notification';
import { NotifyTypes } from '@/configs/notify-types';
import { ref } from 'vue';

const folders = useIcons();

const isCopyRaw = ref(false);

async function copy(icon: Icon) {
  await navigator.clipboard.writeText(isCopyRaw.value ? icon.raw : icon.import);
  notify({
    type: NotifyTypes.Success,
    text: isCopyRaw.value ? 'Скопирован SVG-код' : `Скопировано: ${icon.import}`,
  });
}
</script>

<template>
  <section class="icons-page">
    <header class="header">
      <h1>Иконки</h1>

      <label class="checkbox">
        <input
          v-model="isCopyRaw"
          type="checkbox"
        />
        Копировать SVG-код
      </label>
    </header>

    <div
      v-for="(folder, name) in folders"
      :key="name"
      class="folder"
    >
      <h2 class="folder-title">
        <span v-prefix>📁</span>
        <span>{{ name }}</span>
      </h2>

      <div class="grid">
        <button
          v-for="icon in folder"
          :key="icon.name"
          class="button"
          @click="copy(icon)"
        >
          <component
            :is="icon.component"
            class="icon"
          />
          <span>{{ icon.name }}</span>
        </button>
      </div>
    </div>
  </section>
</template>

<style scoped>
.icons-page {
  height: 100%;
  overflow-y: auto;

  padding: 40px;
}

.header {
  margin-bottom: 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}

.folder {
  &:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: var(--border-gray-400);
  }
}

.folder-title {
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 16px;
}

.button {
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  word-break: break-word;
  border-radius: 8px;

  background-color: var(--color-white);

  @media (hover: hover) {
    transition: opacity var(--transition-fast);

    &:not(:disabled) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.icon {
  margin-bottom: 8px;

  width: 40px;
  height: 40px;
}
</style>
