export enum Route {
  CommissionsPage = 'CommissionsPage',
  ControlsPage = 'ControlsPage',
  DocumentsPage = 'DocumentsPage',
  ClientsPage = 'ClientsPage',
  DocumentPage = 'DocumentPage',
  ClientPage = 'ClientPage',
  FileEditorPage = 'FileEditorPage',
  LoginPage = 'LoginPage',
  ForbiddenPage = 'ForbiddenPage',
  NotFoundPage = 'NotFoundPage',
  DocumentNotFoundPage = 'DocumentNotFoundPage',
  IconsPage = 'IconsPage',
}
