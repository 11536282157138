<script setup>
import { computed } from 'vue';
import ClientsByFilterTableIcon from '@/components/icons/ClientsByFilterTableIcon';
import VuexAdapter from '@/services/vuex-adapter.js';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { useTableStore } from '@/stores/table.js';
import CommissionsEmptyByFilterIcon from '@/components/icons/CommissionsEmptyByFilterIcon.vue';
import CommissionsEmptyIcon from '@/components/icons/CommissionsEmptyIcon.vue';
import ControlsEmptyByFilterIcon from '@/components/icons/ControlsEmptyByFilterIcon.vue';
import ControlsEmptyIcon from '@/components/icons/ControlsEmptyIcon.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import DocumentEmptyByFilterTableIcon from '@/components/icons/DocumentEmptyByFilterTableIcon.vue';
import ClientsEmptyTableIcon from '@/components/icons/ClientsEmptyTableIcon.vue';
import DocumentEmptyTableIcon from '@/components/icons/DocumentEmptyTableIcon.vue';
import EmptyTableIcon from '@/components/icons/EmptyTableIcon.vue';
import { useMasterStore } from '@/stores/master.ts';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const tableStore = useTableStore();
const masterStore = useMasterStore();

const hasFilters = computed(() => {
  return Object.keys(tableStore[VuexAdapter.filterTableNameGetter(props.name)]).length > 0;
});

const icon = computed(() => {
  switch (props.name) {
    case 'commissions':
      return hasFilters.value ? CommissionsEmptyByFilterIcon : CommissionsEmptyIcon;
    case 'controls':
      return hasFilters.value ? ControlsEmptyByFilterIcon : ControlsEmptyIcon;
    case 'documents':
      return hasFilters.value ? DocumentEmptyByFilterTableIcon : DocumentEmptyTableIcon;
    case 'clients':
      return hasFilters.value ? ClientsByFilterTableIcon : ClientsEmptyTableIcon;
    default:
      return EmptyTableIcon;
  }
});

const message = computed(() => {
  switch (props.name) {
    case 'commissions':
      return hasFilters.value ? 'Нет отфильтрованных поручений' : 'У вас нет поручений';
    case 'controls':
      return hasFilters.value ? 'У вас нет отфильтрованных документов' : 'У вас нет документов на контроле';
    case 'documents':
      return hasFilters.value ? 'У вас нет отфильтрованных документов' : 'У вас нет документов';
    case 'clients':
      return hasFilters.value
        ? 'У вас нет отфильтрованных карточек контрагентов'
        : 'У вас нет доступа ни к одной карточке контрагента';
    default:
      return 'У вас нет отфильтрованных данных';
  }
});

const showAddDocument = computed(() => !hasFilters.value && props.name === 'controls');

function clearFilters() {
  tableStore[VuexAdapter.delFilterAndOrderByTableNameAction(props.name)]();
}
</script>

<template>
  <div class="empty-table">
    <component
      :is="icon"
      class="icon"
    />

    <div class="message">{{ message }}</div>

    <ButtonUi
      v-if="hasFilters"
      @click="clearFilters"
    >
      Сбросить фильтр
    </ButtonUi>

    <AddButton
      v-if="showAddDocument"
      @click="masterStore.open"
    />
  </div>
</template>

<style scoped lang="scss">
.empty-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-bottom: 20px;
}

// TODO: Refactor
.message {
  color: #a9afbc;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
