<script setup>
import { computed } from 'vue';
import { only } from '@/common/utils/props-validators';

const props = defineProps({
  size: {
    type: String,
    default: 'm',
    validator: only('s', 'm', 'l'),
  },
  color: {
    type: String,
    default: 'yellow',
    validator: only('yellow', 'red', 'green', 'blue', 'light-blue', 'black', 'white', 'gray'),
  },
  mode: {
    type: String,
    default: 'fill',
    validator: only('fill', 'outline'),
  },
  shape: {
    type: String,
    default: 'auto',
    validator: only('auto', 'square', 'round'),
  },
  shadow: Boolean,
});

const classList = computed(() => [
  `_size-${props.size}`,
  `_color-${props.color}`,
  `_mode-${props.mode}`,
  `_shape-${props.shape}`,
  props.shadow && '_shadow',
]);
</script>

<template>
  <button
    class="button-ui"
    :class="classList"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
.button-ui {
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: var(--font-weight-bold);

  transition:
    opacity var(--transition-fast),
    color var(--transition-fast),
    background-color var(--transition-fast),
    border-color var(--transition-fast);

  &:disabled {
    opacity: 0.5;
    color: var(--color-white);

    :deep(svg) {
      fill: var(--color-white);
    }
  }

  :deep(svg) {
    transition:
      fill var(--transition-fast),
      transform var(--transition-fast);
  }

  &._shape-round {
    border-radius: 50%;
  }

  &._size-s {
    height: 24px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    :deep(svg) {
      height: 16px;
      width: 16px;
    }

    &:not(._shape-round) {
      border-radius: 4px;
    }

    &._shape-auto {
      &._mode-fill {
        padding: 0 8px;
      }

      &._mode-outline {
        padding: 0 7px;
      }
    }

    &._shape-square,
    &._shape-round {
      width: 24px;
    }
  }

  &._size-m {
    height: 36px;

    font-size: var(--font-size-l);
    line-height: var(--line-height-l);

    :deep(svg) {
      height: 20px;
      width: 20px;
    }

    &:not(._shape-round) {
      border-radius: 8px;
    }

    &._shape-auto {
      &._mode-fill {
        padding: 0 12px;
      }

      &._mode-outline {
        padding: 0 11px;
      }
    }

    &._shape-square,
    &._shape-round {
      width: 36px;
    }
  }

  &._size-l {
    height: 48px;

    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);

    :deep(svg) {
      height: 24px;
      width: 24px;
    }

    &:not(._shape-round) {
      border-radius: 8px;
    }

    &._shape-auto {
      &._mode-fill {
        padding: 0 16px;
      }

      &._mode-outline {
        padding: 0 15px;
      }
    }

    &._shape-square,
    &._shape-round {
      width: 48px;
    }
  }

  &._shadow {
    box-shadow: var(--shadow);
  }

  @mixin fill($color, $colorHover, $background, $backgroundHover) {
    &._mode-fill {
      background-color: $background;

      &:not(:disabled) {
        color: $color;

        :deep(svg) {
          fill: $color;
        }

        @media (hover: hover) {
          &:hover {
            color: $colorHover;
            background-color: $backgroundHover;

            :deep(svg) {
              fill: $colorHover;
            }
          }
        }
      }
    }
  }

  @mixin outline($color, $colorHover, $border, $borderHover) {
    &._mode-outline {
      color: $color;
      border: $border;

      :deep(svg) {
        fill: $color;
      }

      &:not(:disabled) {
        @media (hover: hover) {
          &:hover {
            color: $colorHover;
            border: $borderHover;

            :deep(svg) {
              fill: $colorHover;
            }
          }
        }
      }
    }
  }

  &._color-yellow {
    @include fill(var(--color-gray-1000), var(--color-gray-1000), var(--color-yellow-600), var(--color-yellow-500));
  }

  &._color-red {
    @include fill(var(--color-white), var(--color-white), var(--color-red-1000), var(--color-red-900));
  }

  &._color-green {
    @include fill(var(--color-white), var(--color-white), var(--color-green-800), var(--color-green-700));
  }

  &._color-blue {
    @include fill(var(--color-white), var(--color-white), var(--color-blue-700), var(--color-blue-600));
  }

  &._color-light-blue {
    @include fill(var(--color-white), var(--color-white), var(--color-blue-400), var(--color-blue-300));
  }

  &._color-black {
    @include fill(var(--color-white), var(--color-white), var(--color-gray-800), var(--color-gray-600));
    @include outline(var(--color-gray-1000), var(--color-gray-600), var(--border-gray-1000), var(--border-gray-800));
  }

  &._color-gray {
    @include outline(var(--color-gray-500), var(--color-gray-600), var(--border-gray-500), var(--border-gray-600));
  }

  &._color-white {
    @include fill(var(--color-gray-1000), var(--color-gray-600), var(--color-white), var(--color-white));

    &:disabled {
      color: var(--color-gray-1000);

      :deep(svg) {
        fill: var(--color-gray-1000);
      }
    }
  }
}
</style>
