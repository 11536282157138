<template>
  <div
    v-if="show"
    class="files-preview"
  >
    <FilePreview
      v-for="file in files"
      :key="file.id"
      :ref="(el) => setFilePreviewRef(el, file.id)"
      :file="file"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FilePreview from '@/components/doc/file-preview/FilePreview.vue';
import { mapState } from 'pinia';
import Emitter from '@/services/emitter.js';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import { State } from '@/common/enums/state.ts';

export default defineComponent({
  name: 'FilePreviewList',
  components: {
    FilePreview,
  },
  data() {
    return {
      displayedLength: 1,
      filePreviewRefs: {},
    };
  },
  mounted() {
    Emitter.on('document-preview-scroll-bottom', this.addFile);
  },
  unmounted() {
    Emitter.off('document-preview-scroll-bottom', this.addFile);
  },
  computed: {
    ...mapState(usePreviewFilesStore, ['previewLastRevisionList', 'isReady', 'lastRevisionFilesState']),
    show() {
      return this.isReady && this.files.length > 0;
    },
    files() {
      return this.previewLastRevisionList.slice(0, this.displayedLength);
    },
  },
  methods: {
    setFilePreviewRef(el, id) {
      if (el) {
        this.filePreviewRefs[id] = el;
      }
    },
    addFile() {
      if (this.displayedLength < this.previewLastRevisionList.length) {
        this.displayedLength++;
      }
    },
    async showPreview(fileId) {
      for (let i = 0; i < this.previewLastRevisionList.length; i++) {
        if (this.files[i].id === fileId) {
          break;
        }
        this.addFile();
      }
      await this.$nextTick();
      const el = this.filePreviewRefs[fileId].$el;

      const unwatch = this.$watch(
        'lastRevisionFilesState',
        async (state) => {
          if (!state[fileId] || state[fileId] === State.Loading) {
            return;
          }

          if (state[fileId] === State.Defined || state[fileId] === State.Error) {
            el.scrollIntoView({ behavior: 'smooth' });
          }

          await this.$nextTick();
          unwatch();
        },
        { immediate: true },
      );
    },
  },
});
</script>

<style scoped lang="scss">
.file-preview {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
