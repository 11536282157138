<template>
  <component
    :is="tag"
    class="card-ui"
  >
    <button
      v-show="!readonly"
      class="close"
      @click="$emit('remove')"
    >
      <CloseSmallIcon class="close-icon" />
    </button>

    <TooltipUi
      class="header"
      :tag="item.href ? 'a' : 'span'"
      :href="item.href"
      :target="item.href && '_blank'"
      :text="item[titleKey]"
      :disabled="!showTooltip"
      @mouseenter="setShowTooltip"
    >
      <b
        ref="title"
        class="title"
      >
        {{ item[titleKey] }}
      </b>
      <span v-if="item.subtitle">{{ item.subtitle }}</span>
    </TooltipUi>

    <component
      :is="icon"
      class="icon"
      width="60px"
      height="60px"
    />

    <component
      :is="footerComponent"
      v-bind="item"
      @change="$emit('change', $event)"
    />
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import CloseSmallIcon from '@/assets/icons/close-small.svg';
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import { only } from '@/common/utils/props-validators';
import TooltipUi from '@/components/ui/TooltipUi.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';

export default defineComponent({
  name: 'CardUi',
  components: {
    ButtonUi,
    TooltipUi,
    CloseSmallIcon,
    ButtonIconUi,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
      validator: only('div', 'li'),
    },
    icon: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    footerComponent: Object,
    titleKey: {
      type: String,
      default: 'title',
    },
  },
  emits: ['change', 'remove'],
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    setShowTooltip() {
      this.showTooltip = this.$refs.title.scrollHeight > this.$refs.title.clientHeight + 1;
    },
  },
});
</script>

<style scoped lang="scss">
.card-ui {
  padding: 4px;

  display: flex;
  flex-direction: column;

  color: var(--color-gray-600);
  border-radius: 8px;
  background-color: var(--color-gray-075);
}

.header {
  flex-grow: 1;
  margin-bottom: 4px;
  padding: 4px;
  min-height: 40px;
}

.title {
  max-height: 32px;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.close {
  position: absolute;
  top: -6px;
  right: -6px;

  width: 17px;
  height: 17px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--color-gray-700);

  @media (hover: hover) {
    transition: background-color var(--transition-fast);

    &:hover {
      background-color: var(--color-gray-500);
    }
  }
}

.close-icon {
  width: 100%;
  height: 100%;

  fill: var(--color-white);
}

.icon {
  fill: var(--color-gray-200);
  align-self: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 32px;
  }
}
</style>
