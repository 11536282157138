<template>
  <div class="eye-commissions">
    <DropdownUi
      ref="dropdown"
      no-overflow
      close-if-inner-click
      close-if-outside-click
    >
      <template #anchor="{ toggle }">
        <MenuButton
          :is-gray="!small"
          @click.stop="toggle"
        />

        <SolutionRoutePointModal
          v-if="solutionConfig"
          v-model="isSolutionRoutePointOpen"
          :configs="solutionConfig"
        />

        <AddRoutePointModal
          v-model="isAddRoutepointOpen"
          :document-id="dataRow['DOC_ID']"
          :parents="parents"
          :rpt-id="rptSeleted"
        />
      </template>

      <DropdownItemUi
        rounded
        @click="open"
      >
        <DocumentTwoIcon v-prefix />
        Документ
      </DropdownItemUi>

      <DropdownUi
        ref="secondDropdown"
        placement="right-start"
        @mouseleave="hideSecondLevel"
      >
        <template #anchor>
          <DropdownItemUi
            class="route-option"
            @click.stop
            @mouseenter="showSecondLevel"
          >
            <div class="route-option-left">
              <CircleChevronIcon v-prefix />
              Создать под…
            </div>

            <ChevronIcon
              v-postfix
              class="icon icon_chevron"
            />
          </DropdownItemUi>
        </template>

        <DropdownItemUi @click="addRoutePointModal(routePointTypes.assignment)">
          <CircleChevronIcon
            v-prefix
            class="icon icon_green"
          />
          Поручение
        </DropdownItemUi>

        <DropdownItemUi @click="addRoutePointModal(routePointTypes.agreement)">
          <CircleChevronIcon
            v-prefix
            class="icon icon_blue"
          />
          Согласование
        </DropdownItemUi>

        <DropdownItemUi @click="addRoutePointModal(routePointTypes.notification)">
          <CircleChevronIcon
            v-prefix
            class="icon icon_light-blue"
          />
          Уведомление
        </DropdownItemUi>
      </DropdownUi>

      <DropdownItemUi
        v-if="showNotification"
        rounded
        @click="applyRoutePointSolution('confirm_familiarization')"
      >
        <component
          :is="route_point_solution.confirm_familiarization.icon"
          v-prefix
        />
        Подтвердить ознакомление
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showAgreement"
        rounded
        @click="applyRoutePointSolution('agreement')"
      >
        <component
          :is="route_point_solution.agreement.icon"
          v-prefix
        />
        {{ route_point_solution.agreement.title }}
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showReject"
        rounded
        @click="applyRoutePointSolution('reject')"
      >
        <component
          :is="route_point_solution.reject.icon"
          v-prefix
        />
        Отклонить
      </DropdownItemUi>

      <DropdownItemUi
        v-if="showReportBack"
        rounded
        @click="applyRoutePointSolution('report_back')"
      >
        <component
          :is="route_point_solution.report_back.icon"
          v-prefix
        />
        Отчитаться о выполнении
      </DropdownItemUi>

      <DropdownDividerUi />

      <DropdownItemUi
        rounded
        :disabled="!isUnread"
        @click.stop="markAsRead(dataRow)"
      >
        <MarkReadIcon v-prefix />
        <span v-prefix="'l'">Пометить как прочитанное</span>
        <span v-postfix="'max'">Ctrl+M</span>
      </DropdownItemUi>

      <DropdownItemUi
        rounded
        :disabled="isUnread"
        @click.stop="markAsUnread(dataRow)"
      >
        <MarkUnreadIcon v-prefix />
        <span v-prefix="'l'">Пометить как непрочитанное</span>
        <span v-postfix="'max'">Ctrl+U</span>
      </DropdownItemUi>

      <DropdownItemUi
        rounded
        :disabled="!commissionsHasNew"
        @click.stop="markAsReadAll(dataRow)"
      >
        <MarkReadAllIcon v-prefix />
        Пометить все как прочитанное
      </DropdownItemUi>
    </DropdownUi>

    <NoveltyFlag
      v-if="small && dataRow['Новое']"
      :data="dataRow['Новое']"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RoutePointCommissionsSolutionMixin from '@/mixins/route-point-commissions-solution-mixin.js';
import ConstantsRPS from '@/configs/route-point-statuses.js';
import NoveltyFlag from '@/components/table/flags/NoveltyFlag';
import DocumentTwoIcon from '@/components/icons/DocumentTwoIcon';
import MenuTableIcon from '@/components/icons/MenuTableIcon';
import ExpandIcon from '@/components/icons/ExpandIcon';
import CircleChevronIcon from '@/assets/icons/circle/fill-chevron.svg';
import routePointTypes from '@/configs/route-point-types.js';
import AddRoutePointModal from '@/components/modals/RoutePoint/AddRoutePointModal';
import SolutionRoutePointModal from '@/components/modals/RoutePoint/SolutionRoutePointModal';
import DropdownUi from '@/components/ui/DropdownUi.vue';
import MenuButton from '@/components/buttons/MenuButton.vue';
import DropdownItemUi from '@/components/ui/DropdownItemUi.vue';
import ChevronIcon from '@/assets/icons/chevron.svg';
import MarkReadIcon from '@/assets/icons/mark/read.svg';
import MarkUnreadIcon from '@/assets/icons/mark/unread.svg';
import MarkReadAllIcon from '@/assets/icons/mark/read-all.svg';
import DropdownDividerUi from '@/components/ui/DropdownDividerUi.vue';
import MarkCommissionsMixin from '@/mixins/mark-commissions-mixin.js';
import { mapState } from 'pinia';
import { useCommissionsStore } from '@/stores/commissions.js';

export default defineComponent({
  name: 'EyeCommissions',
  components: {
    DropdownDividerUi,
    MarkReadAllIcon,
    MarkUnreadIcon,
    MarkReadIcon,
    DropdownItemUi,
    MenuButton,
    DropdownUi,
    SolutionRoutePointModal,
    AddRoutePointModal,
    CircleChevronIcon,
    ChevronIcon,
    ExpandIcon,
    MenuTableIcon,
    DocumentTwoIcon,
    NoveltyFlag,
  },
  mixins: [RoutePointCommissionsSolutionMixin, MarkCommissionsMixin],
  props: ['dataRow', 'small'],
  data: () => ({
    isAddRoutepointOpen: false,
    ConstantsRPS,
    rptSeleted: null,
    routePointTypes,
  }),
  mounted() {
    this.events_solution.push('document-preview-clear');
    this.events_solution.push('table-start-reload-commissions');
  },
  computed: {
    ...mapState(useCommissionsStore, ['commissionsHasNew']),
    rp_id_solution() {
      return this.dataRow['RP_ID'];
    },
    doc_id_solution() {
      return this.dataRow['DOC_ID'];
    },
    rpt_id() {
      return this.dataRow['RPT_ID'];
    },
    rps_id() {
      return this.dataRow['RPS_ID'];
    },
    isUnread() {
      return !!this.dataRow['Новое'];
    },
    showReportBack() {
      return (
        this.rpt_id === this.route_point_types.assignment && this.rps_id !== ConstantsRPS.rp_status_type_completed_id
      );
    },
    showNotification() {
      return this.rpt_id === this.route_point_types.notification;
    },
    showAgreement() {
      return this.rpt_id === this.route_point_types.agreement;
    },
    showReject() {
      return this.rpt_id === this.route_point_types.agreement;
    },
    parents() {
      return '#' + this.dataRow['RP_ID'] + '#';
    },
  },
  methods: {
    open() {
      window.open('/document/' + this.dataRow['DOC_ID'], '_blank');
    },
    showSecondLevel() {
      this.$refs.secondDropdown?.show();
    },
    hideSecondLevel() {
      this.$refs.secondDropdown?.hide();
    },
    addRoutePointModal(rpt_id) {
      this.rptSeleted = rpt_id;
      this.isAddRoutepointOpen = true;
    },
    beforeMark() {
      this.$refs.dropdown.hide();
    },
  },
});
</script>

<style scoped lang="scss">
.route-option {
  justify-content: space-between;
}

.route-option-left {
  display: flex;
  align-items: center;
}

.icon {
  &_chevron {
    transform: rotate(-90deg);
  }

  &_green {
    :deep(.inside) {
      fill: var(--color-green-800);
    }

    :deep(.outside) {
      fill: var(--color-green-500);
    }
  }

  &_blue {
    :deep(.inside) {
      fill: var(--color-blue-700);
    }

    :deep(.outside) {
      fill: var(--color-blue-400);
    }
  }

  &_light-blue {
    :deep(.inside) {
      fill: var(--color-blue-400);
    }

    :deep(.outside) {
      fill: var(--color-blue-200);
    }
  }
}

.novelty-flag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
