<script setup lang="ts">
import ButtonUi from '@/components/ui/ButtonUi.vue';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import StopCircleIcon from '@/assets/icons/circle/stop.svg';
import ModalUi from '@/components/ui/ModalUi.vue';

const isOpen = defineModel<boolean>({ required: true });
const isConfirmed = defineModel<boolean | null>('confirmed', { required: true });

defineEmits<{
  (e: 'hide'): void;
}>();

function close(result: boolean) {
  isConfirmed.value = result;
  isOpen.value = false;
}
</script>

<template>
  <ModalUi
    v-model="isOpen"
    title="Внимание"
    popup
    @hide="$emit('hide')"
  >
    <template #body>У документа есть неподтвержденные поручения. Считать их завершенными?</template>

    <template #footer>
      <ButtonUi
        color="white"
        @click="close(false)"
      >
        <CircleCloseIcon v-prefix />
        <span>Нет</span>
      </ButtonUi>

      <ButtonUi @click="close(true)">
        <StopCircleIcon v-prefix />
        <span>Да</span>
      </ButtonUi>
    </template>
  </ModalUi>
</template>
