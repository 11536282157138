import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M14 5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523v6.985c0 .523 0 .984.03 1.354.032.369.093.707.247 1.046.246.492.677.923 1.17 1.169.338.154.676.215 1.045.246.37.03.8.03 1.354.03h4.308c.523 0 .985 0 1.354-.03s.707-.092 1.046-.246a2.7 2.7 0 0 0 1.17-1.17c.153-.338.214-.676.245-1.045.031-.37.031-.8.031-1.354V5.323m-4-2.37 1.723 1.724H10.03V2.954zm2.677 8.524c0 .585 0 .954-.03 1.261-.032.277-.062.431-.124.554a1.34 1.34 0 0 1-.585.585c-.092.061-.246.092-.553.123-.308.03-.677.03-1.262.03H5.877c-.585 0-.954 0-1.262-.03-.277-.03-.43-.062-.553-.123a1.34 1.34 0 0 1-.585-.585c-.062-.092-.092-.246-.123-.553-.03-.308-.03-.677-.03-1.262V4.523c0-.584 0-.954.03-1.261.03-.277.061-.431.123-.554.123-.246.338-.431.585-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8v2.768c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769v5.477",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }