import { ContactEndPoint } from '@/common/enums/end-point.ts';
import Api from '@/services/api/api';
import { TableId } from '@/common/enums/table-id.ts';
import type Option from '@/common/models/option/option';
import { type MaybeRef, unref } from 'vue';

export interface ContactFormData {
  type: Option;
  contact: string;
  comment: string;
}

interface AddRequest {
  tableId: TableId;
  parentId: number;
  formData: MaybeRef<ContactFormData>;
}

interface EditRequest {
  id: number;
  formData: MaybeRef<ContactFormData>;
}

export default class ContactApi {
  static async add({ tableId, parentId, formData }: AddRequest) {
    const data = unref(formData);

    await Api.post(ContactEndPoint.Add, {
      parent_table_id: tableId,
      parent_id: parentId,
      contact_type: data.type.label,
      contact: data.contact,
      comment: data.comment,
    });
  }

  static async edit({ id, formData }: EditRequest) {
    const data = unref(formData);

    await Api.post(ContactEndPoint.Edit, {
      contact_id: id,
      contact_type: data.type.label,
      contact: data.contact,
      comment: data.comment,
    });
  }

  static async delete(id: number) {
    await Api.post(ContactEndPoint.Delete, { contact_id: id });
  }
}
