<template>
  <div class="start-parameters-step-master">
    <div class="start-parameters-step-master__header">
      <div class="start-parameters-step-master__header__label">Номер документа:</div>

      <div class="start-parameters-step-master__header__number">
        <a
          :href="'/document/' + docId"
          target="_blank"
        >
          {{ docId }}
        </a>
      </div>

      <div
        class="start-parameters-step-master__header__copy"
        @click="copy"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_107_20087)">
            <path
              d="M15.5945 2H4.68537C3.68537 2 2.86719 2.81818 2.86719 3.81818V16.5455H4.68537V3.81818H15.5945V2ZM18.3217 5.63636H8.32173C7.32173 5.63636 6.50355 6.45455 6.50355 7.45455V20.1818C6.50355 21.1818 7.32173 22 8.32173 22H18.3217C19.3217 22 20.1399 21.1818 20.1399 20.1818V7.45455C20.1399 6.45455 19.3217 5.63636 18.3217 5.63636ZM18.3217 20.1818H8.32173V7.45455H18.3217V20.1818Z"
              fill="#5F6A7D"
            />
          </g>
        </svg>
      </div>
    </div>

    <div class="start-parameters-step-master__content">
      <Transition
        name="opacity-replaced"
        mode="out-in"
      >
        <div
          v-if="showThumbUp"
          key="thumb-up"
          class="thumb-up"
        >
          <ThumbUpIcon class="thumb-up-icon" />
          <CheckAnimateIcon class="check-animate" />
        </div>

        <FormBuilder
          v-else-if="showForm"
          key="form"
          ref="form"
          v-model="formData"
          class="_old"
          :fields="fields"
        />
      </Transition>
    </div>

    <TileListUi :options="tiles" />

    <LoaderUi
      v-if="isLoading"
      color="white"
      text="Загрузка данных"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StepMixin from '@/mixins/document-master/step-mixin.js';
import { LaunchType } from '@/common/enums/launch-type.ts';
import FormBuilder from '@/components/form/FormBuilder.vue';
import { RouteType } from '@/common/enums/route-type.ts';
import { mapActions, mapState } from 'pinia';
import VuexAdapter from '@/services/vuex-adapter.js';
import { CONTROL_GET, DOC_CONTROLLER_SET, DOC_STARTDATE_SET, SYSTEM_USER_ID } from '@/configs/end-points.js';
import { DateTime } from 'luxon';
import { NotifyTypes } from '@/configs/notify-types.js';
import { CONTROLS_TABLE_NAME } from '@/configs/tables/table';
import OpenDocumentIcon from '@/assets/icons/open-document.svg';
import FavoriteIcon from '@/assets/icons/favorite.svg';
import TileListUi from '@/components/ui/TileListUi.vue';
import ThumbUpIcon from '@/assets/icons/thumb-up.svg';
import CheckAnimateIcon from '@/assets/icons/check-animate.svg';
import { useMasterControllerStore } from '@/stores/master-controller.js';
import { useMasterDeadlineStore } from '@/stores/master-deadline.js';
import { useMasterStore } from '@/stores/master.ts';
import { useActionsStore } from '@/stores/actions.js';
import { useTableStore } from '@/stores/table.js';
import { useListsStore } from '@/stores/lists.js';
import DocumentMasterFields from '@/common/consts/document-master/fields.js';
import { StartType } from '@/common/enums/start-type.js';
import { INITIAL_LAUNCH_DEADLINE, INITIAL_START_TYPE } from '@/common/consts/document-master/common.js';
import { DeadlineType } from '@/common/enums/deadline-type.js';
import { getStartOfDayDate } from '@/common/utils/date.js';
import DocumentApi from '@/services/api/document-api.js';
import LoaderUi from '@/components/ui/LoaderUi.vue';

export default defineComponent({
  name: 'StepLaunch',
  components: {
    LoaderUi,
    TileListUi,
    FormBuilder,
    ThumbUpIcon,
    CheckAnimateIcon,
  },
  mixins: [StepMixin],
  data() {
    return {
      isLoading: false, // TODO: Отказаться
      formData: {
        launchType: LaunchType.Now,
        startType: INITIAL_START_TYPE,
        deadline: INITIAL_LAUNCH_DEADLINE,
        stopDocument: false,
      },
      showForm: false,
      showThumbUp: false,
      documentWindow: null,
    };
  },
  computed: {
    ...mapState(useMasterControllerStore, ['isControllerUser']),
    ...mapState(useMasterDeadlineStore, {
      deadline: '$state',
      startDateFormatted: 'deadline',
      hasDeadline: 'hasDeadline',
    }),
    ...mapState(useListsStore, {
      controlList: VuexAdapter.getNameGetter(CONTROL_GET),
    }),
    fields() {
      return [
        DocumentMasterFields.launchType(() => ({
          hidden: this.launchDisabled,
          disabled: this.hasRoutesResponsibleSkip,
        })),
        DocumentMasterFields.launchTypeNoneWarning(
          () => this.formData.launchType !== LaunchType.None || this.launchDisabled,
        ),
        DocumentMasterFields.launchStartType(() => ({
          showBefore: this.hasDeadline,
          hidden: this.formData.launchType !== LaunchType.Now || this.launchDisabled,
        })),
        DocumentMasterFields.launchDeadline(() => ({
          hidden: this.hiddenStartControlTypeTerm,
          countBackwards:
            this.formData.startType.code === StartType.Before && this.formData.launchType === LaunchType.Now,
          startDate: this.startDate,
        })),
        DocumentMasterFields.stopDocument(() => this.formData.launchType !== LaunchType.Delayed),
      ];
    },
    startDate() {
      if (
        this.startDateFormatted &&
        this.formData.launchType === LaunchType.Now &&
        this.formData.startType.code === StartType.Before
      ) {
        return DateTime.fromSQL(this.startDateFormatted).toJSDate();
      }

      return getStartOfDayDate();
    },
    tiles() {
      return [
        {
          code: 'openDocument',
          label: 'Открыть документ',
          icon: OpenDocumentIcon,
          inline: true,
          onClick: () => (this.documentWindow = window.open('/document/' + this.docId, '_blank')),
        },
        {
          code: 'favorite',
          label: 'В избранное',
          icon: FavoriteIcon,
          inline: true,
          disabled: true,
        },
      ];
    },
    hasRoutesResponsibleSkip() {
      return this.formDataProxy.routes_responsible_unspecified_steps.some((i, n) => {
        return this.formDataProxy['routes_responsible_skip_' + n];
      });
    },
    launchDisabled() {
      return this.formDataProxy.type_route === RouteType.None || this.formDataProxy.type_route === RouteType.New;
    },
    docId() {
      return Number(this.formDataProxy?.doc_id);
    },
    hiddenStartControlTypeTerm() {
      if (this.launchDisabled) {
        return true;
      }

      if (this.formData.launchType === LaunchType.Now && this.formData.startType.code !== StartType.Immediately) {
        return false;
      }

      return this.formData.launchType !== LaunchType.Delayed;
    },
  },
  mounted() {
    // FIXME: При !this.isControllerUser форма не отобразится
    if (this.hasRoutesResponsibleSkip || !this.isControllerUser) {
      this.formData.launchType = LaunchType.None;
    }

    setTimeout(() => {
      this.showThumbUp = true;

      if (!this.launchDisabled && this.isControllerUser) {
        setTimeout(() => {
          this.showThumbUp = false;
          this.showForm = true;
        }, 500 + 700); // 500 delay + 700 checkmark animation
      }
    }, 100);
  },
  methods: {
    ...mapActions(useMasterStore, {
      setShowForceClose: 'setShowForceClose',
      closeMaster: 'close',
    }),
    ...mapActions(useActionsStore, {
      startDateAction: VuexAdapter.getNameAction(DOC_STARTDATE_SET),
      setControllerAction: VuexAdapter.getNameAction(DOC_CONTROLLER_SET),
      getSystemUserIdAction: VuexAdapter.getNameAction(SYSTEM_USER_ID),
    }),
    ...mapActions(useTableStore, {
      refreshControlsTable: VuexAdapter.refreshOnlyStateTableNameAction(CONTROLS_TABLE_NAME),
      refreshControlsRow: VuexAdapter.activeRowTableNameMutation(CONTROLS_TABLE_NAME),
    }),
    copy() {
      navigator.clipboard.writeText(this.docId);
    },
    async ready() {
      if (!this.launchDisabled && this.isControllerUser && !this.$refs.form?.validate()) {
        return;
      }

      this.isLoading = true;

      try {
        // Запустить сразу и поставить на контроль СРАЗУ
        if (this.formData.launchType === LaunchType.Now && this.formData.startType.code === StartType.Immediately) {
          await DocumentApi.activate(this.docId);
        }

        // Запустить сразу и поставить на контроль ЧЕРЕЗ
        if (this.formData.launchType === LaunchType.Now && this.formData.startType.code === StartType.After) {
          await this.controlsDocActivationAction({
            doc_id: this.docId,
            active: 1,
          });
          await this.startDateAction({
            doc_id: this.docId,
            start_date: this.getFormattedDeadline(this.formData.deadline),
          });

          const res = await this.getSystemUserIdAction({});

          await this.setControllerAction({
            doc_id: this.docId,
            login_id: res.data.login_id,
          });
        }

        // Отложенный запуск
        if (this.formData.launchType === LaunchType.Delayed) {
          await this.startDateAction({
            doc_id: this.docId,
            start_date: this.getFormattedDeadline(this.formData.deadline),
          });

          if (this.formData.stopDocument) {
            const res = await this.getSystemUserIdAction({});

            await this.setControllerAction({
              doc_id: this.docId,
              login_id: res.data.login_id,
            });
          }
        }

        this.documentWindow?.location.reload();
      } catch (error) {
        this.setShowForceClose(true);

        this.$notify({
          type: NotifyTypes.Error,
          text: 'При запуске документа возникла ошибка.',
          data: error,
        });

        this.isLoading = false;

        this.formDataProxy.errors = true;
        this.change();

        return;
      }

      void this.updateTable();

      if (this.formDataProxy.type_route === RouteType.None) {
        this.$notify({
          type: NotifyTypes.Success,
          text: `Документ <a href="/document/${this.docId}" target="_blank">${this.docId}</a> добавлен.`,
        });
      } else if (this.formData.launchType !== LaunchType.None) {
        this.$notify({
          type: NotifyTypes.Success,
          text: 'Параметры запуска документа успешно установлены.',
        });
      }

      void this.closeMaster();
    },
    async updateTable() {
      await this.refreshControlsTable({});

      this.controlList.forEach((row, index) => {
        if (row['DOC_ID'] === this.docId) {
          this.refreshControlsRow({ row, index });
        }
      });
    },
    getFormattedDeadline(state) {
      switch (state.type) {
        case DeadlineType.WorkingDays:
          return state.workingDaysDate;
        case DeadlineType.Days:
          return state.daysDate;
        case DeadlineType.Date:
          return state.date;
        default:
          throw new Error('Получено неожиданное значение');
      }
    },
  },
});
</script>

<!-- TODO: Рефакторинг -->
<style scoped lang="scss">
.start-parameters-step-master {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--color-gray-700);
    font-weight: 400;

    &__number {
      font-weight: 800;
      margin-right: 10px;
      margin-left: 10px;

      a {
        color: var(--color-gray-700);
      }
    }

    &__copy {
      cursor: pointer;
    }
  }

  &__content {
    position: relative;
    margin-bottom: auto;

    &:deep(.form__row) {
      &:empty {
        display: none;
      }

      #form-col-stopDocument {
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #9da4b4;
          margin-top: 8px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.thumb-up {
  display: flex;
  justify-content: center;

  .thumb-up-icon {
    fill: transparent;
    position: absolute;
    width: 200px;
    height: 200px;
    left: 121px;
    top: 30px;
  }

  .check-animate {
    fill: #f3f6fb;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 205px;
    top: 105px;

    :deep(path) {
      stroke-dasharray: 1000 1000;
      stroke-dashoffset: -100;
      animation: dash-check 0.7s 0.5s ease-in-out forwards;
    }
  }
}

:deep(.form__row--4) {
  .checkbox-label {
    margin-top: 4px;
    display: block;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
</style>
