import Api from '@/services/api/api.js';
import { Parameter } from '@/common/models/parameter.js';
import CacheGroup from '@/common/enums/cache-group.js';
import { LinkTypeOption } from '@/common/models/option/link-type-option.js';
import TextOption from '@/common/models/option/text-option.js';
import UserOption from '@/common/models/option/user-option.js';
import { DateTime } from 'luxon';
import {
  DocumentEndPoint,
  LinkEndPoint,
  ProjectEndPoint,
  SystemEndPoint,
  UserEndPoint,
} from '@/common/enums/end-point.ts';
import { TABLE_SORT_ASC } from '@/configs/tables/table.js';
import TypeOption from '@/common/models/option/type-option.js';
import { TypeCode } from '@/common/consts/document-master/common.js';
import ProjectOption from '@/common/models/option/project-option.js';
import { useSystemStore } from '@/stores/system.js';
import DocumentApi from '@/services/api/document-api.ts';

export default class DocumentMasterApi {
  static DEFAULT_LINK_TYPE = {
    code: 1,
    label: 'По умолчанию',
  };

  static async getTypes(signal) {
    const systemStore = useSystemStore();
    const nameKey = systemStore.dbAdapter.generateKey('Название');

    const response = await Api.post(
      DocumentEndPoint.GetTypes,
      {
        orderby: `${nameKey} ${TABLE_SORT_ASC}`,
      },
      signal,
      true,
      { group: CacheGroup.DocumentMaster, key: `MASTER_TYPES` },
    );

    return response.items.map((item) => new TypeOption(item)).filter((type) => type.code !== TypeCode.Route);
  }

  static async getProjects(signal) {
    const systemStore = useSystemStore();
    const nameKey = systemStore.dbAdapter.generateKey('Название');

    const response = await Api.post(
      ProjectEndPoint.GetList,
      {
        orderby: `${nameKey} ${TABLE_SORT_ASC}`,
      },
      signal,
      true,
      { group: CacheGroup.DocumentMaster, key: `MASTER_PROJECTS` },
    );

    return response.items.map((item) => new ProjectOption(item)).filter((project) => !project.disabled);
  }

  static async getParameters(typeId, signal) {
    const response = await Api.post(
      DocumentEndPoint.GetTypeParameters,
      {
        DT_ID: typeId,
      },
      signal,
    );

    return response.items.map((item) => new Parameter(item));
  }

  static async getParameterOptions(typeId, number, signal) {
    const response = await Api.post(
      DocumentEndPoint.GetTypeParameterOptions,
      {
        dt_id: typeId,
        number,
      },
      signal,
      true,
      { group: CacheGroup.DocumentMaster, key: `MASK_PARAMETER_${typeId}_${number}` },
    );

    return response.items.map((item) => new TextOption(item));
  }

  static async getHints(request, signal) {
    const response = await Api.post(
      DocumentEndPoint.GetTypeParameterOptionHints,
      {
        dt_id: request.id,
        number: request.number,
        count: DocumentApi.GET_HINTS_COUNT,
        value: request.query,
      },
      signal,
    );

    return response.items.map((item) => new TextOption(item));
  }

  static async getLinkTypes(linkTypesIds = [], tableId, signal) {
    if (!linkTypesIds.length) {
      return [DocumentMasterApi.DEFAULT_LINK_TYPE];
    }

    const response = await Api.post(
      LinkEndPoint.GetLinkTypes,
      {
        where: `LT_ID IN (${linkTypesIds.join(',')})`,
      },
      signal,
      true,
      { group: CacheGroup.DocumentMaster, key: `LINK_TYPES_${linkTypesIds.join('_')}` },
    );

    const options = response.items.map((item) => new LinkTypeOption(item));

    if (!tableId) {
      return [DocumentMasterApi.DEFAULT_LINK_TYPE, ...options];
    }

    return [DocumentMasterApi.DEFAULT_LINK_TYPE, ...options.filter((option) => option.tableIds.includes(tableId))];
  }

  static async getActiveUsers(signal) {
    const response = await Api.post(UserEndPoint.GetListActive, {}, signal, true, {
      group: CacheGroup.DocumentMaster,
      key: `USERS`,
    });

    return response.items.map((user) => new UserOption(user));
  }

  static async getDeadline(days, startDate, signal) {
    const response = await Api.post(
      SystemEndPoint.GetDeadline,
      {
        count_day: days,
        date_start: startDate,
      },
      signal,
    );

    return DateTime.fromFormat(response.date, 'dd.MM.yyyy').toFormat('yyyy-MM-dd');
  }
}
