<template>
  <div class="PreferencesList">
    <ul class="PreferencesList_container">
      <li
        v-for="key in configOrderArray"
        :key.prop="key"
        class="list-group-item"
      >
        <span class="PreferencesList_bars">
          <MenuTableSettingBarsIcon class="icon" />
          {{ configs.info[key].title }}
        </span>
        <CheckboxForm
          v-model="form[key]"
          @update:model-value="$emit('change')"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import MenuTableSettingBarsIcon from '@/components/icons/MenuTableSettingBarsIcon';
import CheckboxForm from '@/components/form/CheckboxForm';
import VuexAdapter from '@/services/vuex-adapter.js';
import Emitter from '@/services/emitter.js';
import { useTableStore } from '@/stores/table.js';

export default {
  name: 'PreferencesList',
  components: {
    CheckboxForm,
    MenuTableSettingBarsIcon,
  },
  props: ['configs'],
  emits: ['change'],
  data: () => ({
    form: {},
    preferences: {},
    configOrderArray: [],
    tableStore: useTableStore(),
  }),
  computed: {
    visibleColumns() {
      return this.tableStore[VuexAdapter.visibleColumnsTableNameGetter(this.configs.name)] || {};
    },
    sortColumns() {
      return this.tableStore[VuexAdapter.sortColumnsTableNameGetter(this.configs.name)] || [];
    },
  },
  mounted() {
    this.setPreferences();
    this.setConfigOrderList();

    Emitter.on('preferences-table-modal-apply-table-' + this.configs.name, this.setPreferencesList);
    Emitter.on('preferences-table-modal-default-table-' + this.configs.name, this.cleanPreferencesList);
    Emitter.on('preferences-table-modal-on-all-table-' + this.configs.name, this.onAllCheckbox);
    Emitter.on('preferences-table-modal-off-all-table-' + this.configs.name, this.offAllCheckbox);
    Emitter.on('table-set-refresh-total-' + this.configs.name, () => this.cleanPreferencesList());
  },
  unmounted() {
    Emitter.off('preferences-table-modal-apply-table-' + this.configs.name);
    Emitter.off('preferences-table-modal-default-table-' + this.configs.name);
    Emitter.off('preferences-table-modal-on-all-table-' + this.configs.name);
    Emitter.off('preferences-table-modal-off-all-table-' + this.configs.name);
    Emitter.off('table-set-refresh-total-' + this.configs.name);
  },
  methods: {
    setPreferences() {
      this.preferences = this.visibleColumns ? { ...this.visibleColumns } : {};
    },
    setConfigOrderList(reload = false) {
      let array = this.configOrderArray.length === 0 || reload ? [...this.sortColumns] : this.configOrderArray;
      if (array.length === 0) {
        array = Object.keys(this.configs.info);
      }

      array.forEach((key) => {
        this.form[key] =
          this.preferences[key] === undefined || reload ? this.configs.info[key].visible : this.preferences[key];
      });

      this.configOrderArray = array.filter(
        (value) => !this.configs.info[value].system && !this.configs.info[value].configTitle,
      );
    },
    setPreferencesList() {
      this.tableStore[VuexAdapter.visibleColumnsTableNameAction(this.configs.name)](this.form);
      this.tableStore[VuexAdapter.sortColumnsTableNameAction(this.configs.name)](this.configOrderArray);
    },
    cleanPreferencesList() {
      this.tableStore[VuexAdapter.visibleColumnsTableClearNameAction(this.configs.name)]();
      this.tableStore[VuexAdapter.sortColumnsTableClearNameAction(this.configs.name)]();
      this.setConfigOrderList(true);
      this.setPreferences();
    },
    onAllCheckbox() {
      for (const key in this.form) {
        this.preferences[key] = true;
        this.form[key] = true;
      }
    },
    offAllCheckbox() {
      for (const key in this.form) {
        this.preferences[key] = false;
        this.form[key] = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.PreferencesList {
  color: var(--color-gray-600);
}

.icon {
  margin-right: 8px;
}
</style>
