import { ref } from 'vue';
import ExternalFile from '@/common/models/file/external-file.ts';
import { unionWith } from 'lodash-es';
import { NotifyTypes } from '@/configs/notify-types.js';
import { fileSize } from '@/common/utils/utils.js';
import UpdatedFile from '@/common/models/file/updated-file.ts';
import { notify } from '@kyvg/vue3-notification';

export default function useFileInput({ files, readonly = ref(false), update, afterAdd }) {
  const input = ref();

  function getUniq(...arrays) {
    return unionWith(...arrays, (a, b) => {
      if (a.name === b.name) {
        notify({
          type: NotifyTypes.Warn,
          text: `Файл "${b.name}" (${fileSize(b.size)}) уже добавлен`,
        });
        return true;
      }
      return false;
    });
  }

  function dispatchChange() {
    if (!readonly.value) {
      input.value.dispatchEvent(new MouseEvent('click'));
    }
  }

  function onChange(event) {
    if (readonly.value) {
      input.value.value = '';
      return;
    }

    const rawNewFiles = Array.from(event.target.files);
    input.value.value = '';

    if (rawNewFiles.length) {
      void add(rawNewFiles);
    }
  }

  async function add(param) {
    const addingIndex = files.value.length;

    const newFiles = Array.isArray(param) ? param : [param];

    const raw = newFiles.filter((file) => file instanceof File);
    const external = await ExternalFile.createList(raw);

    const other = newFiles.filter((file) => !(file instanceof File));
    other.forEach((file) => {
      if (file instanceof UpdatedFile) {
        file.upload();
      }
    });

    const uniq = getUniq(files.value, other, external);
    update(uniq);

    afterAdd?.(addingIndex);
  }

  async function replace(updatedFile) {
    await updatedFile.upload();

    const newFiles = [...files.value];
    const index = newFiles.findIndex((file) => file.id === updatedFile.id);
    const replacementIndex = index === -1 ? newFiles.length : index;
    newFiles[replacementIndex] = updatedFile;
    update(newFiles);

    afterAdd?.(replacementIndex);
  }

  function remove(id) {
    const updatedFiles = files.value.filter((current) => current.id !== id);
    update(updatedFiles);
  }

  return {
    input,
    dispatchChange,
    onChange,
    add,
    replace,
    remove,
  };
}
