export enum AuthEndPoint {
  Login = 'auth/org/',
  Logout = 'logout/',
  Refresh = 'auth/refresh/',
}

export enum UserEndPoint {
  GetId = 'user/id/',
  GetName = 'user/fio/',
  GetListActive = 'users/active/',
}

export enum SystemEndPoint {
  GetDeadline = 'system/get/deadline/',
  SetUnread = 'system/set/unread/',
  SetReadAll = 'system/set/read/',
  GetServerId = 'system/server/id/',
  GetAutoSingStatus = 'system/autosign/status/',
  CheckReplicationSystem = 'system/rplobjects/check/',
  AddComment = 'system/comment/add/',
}

export enum FileEndPoint {
  GetUserFiles = 'system/user/files/get/',
  GetEdition = 'system/file/edition/get/',
  GetMeta = 'system/file/meta/get/',
  Add = 'system/file/add/with/content/',
  AddEdits = 'system/file/add/with/edits/',
  AddView = 'system/file/addview/',
  Move = 'doc/file/move/',
  GetEditConfig = 'onlyoffice/config/get/',
}

export enum ProjectEndPoint {
  GetList = 'projects/get/',
}

export enum DocumentEndPoint {
  GetList = 'docs/get/',
  GetProgress = 'docs/progress/',
  GetOptions = 'doc/check/',
  GetFiles = 'doc/files/get/',
  GetTypes = 'doc/types/active/get/',
  GetTypeParameters = 'doc/types/mask/params/get/',
  GetTypeParameterOptions = 'doc/type/mask/param/values/get/',
  GetTypeParameterOptionHints = 'doc/type/mask/param/values/frequent/',
  Delete = 'doc/delete/',
  Stop = 'doc/stop/',
  SetActivation = 'doc/activation/set/',
  ConfirmRoutepoint = 'doc/routepoint/mark/confirm/',
  SetRead = 'doc/mark/view/',
  SetRouteRead = 'doc/routepoint/mark/read/',
}

export enum ClientEndPoint {
  GetList = 'clients/get/',
  GetOptions = 'client/check/',
}

export enum LinkEndPoint {
  GetLinkTypes = 'link/types/active/',
}

export enum RouteEndPoint {
  GetFileTemplates = 'routes/files/templates/',
}

export enum AddressEndPoint {
  Add = 'address/add/',
  Edit = 'address/edit/',
  Delete = 'address/del/',
  GetTypes = 'address/types/get/',
}

export enum ContactEndPoint {
  Add = 'contact/add/',
  Edit = 'contact/edit/',
  Delete = 'contact/del/',
  GetTypes = 'contact/types/get/',
}

export enum BankDetailsEndPoint {
  Get = 'clients/bankdetails/get/',
  Add = 'clients/bankdetails/add/',
  Edit = 'clients/bankdetails/edit/',
  Delete = 'clients/bankdetails/del/',
}
