import { BankDetailsEndPoint } from '@/common/enums/end-point.ts';
import Api from '@/services/api/api';
import { TableId } from '@/common/enums/table-id.ts';
import { type MaybeRef, unref } from 'vue';

export interface BankDetailsFormData {
  bankName: string;
  bankIdentificationCode: string;
  correspondentAccount: string;
  currentAccount: string;
  comment: string;
}

interface AddRequest {
  tableId: TableId;
  parentId: number;
  formData: MaybeRef<BankDetailsFormData>;
}

interface EditRequest extends AddRequest {
  id: number;
}

export default class BankDetailsApi {
  static async add({ tableId, parentId, formData }: AddRequest) {
    const data = unref(formData);

    await Api.post(BankDetailsEndPoint.Add, {
      parent_table_id: tableId,
      parent_id: parentId,
      bank_name: data.bankName,
      bik: data.bankIdentificationCode,
      kc: data.correspondentAccount,
      account: data.currentAccount,
      comment: data.comment,
    });
  }

  static async edit({ tableId, parentId, id, formData }: EditRequest) {
    const data = unref(formData);

    await Api.post(BankDetailsEndPoint.Edit, {
      parent_table_id: tableId,
      parent_id: parentId,
      account_id: id,
      bank_name: data.bankName,
      bik: data.bankIdentificationCode,
      kc: data.correspondentAccount,
      account: data.currentAccount,
      comment: data.comment,
    });
  }

  static async delete(id: number) {
    await Api.post(BankDetailsEndPoint.Delete, { account_id: id });
  }
}
