import { defineComponent, markRaw } from 'vue';
import route_point_types from '@/configs/route-point-types.js';
import {
  DOC_ROUTE_POINT_ACCEPT,
  DOC_ROUTE_POINT_DECLINE,
  DOC_ROUTE_POINT_MARK_FINISH,
  DOC_ROUTE_POINT_MARK_VIEW,
} from '@/configs/end-points.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import CircleCloseIcon from '@/assets/icons/circle/close.svg';
import ConfirmFamiliarizationIcon from '@/components/icons/ConfirmFamiliarizationIcon.vue';
import AgreementIcon from '@/components/icons/AgreementIcon.vue';
import ReportBackIcon from '@/components/icons/ReportBackIcon.vue';

/** @deprecated Используйте composables */
export default defineComponent({
  data: () => ({
    isSolutionRoutePointOpen: false,
    solutionConfig: null,
    events_solution: ['counter-reload-commissions', 'preview-reload-gantt'],
    route_point_types,
  }),
  computed: {
    route_point_solution() {
      return {
        confirm_familiarization: {
          icon: markRaw(ConfirmFamiliarizationIcon),
          title: 'Подтвердить ознакомление',
          color: 'light-blue',
          action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_VIEW),
          routePointType: route_point_types.notification,
        },
        agreement: {
          icon: markRaw(AgreementIcon),
          title: 'Одобрить',
          color: 'blue',
          action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_ACCEPT),
          routePointType: route_point_types.agreement,
        },
        reject: {
          icon: markRaw(CircleCloseIcon),
          title: 'Отклонить',
          color: 'red',
          action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_DECLINE),
          routePointType: route_point_types.agreement,
        },
        report_back: {
          icon: markRaw(ReportBackIcon),
          title: 'Отчитаться о выполнении',
          color: 'green',
          action: VuexAdapter.getNameAction(DOC_ROUTE_POINT_MARK_FINISH),
          routePointType: route_point_types.assignment,
        },
      };
    },
  },
  methods: {
    applyRoutePointSolution(key) {
      this.solutionConfig = {
        ...this.route_point_solution[key],
        rp_id: this.rp_id_solution,
        doc_id: this.doc_id_solution,
        events: this.events_solution,
      };

      this.$nextTick(() => (this.isSolutionRoutePointOpen = true));
    },
  },
});
