<template>
  <svg
    width="131"
    height="56"
    viewBox="0 0 131 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.279 48.675L128.995 11.5338C129.053 8.49561 126.603 6.00119 123.559 6.00119H65.2725C63.7685 6.00119 62.3317 6.62332 61.3039 7.71963L42.0042 28.3045V48.5706C42.0042 51.5685 44.4381 53.9988 47.4405 53.9988H122.844C125.805 53.9988 128.222 51.6317 128.279 48.675ZM65.2725 4C63.2141 4 61.2476 4.85149 59.8408 6.35197L40.5411 26.9368C40.1934 27.3076 40 27.7966 40 28.3045V48.5706C40 52.6737 43.3312 56 47.4405 56H122.844C126.897 56 130.205 52.7602 130.283 48.7135L130.999 11.5723C131.079 7.41403 127.725 4 123.559 4H65.2725Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M119.325 18L120 18.1251L119.887 18.5483C119.817 18.8122 119.536 19 119.212 19H117.692C117.265 19 116.939 18.6799 117.01 18.3283L117.692 18.4231L119.212 18.4231L119.325 18ZM117.01 18.3283L117.072 18.0152L117.755 18.11L117.692 18.4231L117.01 18.3283Z"
      fill="black"
    />
    <path
      d="M116 11.7778C116 11.3482 116.348 11 116.778 11H123V17.2222C123 17.6518 122.652 18 122.222 18H116.778C116.348 18 116 17.6518 116 17.2222V11.7778Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M119.569 10.682C119.409 10.682 119.272 10.8008 119.244 10.9638L119.238 11.0171H118.556L118.595 10.8453C118.678 10.3564 119.089 10 119.569 10H121.056C121.577 10 122 10.4372 122 10.9766C122 11.0241 121.997 11.0716 121.99 11.1187L121.307 15.9222L121.243 16.2476C121.101 16.9662 120.175 17.1207 119.822 16.4846L119.54 15.9758L119.185 16.6052C119.047 16.8497 118.794 17 118.52 17H117.541L117 15.927L117.252 14.3611L118.158 13.1123C118.303 12.9119 118.531 12.794 118.773 12.794H119.514L120.092 13.7163V14.6308H119.432V13.9179L119.156 13.4761H118.773C118.738 13.4761 118.706 13.4929 118.685 13.5215L117.876 14.6372L117.689 15.7995L117.934 16.318H118.52C118.56 16.318 118.596 16.2965 118.615 16.2616L119.257 15.123L119.828 15.1251L120.394 16.1451C120.444 16.236 120.569 16.2052 120.589 16.1026L120.687 15.6957L121.338 11.0194C121.338 11.0338 121.34 11.0053 121.338 11.0194C121.338 10.8568 121.213 10.682 121.056 10.682H119.569Z"
      fill="black"
    />
    <path
      d="M35.3497 24.4696L56.7112 2.70345C57.3338 2.06908 56.8833 1 55.9934 1H2.00655C1.11669 1 0.666223 2.06908 1.2888 2.70345L22.9371 24.7618C23.1209 24.9491 23.2239 25.2008 23.2239 25.463V53.9958C23.2239 54.8436 24.2131 55.3084 24.8682 54.7685L34.4114 46.903C34.6424 46.7127 34.7761 46.4293 34.7761 46.1304V25.872C34.7761 25.3477 34.982 24.8442 35.3497 24.4696Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.577461 3.40651C-0.667402 2.13793 0.233324 0 2.01265 0H55.9873C57.7667 0 58.6674 2.13792 57.4225 3.40651L36.0659 25.1701C35.8821 25.3574 35.7792 25.6091 35.7792 25.8713V46.1273C35.7792 46.7252 35.5117 47.2918 35.0498 47.6725L34.4102 46.8999L35.0498 47.6725L25.5087 55.5371C24.1989 56.6168 22.2208 55.6872 22.2208 53.9919V25.4623L0.577461 3.40651ZM55.9873 2.00427L2.01265 2.00427L23.656 24.0601C24.0236 24.4347 24.2295 24.9381 24.2295 25.4623V53.9919L33.7705 46.1273V25.8713C33.7705 25.0849 34.0793 24.3298 34.6307 23.7679L55.9873 2.00427Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.7131 7.31035C44.1092 7.71028 44.0932 8.34325 43.6772 8.72414L32.7572 18.7241C32.3413 19.105 31.683 19.0896 31.2869 18.6897C30.8908 18.2897 30.9068 17.6567 31.3228 17.2759L42.2428 7.27586C42.6587 6.89498 43.317 6.91042 43.7131 7.31035Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M85.1543 25.0132C85.1543 24.461 85.602 24.0132 86.1543 24.0132H113.154C113.707 24.0132 114.154 24.461 114.154 25.0132C114.154 25.5655 113.707 26.0132 113.154 26.0132H86.1543C85.602 26.0132 85.1543 25.5655 85.1543 25.0132Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.1543 33.0132C59.1543 32.461 59.602 32.0132 60.1543 32.0132H113.154C113.707 32.0132 114.154 32.461 114.154 33.0132C114.154 33.5655 113.707 34.0132 113.154 34.0132H60.1543C59.602 34.0132 59.1543 33.5655 59.1543 33.0132Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.1543 41.0132C59.1543 40.461 59.602 40.0132 60.1543 40.0132H113.154C113.707 40.0132 114.154 40.461 114.154 41.0132C114.154 41.5655 113.707 42.0132 113.154 42.0132H60.1543C59.602 42.0132 59.1543 41.5655 59.1543 41.0132Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'CommissionsEmptyByFilterIcon',
};
</script>
