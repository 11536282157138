<template>
  <svg
    width="94"
    height="119"
    viewBox="0 0 94 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.9664 22.2031L63.8469 22.9199C63.7798 23.3224 64.0902 23.6888 64.4982 23.6888H67.3984C67.7079 23.6888 67.9759 23.4738 68.043 23.1717L68.2582 22.2031"
      stroke="black"
      stroke-width="1.32058"
      stroke-linejoin="round"
    />
    <path
      d="M61 11.969C61 11.2397 61.5912 10.6484 62.3206 10.6484H72.8852V21.213C72.8852 21.9424 72.2939 22.5336 71.5646 22.5336H62.3206C61.5912 22.5336 61 21.9424 61 21.213V11.969Z"
      fill="#C3CADB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.3758 10.1069C67.1072 10.1069 66.8774 10.2998 66.8307 10.5642L66.82 10.6507H65.6746L65.7406 10.3719C65.8806 9.57842 66.5701 9 67.3758 9H69.8725C70.7479 9 71.4575 9.70961 71.4575 10.585C71.4575 10.6622 71.4518 10.7393 71.4406 10.8156L70.2939 18.6116L70.1861 19.1396C69.9482 20.3059 68.3932 20.5567 67.8007 19.5243L67.3268 18.6986L66.7309 19.7201C66.4995 20.1168 66.0747 20.3608 65.6154 20.3608H63.971L63.0625 18.6193L63.4861 16.0779L65.0061 14.0512C65.25 13.726 65.6328 13.5346 66.0392 13.5346H67.284L68.2532 15.0315V16.5156H67.1462V15.3586L66.682 14.6416H66.0392C65.9812 14.6416 65.9265 14.6689 65.8917 14.7154L64.5336 16.5261L64.2192 18.4124L64.6312 19.2539H65.6154C65.681 19.2539 65.7417 19.219 65.7747 19.1623L66.8526 17.3146L67.8107 17.3179L68.7608 18.9733C68.8454 19.1208 69.0542 19.0709 69.0882 18.9043L69.2533 18.244L70.3455 10.6545C70.3455 10.6778 70.3488 10.6315 70.3455 10.6545C70.3455 10.3905 70.1365 10.1069 69.8725 10.1069H67.3758Z"
      fill="black"
    />
    <path
      d="M42 50L15 50"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M68 44L15 44"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M68 38L15 38"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.5308 55.01L80.8625 16.1194C80.8954 12.262 80.914 9.57087 80.6577 7.53962C80.4085 5.56598 79.9311 4.52078 79.1922 3.77549C78.4532 3.0302 77.4121 2.54389 75.4407 2.27796C73.4117 2.00428 70.7205 2 66.863 2H43.1932H27.4733C23.6502 2 22.3613 2.04318 21.2567 2.55389C20.152 3.06461 19.2842 4.01852 16.8077 6.93105L5.33444 20.4242C3.35626 22.7506 2.70874 23.556 2.36858 24.4812C2.02843 25.4064 2 26.4394 2 29.4932V91C2 94.8278 2.00425 97.4974 2.27506 99.5117C2.53813 101.468 3.01932 102.505 3.75736 103.243C4.49539 103.981 5.53159 104.462 7.48832 104.725C9.50259 104.996 12.1722 105 16 105H50.0868C50.1989 105.673 50.3233 106.34 50.4598 107H16C8.45753 107 4.68629 107 2.34315 104.657C0 102.314 0 98.5425 0 91V29.4932C0 26.5828 0 25.1276 0.491444 23.791C0.982888 22.4544 1.92552 21.3458 3.81079 19.1286L15.2841 5.63549C17.6413 2.86319 18.82 1.47705 20.4174 0.738524C22.0148 0 23.8343 0 27.4733 0H43.1932H66.863C74.4644 0 78.2652 0 80.6124 2.36732C82.9596 4.73464 82.9272 8.53524 82.8624 16.1364L82.5296 55.1561C81.8691 55.0788 81.2026 55.0298 80.5308 55.01Z"
      fill="black"
    />
    <path
      d="M70.7663 87.4169L92.0311 65.6996C92.6509 65.0667 92.2024 64 91.3166 64H37.574C36.6882 64 36.2398 65.0667 36.8595 65.6996L58.4098 87.7084C58.5928 87.8953 58.6953 88.1465 58.6953 88.4081V116.877C58.6953 117.723 59.6801 118.186 60.3322 117.648L69.8322 109.8C70.0622 109.61 70.1953 109.327 70.1953 109.029V88.8161C70.1953 88.293 70.4003 87.7907 70.7663 87.4169Z"
      fill="#C3CADB"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M78.1953 71L67.6953 81"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'ControlsEmptyByFilterIcon',
};
</script>
