import { AddressEndPoint } from '@/common/enums/end-point.ts';
import Api from '@/services/api/api';
import { TableId } from '@/common/enums/table-id.ts';
import type Option from '@/common/models/option/option';
import { type MaybeRef, unref } from 'vue';

export interface AddressFormData {
  type: Option;
  address: string;
  comment: string;
}

interface AddRequest {
  tableId: TableId;
  parentId: number;
  formData: MaybeRef<AddressFormData>;
}

interface EditRequest {
  id: number;
  formData: MaybeRef<AddressFormData>;
}

export default class AddressApi {
  static async add({ tableId, parentId, formData }: AddRequest) {
    const data = unref(formData);

    await Api.post(AddressEndPoint.Add, {
      parent_table_id: tableId,
      parent_id: parentId,
      address_type: data.type.label,
      address: data.address,
      comment: data.comment,
    });
  }

  static async edit({ id, formData }: EditRequest) {
    const data = unref(formData);

    await Api.post(AddressEndPoint.Edit, {
      address_id: id,
      address_type: data.type.label,
      address: data.address,
      comment: data.comment,
    });
  }

  static async delete(id: number) {
    await Api.post(AddressEndPoint.Delete, { address_id: id });
  }
}
