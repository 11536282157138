<!-- TODO: Объединить общие части ContactRow и BankDetailsRow -->
<template>
  <div class="contact-row">
    <EditContactModal
      v-model="isEditOpen"
      :contact="contact"
    />

    <DeleteContactModal
      v-model="isDeleteOpen"
      :contact-id="row['CONTACT_ID']"
      :contact="row['Контакт']"
    />

    <div class="info">
      <p
        v-for="item in rows"
        :key="item.key"
        class="row"
      >
        <span class="label _muted">{{ item.title }}:</span>
        <span
          class="value"
          :class="{ _bold: item.bold }"
        >
          {{ item.value }}
        </span>
      </p>
    </div>

    <EditBlockRow
      @edit="isEditOpen = true"
      @delete="isDeleteOpen = true"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EditBlockRow from '@/components/clients/rows/EditBlockRow';
import EditContactModal from '@/components/modals/Contact/EditContactModal';
import DeleteContactModal from '@/components/modals/Contact/DeleteContactModal.vue';
import Contact from '@/common/models/contact.js';

export default defineComponent({
  name: 'ContactRow',
  components: {
    DeleteContactModal,
    EditContactModal,
    EditBlockRow,
  },
  props: ['row'],
  data() {
    return {
      isEditOpen: false,
      isDeleteOpen: false,
    };
  },
  computed: {
    rows() {
      return [
        {
          key: 0,
          title: 'Тип',
          value: this.row['Тип'],
        },
        {
          key: 1,
          title: 'ФИО',
          value: this.row['ФИО'],
          bold: true,
        },
        {
          key: 2,
          title: 'Должность',
          value: this.row['Должность'],
        },
        {
          key: 3,
          title: 'Контакт',
          value: this.row['Контакт'],
        },
        {
          key: 4,
          title: 'Примечание',
          value: this.row['Примечание'],
        },
      ].filter((line) => !!line.value);
    },
    contact() {
      return new Contact(this.row);
    },
  },
});
</script>

<style scoped lang="scss">
.contact-row {
  padding: 8px 8px 8px 16px;

  display: flex;
  justify-content: space-between;

  border: var(--border-gray-100);
  border-radius: 8px;
}

.info {
  margin-right: 8px;
}

.row {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.label {
  display: inline-block;
  width: 100px;
}

.value {
  word-break: break-word;

  &._bold {
    font-weight: var(--font-weight-bold);
  }
}
</style>
