<script setup lang="ts">
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue';
import CancelButton from '@/components/buttons/CancelButton.vue';
import RoutePointType from '@/configs/route-point-types.js';
import VuexAdapter from '@/services/vuex-adapter.js';
import {
  DOC_GANTT_ITEMS,
  DOC_ROUTE_POINT_ADD,
  DOCS_GET,
  ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET,
} from '@/configs/end-points.js';
import FormBuilder from '@/components/form/FormBuilder.vue';
import CheckboxForm from '@/components/form/CheckboxForm.vue';
import { fullTrim } from '@/common/utils/utils.js';
import { DateTime } from 'luxon';
import { NotifyTypes } from '@/configs/notify-types.js';
import CopyIcon from '@/assets/icons/copy.svg';
import AddButton from '@/components/buttons/AddButton.vue';
import ButtonUi from '@/components/ui/ButtonUi.vue';
import { DeadlineType } from '@/common/enums/deadline-type.ts';
import { getStartOfDayDate } from '@/common/utils/date';
import Emitter from '@/services/emitter.js';
import { useAfterRunRoutePointStore } from '@/stores/after-run-route-point.js';
import { useActionsStore } from '@/stores/actions.js';
import { useSystemStore } from '@/stores/system.js';
import { usePreviewStore } from '@/stores/preview.js';
import { useRowStore } from '@/stores/row.js';
import { useListsStore } from '@/stores/lists.js';
import ModalForm from '@/components/common/ModalForm.vue';
import { notify } from '@kyvg/vue3-notification';
import { TYPE_DEADLINE, TYPE_SELECT, TYPE_SWITCH, TYPE_TEXT } from '@/configs/form';
import type Option from '@/common/models/option/option';

const isOpen = defineModel<boolean>({ required: true });

const { documentId, rptId, parents } = defineProps<{
  documentId: number;
  rptId?: number;
  parents?: string;
}>();

enum TermFromEnum {
  WorkingDays = 'days',
  EndDate = 'end_date',
}

const TYPES_OPTIONS = [
  {
    code: RoutePointType.assignment,
    label: 'Поручение',
  },
  {
    code: RoutePointType.agreement,
    label: 'Согласование',
  },
  {
    code: RoutePointType.notification,
    label: 'Уведомление',
  },
];

const INITIAL_FORM_VALUE: any = {
  links: [],
  responsible_list: [],
  rpt_id: null,
  deadline: {
    type: DeadlineType.WorkingDays,
    workingDays: 1,
    workingDaysDate: null,
    days: 1,
    daysDate: null,
    date: null,
  },
  content: '',
  comment: '',
  responsibleFrom: 'server',
};

const CONTEND_INPUT_MAX_LENGTH = 100;
const COMMENT_INPUT_MAX_LENGTH = 250;

const afterRunRoutePointStore = useAfterRunRoutePointStore();
const systemStore = useSystemStore();
const listsStore = useListsStore() as any;
const previewStore = usePreviewStore();
const actionsStore = useActionsStore() as any;
const rowStore = useRowStore() as any;

const formBuilderRef = useTemplateRef('formBuilderRef');

const form = ref({ ...INITIAL_FORM_VALUE });
const closeForm = ref(true);
const doc = ref<any>(null);
const isDirty = ref(false);
const isLoading = ref(false);

const systemRPLObjects = computed(() => systemStore.systemRPLObjects as any);

const optionsResponsible = computed(() => {
  const options: any[] = [];

  listsStore[VuexAdapter.getNameGetter(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET)].forEach((responsible: any) => {
    if (form.value.responsibleFrom === responsible['Сервер'] && systemStore.serverId !== responsible['Сервер']) {
      return;
    }
    options.push({
      code: `${responsible.RESPONSIBLE_TABLE_ID}_${responsible.RESPONSIBLE_ID}`,
      label: responsible['Исполнитель'],
      data: {
        responsible_table_id: responsible.RESPONSIBLE_TABLE_ID,
        responsible_id: responsible.RESPONSIBLE_ID,
      },
    });
  });

  return options;
});

const afterRunRoutePointOptions = computed(() => {
  return (afterRunRoutePointStore.listAfterRunRoutePoint || []).map((item: any) => {
    const routePointTypeName = TYPES_OPTIONS.find(({ code }) => code == item.RPT_ID)!.label;

    return {
      code: item.RP_ID,
      label: `${item['Номер']}. ${routePointTypeName} - ${item['Исполнитель']}`,
      endDate: new Date(item['Срок']),
    };
  });
});

const startDate = computed(() => {
  if (!form.value.links.length) {
    return getStartOfDayDate();
  }

  return form.value.links.reduce((acc: any, item: any) => {
    if (!acc) {
      return item.endDate;
    }

    return acc > item.endDate ? acc : item.endDate;
  }, null);
});

const fields = [
  {
    type: TYPE_SWITCH,
    name: 'responsibleFrom',
    hidden: () => systemRPLObjects.value && !systemRPLObjects.value.check_rpl_objects,
    options: [
      {
        label: 'Сервер',
        value: 'server',
      },
      {
        label: 'Все',
        value: 'all',
      },
    ],
  },
  {
    type: TYPE_SELECT,
    name: 'rpt_id',
    placeholder: 'Тип',
    options: () => TYPES_OPTIONS,
    required: true,
  },
  {
    type: TYPE_SELECT,
    name: 'responsible_list',
    placeholder: 'Исполнитель',
    options: () => optionsResponsible.value,
    required: true,
    multiple: true,
  },
  {
    type: TYPE_SELECT,
    name: 'links',
    hidden: () => parents,
    placeholder: 'Выполнять после',
    options: () => afterRunRoutePointOptions.value,
    disabled: () => !afterRunRoutePointOptions.value.length,
    multiple: true,
  },
  {
    type: TYPE_DEADLINE,
    name: 'deadline',
    excludeOptions: [DeadlineType.None],
    copyHandler: copyEndDate,
    startDate: () => startDate.value,
  },
  {
    type: TYPE_TEXT,
    name: 'content',
    placeholder: `Описание (${CONTEND_INPUT_MAX_LENGTH})`,
    length: CONTEND_INPUT_MAX_LENGTH,
    required: true,
    slot: 'copy-content',
  },
  {
    type: TYPE_TEXT,
    name: 'comment',
    placeholder: `Примечание (${COMMENT_INPUT_MAX_LENGTH})`,
    length: COMMENT_INPUT_MAX_LENGTH,
    slot: 'copy-comment',
  },
];

const deadlineToFormDateInfoMap = computed(() => {
  const { deadline } = form.value;

  if (deadline.type === DeadlineType.WorkingDays) {
    return {
      [TermFromEnum.WorkingDays]: deadline.workingDays,
      termFrom: TermFromEnum.WorkingDays,
      [TermFromEnum.EndDate]: null,
    };
  }

  return {
    [TermFromEnum.EndDate]: deadline.type === DeadlineType.Days ? deadline.daysDate : deadline.date,
    termFrom: TermFromEnum.EndDate,
    [TermFromEnum.WorkingDays]: null,
  };
});

watch(
  () => documentId,
  () => afterRunRoutePointStore.fetchAfterRunRoutePointAction({ doc_id: documentId }),
);

function copyEndDate() {
  if (doc.value?.['Срок']) {
    form.value.deadline.date = DateTime.fromFormat(doc.value['Срок'], 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd');
  }
}

async function onShow() {
  form.value = { ...INITIAL_FORM_VALUE };
  isDirty.value = false;

  isLoading.value = true;

  rowStore[VuexAdapter.getNameRowOnlyFetchAction(DOCS_GET)](documentId).then((r: any) => (doc.value = r));

  await Promise.all([
    listsStore[VuexAdapter.getNameAction(ROUTES_POINTS_RESPONSIBLE_DIRECTORY_GET)](),
    systemStore.systemRPLObjectsCheckAction(),
  ]);

  if (!parents) {
    await afterRunRoutePointStore.fetchAfterRunRoutePointAction({ doc_id: documentId });
  }

  if (rptId) {
    TYPES_OPTIONS.forEach((type) => {
      if (type.code == rptId) {
        form.value.rpt_id = type;
      }
    });
  }

  isLoading.value = false;
}

async function onHide() {
  // нужно, чтобы afterRunRoutePointStore.$reset() не триггерил
  // перевычисление computed свойств завязанных на форме в ее
  // промежуточном состоянии из-за триггера от FormBuilder
  await nextTick();
  afterRunRoutePointStore.$reset();
}

async function apply() {
  try {
    if (!formBuilderRef.value!.validate()) {
      return;
    }
    isLoading.value = true;

    const { deadline: _, ...newRoutePointData } = form.value;

    const data = {
      ...newRoutePointData,
      ...deadlineToFormDateInfoMap.value,
      doc_id: documentId,
      rpt_id: form.value.rpt_id.code,
      links:
        !parents && form.value.links && form.value.links.length
          ? '#' + form.value.links.map((item: any) => item.code).join('##') + '#'
          : null,
    };

    if (parents) {
      data.parents = parents;
    }

    for (const responsible of form.value.responsible_list) {
      await actionsStore[VuexAdapter.getNameAction(DOC_ROUTE_POINT_ADD)]({
        ...data,
        responsible_table_id: responsible.data.responsible_table_id,
        responsible_id: responsible.data.responsible_id,
      });

      notify({
        text: `Новая точка маршрута документа №${documentId}, исполнитель: ${responsible.label} добавлена успешно`,
        type: NotifyTypes.Success,
      });
    }

    previewStore.refreshPartlyPreviewAction(DOC_GANTT_ITEMS);

    Emitter.emit('table-trigger-refresh-only-state');

    if (closeForm.value) {
      isDirty.value = false;
      isOpen.value = false;
      return;
    }

    if (!parents) {
      afterRunRoutePointStore.$reset();
      await afterRunRoutePointStore.fetchAfterRunRoutePointAction({ doc_id: documentId });
      form.value.links = [];
    }
  } catch (error) {
    notify({
      type: NotifyTypes.Error,
      text: 'При добавлении возникла ошибка.',
      data: error,
    });
  } finally {
    isLoading.value = false;
  }
}

function copyContent() {
  let content = fullTrim(doc.value['Содержание'] || '');
  if (content.length > 100) {
    content = content.slice(0, 97) + '...';
  }

  form.value.content = content;
}

function copyComment() {
  let comment = fullTrim(doc.value['Комментарий'] || '');
  if (comment.length > 250) {
    comment = comment.slice(0, 247) + '...';
  }

  form.value.comment = comment;
}

function getRoutePointTypeOptionClass(option: Option) {
  return {
    'table-badge': true,
    'badge-assignment': option.code === RoutePointType.assignment,
    'badge-coordination': option.code === RoutePointType.agreement,
    'badge-notification': option.code === RoutePointType.notification,
  };
}

function onFormChange(_: unknown, changedProp: any) {
  if (
    changedProp.field.name === 'deadline' &&
    changedProp.value.type === DeadlineType.WorkingDays &&
    changedProp.value.workingDays === 1
  ) {
    return;
  }

  isDirty.value = true;
}
</script>

<template>
  <ModalForm
    v-model="isOpen"
    title="Новая точка маршрута"
    :confirm="isDirty"
    @show="onShow"
    @hide="onHide"
  >
    <template #body>
      <div class="info">
        Введите информацию о точке маршрута.<br />
        Учтите, что документ начнет двигаться по маршруту только после его отправки в работу.
      </div>

      <FormBuilder
        ref="formBuilderRef"
        v-model="form"
        margins
        :fields="fields"
        :readonly="isLoading"
        @update:model-value="onFormChange"
      >
        <template #copy-content>
          <ButtonUi
            color="black"
            mode="outline"
            :disabled="!doc?.['Содержание']?.length"
            @click="copyContent"
          >
            <CopyIcon />
          </ButtonUi>
        </template>

        <template #copy-comment>
          <ButtonUi
            color="black"
            mode="outline"
            :disabled="!doc?.['Комментарий']?.length"
            @click="copyComment"
          >
            <CopyIcon />
          </ButtonUi>
        </template>

        <template #rpt_id:list-option-content="{ option }">
          <div :class="getRoutePointTypeOptionClass(option)">
            {{ option.label }}
          </div>
        </template>
        <template #rpt_id:selected-option="{ option }">
          <div :class="{ ...getRoutePointTypeOptionClass(option), 'rpt-id-selected-option': true }">
            {{ option.label }}
          </div>
        </template>
      </FormBuilder>
    </template>

    <template #footer>
      <CheckboxForm
        v-model="closeForm"
        label="Закрывать форму"
        :disabled="isLoading"
      />

      <div class="buttons">
        <CancelButton
          class="button"
          :disabled="isLoading"
          @click="isOpen = false"
        />
        <AddButton
          class="button"
          :loading="isLoading"
          @click="apply"
        />
      </div>
    </template>
  </ModalForm>
</template>

<style scoped lang="scss">
.info {
  padding-top: 10px;
  margin-bottom: 24px;

  color: var(--color-gray-1000);
  font-size: 14px;
}

.checkbox-form {
  margin-right: auto !important;
}

.buttons {
  margin-left: auto;
  display: flex;
}

.button {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

/* TODO: Отказаться от перекрытия стилей */
:deep(.checkmark) {
  margin-top: -3px;
}

.rpt-id-selected-option {
  width: min-content;
}
</style>
