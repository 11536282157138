<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { DocumentEditor, type IConfig } from '@onlyoffice/document-editor-vue';
import FileApi from '@/services/api/file-api.js';
import { useRoute } from 'vue-router';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import FatalError from '@/components/general/FatalError.vue';
import { useTitle } from '@vueuse/core';
import { type EditorFile, useFileEditorStore } from '@/stores/file-editor.ts';
import type { DocumentStateChangeEvent, DownloadAsEvent } from '@/common/types/onlyoffice.ts';
import StorageApi from '@/services/api/storage-api';
import type { ApiError } from '@/common/types/api.ts';
import { notify } from '@kyvg/vue3-notification';
import { NotifyTypes } from '@/configs/notify-types';

type PageError = {
  title: string;
  message: string;
};

type EditorData = {
  url: string;
  config: IConfig;
};

const ID = 'file-editor';

const fileEditorStore = useFileEditorStore();

const title = useTitle();
const route = useRoute();

const isLoading = ref(false);
const error = ref<PageError>();
const editorData = ref<EditorData>();

let file: EditorFile;

onMounted(async () => {
  const id = typeof route.params.id === 'string' ? route.params.id : null;
  const timestamp = route.query.t && !isNaN(+route.query.t) ? +route.query.t : null;

  if (id === null || timestamp === null) {
    showNotFoundError();
    return;
  }

  const f = fileEditorStore.getFile(id, timestamp);

  if (!f) {
    showNotFoundError();
    return;
  }

  file = f;

  if (fileEditorStore.isFileOpen(file)) {
    notify({
      type: NotifyTypes.Warn,
      text: `Файл "${file.name}" уже редактируется в другом окне`,
    });
  }

  title.value = (file.documentId ? `${file.documentId} - ` : '') + `«${file.name}» редактирование`;

  window.addEventListener('beforeunload', onBeforeUnload);
  window.addEventListener('unload', onBeforeUnload);
  window.addEventListener('pagehide', onBeforeUnload);

  isLoading.value = true;

  if (!file.isLoaded) {
    void FileApi.addView(file.fileId);

    try {
      await StorageApi.load({ url: file.url, onlyHeaders: true });
      fileEditorStore.setFileLoaded(file.id, true);
    } catch (error: any) {
      showApiError('При получении файла из хранилища возникла ошибка', '404', error);
      isLoading.value = true;
      return;
    }
  }

  try {
    editorData.value = await FileApi.getEditConfig(file.fileId, file.timestamp, {
      onAppReady,
      onDocumentStateChange,
      onDownloadAs,
    });
  } catch (error: any) {
    showApiError('При загрузке конфигурации возникла ошибка', '500', error);
    isLoading.value = true;
  }
});

function showNotFoundError() {
  error.value = {
    title: '404',
    message:
      'Файл, который Вы пытаетесь отредактировать, не существует!<br/>Вернитесь назад или перейдите на главную страницу',
  };
}

function showApiError(title: string, defaultCode: string, apiError?: ApiError) {
  error.value = {
    title: apiError?.error_code.toString() || defaultCode,
    message: `${title}.<br>Перезагрузите страницу, вернитесь назад или перейдите на главную страницу${apiError?.error_message ? `<br>${apiError.error_message}` : ''}`,
  };
}

function showEditorError() {
  error.value = {
    title: 'Ошибка',
    message: `При загрузке редактора возникла ошибка<br>Перезагрузите страницу, вернитесь назад или перейдите на главную страницу`,
  };
}

function onAppReady() {
  isLoading.value = false;
}

function onDocumentStateChange({ data: isMutated }: DocumentStateChangeEvent) {
  if (!isMutated) {
    window.DocEditor.instances[ID].downloadAs();
  }
}

function onLoadComponentError(code: number, message: string) {
  console.error(`Код ошибки: ${code}. Сообщение: ${message}`);
  showEditorError();
}

function onDownloadAs(event: DownloadAsEvent) {
  fileEditorStore.setFileUpdatedUrl(file.id, event.data.url);
}

function onBeforeUnload() {
  fileEditorStore.removeFile(file.id, file.timestamp);
}
</script>

<template>
  <div class="file-editor-page">
    <FatalError
      v-if="error"
      :title="error.title"
      sub-title="Упс, что-то пошло не так..."
      :description="error.message"
    />

    <div
      v-else
      class="wrapper"
    >
      <LoaderUi
        v-if="isLoading"
        text="Загрузка конфигурации"
      />

      <DocumentEditor
        v-if="editorData"
        :id="ID"
        :document-server-url="editorData.url"
        :config="editorData.config"
        :on-load-component-error="onLoadComponentError"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-editor-page,
.wrapper {
  height: 100%;
}
</style>
