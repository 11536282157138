<script setup>
import FileError from '@/components/doc/files/FileError.vue';
import { State } from '@/common/enums/state.ts';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import { only } from '@/common/utils/props-validators';
import { computed } from 'vue';

const props = defineProps({
  state: {
    type: String,
    default: State.Undefined,
  },
  url: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  icon: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: 'm',
    validator: only('m', 'l'),
  },
});

defineEmits(['load', 'retry']);

const stateSize = computed(() => {
  switch (props.size) {
    case 'm':
      return 's';
    case 'l':
    default:
      return 'm';
  }
});
</script>

<template>
  <div
    v-if="state === State.Undefined"
    class="file-preview-page _icon"
  >
    <component
      :is="icon"
      class="icon"
    />
  </div>
  <LoaderUi
    v-else-if="state === State.Loading"
    class="file-preview-page"
    position="static"
    color="white"
    :size="stateSize"
    text="Загрузка данных"
  />
  <FileError
    v-else-if="state === State.Error"
    class="file-preview-page"
    :size="stateSize"
    @retry="$emit('retry')"
  />
  <img
    v-else-if="state === State.Defined"
    class="file-preview-page _image"
    :src="url"
    :alt="alt"
    @load="$emit('load')"
  />
</template>

<style scoped lang="scss">
.file-preview-page {
  &._icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
  }

  &._image {
    display: block;
    width: 100%;
    background: var(--gradient-workspace);
  }
}

.icon {
  width: 50%;
  height: 50%;

  fill: var(--color-gray-100);
}
</style>
