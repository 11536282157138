<template>
  <div class="file-name-button">
    <SpinnerUi
      v-if="isDownloading || previewIsLoading"
      size="s"
    />

    <template v-else>
      <button
        v-if="isClickable"
        @click="onIconClick"
      >
        <component
          :is="file.icon"
          class="icon"
          tabindex="0"
          role="button"
        />
      </button>
      <component
        :is="file.icon"
        v-else
        class="icon"
      />
    </template>

    <FileSignatureIcon
      v-if="withSign && file.signCount"
      :count="file.signCount"
    />

    <FileTruncatedLink
      ref="nameRef"
      :file="file"
      @update:is-loading="isDownloading = $event"
    />

    <span
      v-if="withSize"
      class="_muted size"
    >
      {{ fileSize(file.size) }}
    </span>
  </div>
</template>

<script>
import FileTruncatedLink from '@/components/doc/files/FileTruncatedLink.vue';
import FileSignatureIcon from '@/components/icons/FileSignatureIcon.vue';
import { defineComponent } from 'vue';
import SpinnerUi from '@/components/ui/SpinnerUi.vue';
import DocumentFile from '@/common/models/file/document-file.ts';
import SizeFile from '@/components/table/columns/files/SizeFile.vue';
import { mapState } from 'pinia';
import { State } from '@/common/enums/state.ts';
import { usePreviewFilesStore } from '@/stores/preview-files.js';
import { fileSize } from '@/common/utils/utils.js';
import { useFileEditorStore } from '@/stores/file-editor.ts';

export default defineComponent({
  name: 'FileNameButton',
  components: {
    SpinnerUi,
    FileSignatureIcon,
    FileTruncatedLink,
    SizeFile,
  },
  props: {
    file: {
      type: DocumentFile,
      required: true,
    },
    withSign: {
      type: Boolean,
      default: false,
    },
    withSize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show-preview'],
  data() {
    return {
      isDownloading: false,
      previewIsLoading: false,
      fileEditorStore: useFileEditorStore(),
    };
  },
  computed: {
    ...mapState(usePreviewFilesStore, ['lastRevisionFilesState']),
    isClickable() {
      return this.file.isLast && (this.file.canPreview || this.file.canEdit);
    },
  },
  methods: {
    fileSize,
    onIconClick() {
      if (this.file.canEdit && !this.file.canPreview) {
        this.edit();
        return;
      }

      const fileId = this.file.id;
      this.previewIsLoading = true;

      const unwatch = this.$watch(
        'lastRevisionFilesState',
        async (state) => {
          if (!state[fileId] || state[fileId] === State.Loading) {
            return;
          }

          if (state[fileId] === State.Defined || state[fileId] === State.Error) {
            this.previewIsLoading = false;
          }

          await this.$nextTick();
          unwatch();
        },
        { immediate: true },
      );

      this.$emit('show-preview');
    },
    async edit() {
      this.fileEditorStore.addFile(this.$router, this.file);
    },
  },
});
</script>

<style scoped lang="scss">
.file-name-button {
  min-width: 0;

  display: flex;
  align-items: center;
}

.spinner-ui {
  flex-shrink: 0;
  margin-right: 8px;
}

.icon {
  flex-shrink: 0;
  margin-right: 8px;
}

.file-truncated-link {
  &:not(:last-child) {
    margin-right: 4px;
  }
}

.size {
  white-space: nowrap;
}
</style>
