<script setup lang="ts">
import ButtonIconUi from '@/components/ui/ButtonIconUi.vue';
import UpdateIcon from '@/assets/icons/update.svg';
import { useVersionStore } from '@/stores/version.js';
import { storeToRefs } from 'pinia';
import TooltipUi from '@/components/ui/TooltipUi.vue';
import type { Placement } from '@floating-ui/vue';

defineProps<{
  placement?: Placement;
}>();

const versionStore = useVersionStore();
const { next } = storeToRefs(versionStore);

function showUpdate() {
  versionStore.setShowModal(true);
}
</script>

<template>
  <TooltipUi
    v-if="!!next"
    class="update-button"
    :text="`Доступно обновление приложения до версии ${next.version}`"
    :placement="placement"
  >
    <ButtonIconUi
      size="l"
      @click="showUpdate"
    >
      <UpdateIcon />
    </ButtonIconUi>
  </TooltipUi>
</template>
