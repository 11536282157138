<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00033 2.00033C4.68662 2.00033 2.00033 4.68662 2.00033 8.00033C2.00033 11.314 4.68662 14.0003 8.00033 14.0003C8.36852 14.0003 8.66699 14.2988 8.66699 14.667C8.66699 15.0352 8.36852 15.3337 8.00033 15.3337C3.95024 15.3337 0.666992 12.0504 0.666992 8.00033C0.666992 3.95024 3.95024 0.666992 8.00033 0.666992C8.36852 0.666992 8.66699 0.965469 8.66699 1.33366C8.66699 1.70185 8.36852 2.00033 8.00033 2.00033Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99967 2.00033C11.3134 2.00033 13.9997 4.68662 13.9997 8.00033C13.9997 11.314 11.3134 14.0003 7.99967 14.0003C7.63148 14.0003 7.33301 14.2988 7.33301 14.667C7.33301 15.0352 7.63148 15.3337 7.99967 15.3337C12.0498 15.3337 15.333 12.0504 15.333 8.00033C15.333 3.95024 12.0498 0.666992 7.99967 0.666992C7.63148 0.666992 7.33301 0.965469 7.33301 1.33366C7.33301 1.70185 7.63148 2.00033 7.99967 2.00033Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8044 6.19526C11.0648 6.45561 11.0648 6.87772 10.8044 7.13807L7.80441 10.1381C7.54406 10.3984 7.12195 10.3984 6.8616 10.1381L5.52827 8.80474C5.26792 8.54439 5.26792 8.12228 5.52827 7.86193C5.78862 7.60158 6.21073 7.60158 6.47108 7.86193L7.33301 8.72386L9.8616 6.19526C10.122 5.93491 10.5441 5.93491 10.8044 6.19526Z"
    />
  </svg>
</template>

<script lang="ts">
/** @deprecated Используйте vite-svg-loader */
export default {
  name: 'AgreementIcon',
};
</script>
