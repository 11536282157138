import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "#5F6A7D",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.677 8.4c0 .37.308.677.677.677s.677-.308.677-.677V5.323a.63.63 0 0 0-.185-.461l-4-4a.68.68 0 0 0-.461-.185H5.846c-.523 0-.984 0-1.354.03C4.123.74 3.785.8 3.446.955a2.7 2.7 0 0 0-1.17 1.17c-.153.338-.214.676-.245 1.045C2 3.54 2 3.97 2 4.523v3.908c0 .37.308.677.677.677s.677-.308.677-.677V4.523c0-.584 0-.954.03-1.261.031-.277.062-.431.124-.554.092-.246.307-.431.554-.554.092-.062.246-.092.553-.123C4.923 2 5.292 2 5.877 2h2.8v2.768c0 .154.03.339.123.523.123.247.338.462.585.585.184.092.369.123.523.123h2.769zM10.03 4.646C10 4.554 10 4.462 10 4.246V2.954l1.723 1.723H10.4c-.185 0-.308 0-.37-.03M7.938 10.154H6.615a.683.683 0 0 0-.677.677v4c0 .369.308.677.677.677h1.323c1.108 0 2-.893 2-2v-1.323c0-1.139-.923-2.031-2-2.031m.647 3.323c0 .37-.308.677-.677.677H7.23v-2.677h.677c.369 0 .677.308.677.677zM4 10.154H2.46c-.676 0-1.23.554-1.23 1.23v2.924c0 .677.554 1.23 1.23 1.23H4c.677 0 1.23-.553 1.23-1.23v-2.923c0-.677-.553-1.231-1.23-1.231m0 4.154H2.46v-2.923H4zM12.923 12.154h-.554a.374.374 0 0 1-.369-.37c0-.184.185-.368.37-.368h1.076a.6.6 0 0 0 .523.307.617.617 0 0 0 .616-.615c0-.523-.4-.923-.923-.923h-1.293c-.892 0-1.6.738-1.6 1.6 0 .861.739 1.6 1.6 1.6h.554c.246 0 .462.215.462.461a.473.473 0 0 1-.462.462h-1.015a.6.6 0 0 0-.523-.308.617.617 0 0 0-.616.616c0 .523.4.923.923.923h1.231c.923 0 1.692-.77 1.692-1.693s-.769-1.692-1.692-1.692" }, null, -1)
  ])))
}
export default { render: render }