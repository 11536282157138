<template>
  <PreviewBlock
    title="Банковские реквизиты"
    :loading="loading"
    :amount="amount"
    @resize="onResize"
  >
    <template #header-postfix>
      <AddPreviewButton
        v-if="showAdd"
        :show-title="!compact"
        @click="isAddOpen = true"
      />
      <AddBankDetailsModal
        v-model="isAddOpen"
        :parent-id="clientId"
        :table-id="TableId.Clients"
      />
    </template>

    <UploaderBlockClientPreview
      :end-point="BankDetailsEndPoint.Get"
      :client-id="clientId"
      :component="BankDetailsRow"
      empty-text="Список банковских реквизитов пуст"
    />
  </PreviewBlock>
</template>

<script>
import { defineComponent } from 'vue';
import UploaderBlockClientPreview from '@/components/clients/UploaderBlockClientPreview';
import AddBankDetailsModal from '@/components/modals/BankDetails/AddBankDetailsModal.vue';
import ClientsPreviewBlockMixin from '@/mixins/clients-preview-block-mixin.js';
import PreviewBlock from '@/components/doc/preview/PreviewBlock.vue';
import AddPreviewButton from '@/components/buttons/AddPreviewButton.vue';
import PreviewBlockMixin from '@/mixins/preview-block-mixin.js';
import BankDetailsRow from '@/components/clients/rows/BankDetailsRow.vue';
import { BankDetailsEndPoint } from '@/common/enums/end-point.ts';
import { TableId } from '@/common/enums/table-id.ts';

export default defineComponent({
  name: 'BankDetailsClientPreview',
  components: {
    AddPreviewButton,
    PreviewBlock,
    AddBankDetailsModal,
    UploaderBlockClientPreview,
  },
  mixins: [PreviewBlockMixin, ClientsPreviewBlockMixin],
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    TableId() {
      return TableId;
    },
    BankDetailsEndPoint() {
      return BankDetailsEndPoint;
    },
    BankDetailsRow() {
      return BankDetailsRow;
    },
    endPoint() {
      return BankDetailsEndPoint.Get;
    },
  },
});
</script>
