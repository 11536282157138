import BaseFile from '@/common/models/file/base-file.ts';
import { uniqueId } from 'lodash-es';
import { State } from '@/common/enums/state.ts';
import { NotifyTypes } from '@/configs/notify-types.js';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '@/common/consts/file.ts';
import { fileSize } from '@/common/utils/utils.js';
import IdPrefix from '@/common/enums/id-prefix.js';
import { notify } from '@kyvg/vue3-notification';

export default class ExternalFile extends BaseFile {
  base64: string;
  state: State;
  error?: string;

  constructor(file: File, base64: string) {
    super(uniqueId(IdPrefix.ExternalFile), file.name, file.size);
    this.base64 = base64;
    this.state = State.Undefined;
  }

  static async createList(files: File[]) {
    const valid = ExternalFile.getValid(files);
    return await Promise.all(valid.map((file) => ExternalFile.create(file)));
  }

  static async create(file: File) {
    const base64 = await BaseFile.toBase64(file);
    return new ExternalFile(file, base64);
  }

  static getValid(files: File[]) {
    return files.filter((file) => {
      if (file.size <= 0) {
        notify({
          type: NotifyTypes.Warn,
          text: `Файл "${file.name}" имеет пустое содержимое`,
        });
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        notify({
          type: NotifyTypes.Warn,
          text: `Файл "${file.name}" (${fileSize(file.size)}) превышает максимально допустимый размер ${MAX_FILE_SIZE_MB} МБ`,
        });
        return false;
      }

      if (!file.type) {
        notify({
          type: NotifyTypes.Warn,
          text: `Файл "${file.name}" имеет пустой тип`,
        });
        return false;
      }

      return true;
    });
  }
}
