<!-- TODO: Объединить общие части ContactRow и BankDetailsRow -->
<template>
  <div class="bank-details-row">
    <EditBankDetailsModal
      v-model="isEditOpen"
      :table-id="TableId.Clients"
      :parent-id="row['CLIENT_ID']"
      :bank-details="bankDetails"
    />

    <DeleteBankDetailsModal
      v-model="isDeleteOpen"
      :bank-details-id="row['ACCOUNT_ID']"
      :bank-details="row['Банк']"
    />

    <div class="info">
      <p
        v-for="item in rows"
        :key="item.key"
        class="row"
      >
        <span class="label _muted">{{ item.title }}:</span>
        <span
          class="value"
          :class="{ _bold: item.bold }"
        >
          {{ item.value }}
        </span>
      </p>
    </div>

    <EditBlockRow
      @edit="isEditOpen = true"
      @delete="isDeleteOpen = true"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EditBlockRow from '@/components/clients/rows/EditBlockRow';
import EditBankDetailsModal from '@/components/modals/BankDetails/EditBankDetailsModal.vue';
import DeleteBankDetailsModal from '@/components/modals/BankDetails/DeleteBankDetailsModal.vue';
import BankDetails from '@/common/models/bank-details.js';
import { TableId } from '@/common/enums/table-id.ts';

export default defineComponent({
  name: 'BankDetailsRow',
  components: {
    DeleteBankDetailsModal,
    EditBankDetailsModal,
    EditBlockRow,
  },
  props: ['row'],
  data() {
    return {
      isEditOpen: false,
      isDeleteOpen: false,
    };
  },
  computed: {
    TableId() {
      return TableId;
    },
    rows() {
      return [
        {
          key: 0,
          title: 'Банк',
          value: this.row['Банк'],
          bold: true,
        },
        {
          key: 1,
          title: 'БИК',
          value: this.row['БИК'],
        },
        {
          key: 2,
          title: 'КС',
          value: this.row['КС'],
        },
        {
          key: 3,
          title: 'РС',
          value: this.row['РС'],
        },
        {
          key: 4,
          title: 'Примечание',
          value: this.row['Примечание'],
        },
      ].filter((line) => !!line.value);
    },
    bankDetails() {
      return new BankDetails(this.row);
    },
  },
});
</script>

<style scoped lang="scss">
.bank-details-row {
  padding: 8px 8px 8px 16px;

  display: flex;
  justify-content: space-between;

  border: var(--border-gray-100);
  border-radius: 8px;
}

.info {
  margin-right: 8px;
}

.row {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.label {
  display: inline-block;
  width: 100px;
}

.value {
  word-break: break-word;

  &._bold {
    font-weight: var(--font-weight-bold);
  }
}
</style>
